import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject,
  signal,
} from '@angular/core';
import { Router } from '@angular/router';
import { LeftyComponent, injectActivatedRoute } from '@frontend2/ui';
import { RESET_PASSWORD_PATH } from '../routes.constants';
import { LeftyAuthBloc } from '@frontend2/api';
import { CustomerSpace } from '@frontend2/proto/librarian/proto/frontend_misc_pb';
import { workspaceRouteCommand } from '../routes.helpers';
import { NO_ACCESS_ILLUSTRATION_PATH } from '@frontend2/core';

@Component({
  selector: 'alfred-workspace-selection.route',
  templateUrl: 'workspace-selection.route.html',
  styleUrls: ['./workspace-selection.route.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlfredWorkspaceSelectionRouteComponent
  extends LeftyComponent
  implements OnInit
{
  private readonly _router = inject(Router);
  private readonly _auth = inject(LeftyAuthBloc);
  private readonly route = injectActivatedRoute();

  readonly resetPasswordLink = RESET_PASSWORD_PATH;

  readonly noAccessIllustration = NO_ACCESS_ILLUSTRATION_PATH;

  isInvalidSubscription = signal(false);

  get email(): string {
    return this._auth.user.email;
  }

  get userName(): string {
    return `${this._auth.user.firstName} ${this._auth.user.lastName}`;
  }

  get hasMultipleWorkspaces(): boolean {
    return this._auth.user.customerSpaces.length > 1;
  }

  workspaceSelected(workspace: CustomerSpace): void {
    this._router.navigate(workspaceRouteCommand(workspace.idxHint), {
      replaceUrl: true,
    });
  }

  logout(): void {
    this._auth.logout();
  }

  ngOnInit(): void {
    this.route.data.subscribe((data) => {
      this.isInvalidSubscription.set(data['isInvalidSubscription']);
    });
  }
}
