import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  SALESFORCE_LOGO,
  SHOPIFY_LOGO,
  isManualStore,
  isSalesforceStore,
  isShopifyStore,
} from '@frontend2/core';
import { EcommerceStorePb } from '@frontend2/proto/librarian/proto/ecommerce_stores_pb';
import { SafeImageComponent } from '../../image/image';
import { LeftyIconComponent } from '../icon.component';

@Component({
  selector: 'store-icon',
  styleUrls: ['store-icon.component.scss'],
  template: `
    @if (isShopifyStore) {
      @if (greyIcon) {
        <lefty-icon
          class="logo"
          icon="shopify"
          size="large"
        ></lefty-icon>
      } @else {
        <lefty-image
          objectFit="cover"
          [src]="shopifyLogoUrl"
          class="online-logo"
        ></lefty-image>
      }
    } @else if (isSalesforceStore) {
      @if (greyIcon) {
        <lefty-icon
          class="logo"
          icon="salesforce"
          size="small"
        ></lefty-icon>
      } @else {
        <lefty-image
          objectFit="contain"
          [src]="salesforceLogoUrl"
          class="online-logo"
        ></lefty-image>
      }
    } @else if (isManualStore) {
      <lefty-icon
        class="logo"
        icon="offline"
        size="large"
      ></lefty-icon>
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LeftyIconComponent, SafeImageComponent],
})
export class StoreIconComponent {
  @Input()
  store = new EcommerceStorePb();

  @Input()
  greyIcon = false;

  readonly shopifyLogoUrl = SHOPIFY_LOGO;
  readonly salesforceLogoUrl = SALESFORCE_LOGO;

  get isShopifyStore(): boolean {
    return isShopifyStore(this.store);
  }

  get isSalesforceStore(): boolean {
    return isSalesforceStore(this.store);
  }

  get isManualStore(): boolean {
    return isManualStore(this.store);
  }
}
