import { Component, inject } from '@angular/core';
import { LeftyAuthBloc } from '@frontend2/api';
import {
  FirebaseBloc,
  maybeProvideLeftyIframeLocationStrategy,
} from '@frontend2/ui';

@Component({
  selector: 'alfred-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    // Can't be provided in AppModule
    // Angular router need to be initialized first
    ...maybeProvideLeftyIframeLocationStrategy(),
  ],
})
export class AppComponent {
  constructor() {
    inject(FirebaseBloc).subscribeUserSignin(inject(LeftyAuthBloc).state$);
  }
}
