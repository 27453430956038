<div
  class="item"
  [class.compact]="isCompact"
  *ngIf="!hasError()"
>
  <lefty-icon
    *ngIf="!isCreateLabel() && !isCompact"
    class="label"
    icon="label"
  >
  </lefty-icon>

  <span class="name">
    <span
      *ngIf="isCreateLabel()"
      class="create-label"
    >
      <ng-container i18n>Create label</ng-container>&nbsp;-
    </span>
    {{ name() }}
  </span>
  <span
    *ngIf="!isCreateLabel() && count() > 0 && hideCount === false"
    class="label-count"
    >{{ count() }} {{ isCompact ? '' : countLabel() }}
  </span>
</div>
<div
  class="item error"
  *ngIf="hasError()"
  i18n
>
  Label too long. Your label should not exceed {{ maxSize }} characters
</div>
