import { computed, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { JsonValue } from '@bufbuild/protobuf';
import { injectLibrarianEntityFieldsClient } from '@frontend2/api';
import { getEnumName, isNil, isNotNil } from '@frontend2/core';
import {
  BaseEntityType,
  BaseEntityTypeReq,
  EntityField,
} from '@frontend2/proto/librarian/proto/common_pb';
import { IframeSyncedCacheBloc } from '../bloc';
import { injectLeftyEventsBus } from '../events/events.service';

abstract class EntityFieldsCache extends IframeSyncedCacheBloc<
  Map<bigint, EntityField>
> {
  constructor(entityType: BaseEntityType) {
    super(new Map());
    this._entityType = entityType;

    this.leftyEvents
      .on('entity_field_updated')
      .pipe(takeUntilDestroyed())
      .subscribe((entity: EntityField) => this._addOrUpdateEntity(entity));

    this.leftyEvents
      .on('entity_field_created')
      .pipe(takeUntilDestroyed())
      .subscribe((entity: EntityField) => this._addOrUpdateEntity(entity));
  }

  protected readonly leftyEvents = injectLeftyEventsBus();
  private readonly librarianEntityFields = injectLibrarianEntityFieldsClient();

  private _entityType = BaseEntityType.UNKNOWN;

  override get syncName(): string {
    return `${getEnumName(BaseEntityType, this._entityType)}_fields`;
  }

  readonly fields = computed(() => [...this.cachedData().values()]);

  override async fetch(): Promise<Map<bigint, EntityField>> {
    const req = new BaseEntityTypeReq({ entityType: this._entityType });
    const res = await this.librarianEntityFields.getEntityFieldsAPI(req);

    const map = new Map<bigint, EntityField>();

    for (const field of res.entityFields) {
      const id = field.entityFieldId;
      if (isNil(id)) {
        continue;
      }
      map.set(id, field);
    }

    return map;
  }

  private _addOrUpdateEntity(entity: EntityField): void {
    const id = entity.entityFieldId;
    if (entity.entityType === this._entityType && isNotNil(id)) {
      const newCache = new Map(this.cachedData());
      newCache.set(id, entity);

      this.updateCache(newCache);
    }
  }

  convertToJson(obj: Map<bigint, EntityField>): string {
    const jsonMap: Record<string, JsonValue> = {};

    for (const id of obj.keys()) {
      const field = obj.get(id);

      if (isNil(field)) {
        continue;
      }

      jsonMap[id.toString()] = field.toJson();
    }

    return JSON.stringify(jsonMap);
  }

  convertFromJson(jsonString: string): Map<bigint, EntityField> {
    const jsonMap = JSON.parse(jsonString) as Record<string, JsonValue>;

    const objsMap = new Map<bigint, EntityField>();
    for (const jsonVal of Object.values(jsonMap)) {
      const field = EntityField.fromJson(jsonVal);
      const id = field.entityFieldId;

      if (isNil(id)) {
        continue;
      }

      objsMap.set(id, field);
    }
    return objsMap;
  }
}

@Injectable({ providedIn: 'root' })
export class CreatorEntityFieldsCache extends EntityFieldsCache {
  constructor() {
    super(BaseEntityType.CREATOR);
  }
}

@Injectable({ providedIn: 'root' })
export class CampaignEntityFieldsCache extends EntityFieldsCache {
  constructor() {
    super(BaseEntityType.CAMPAIGN);
  }
}
