import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { isNotEmptyString } from '@frontend2/core';
import { NgIf } from '@angular/common';

@Component({
  selector: 'lefty-empty-state',
  template: `
    <img [attr.src]="illustration" />

    <div
      class="content"
      *ngIf="hasTitle || hasDescription"
    >
      <h4
        class="title"
        *ngIf="hasTitle"
      >
        {{ title }}
      </h4>
      <p
        class="description"
        *ngIf="hasDescription"
      >
        {{ description }}
      </p>
    </div>

    <div class="actions">
      <ng-content select="[action]"></ng-content>
    </div>
  `,
  styleUrls: ['lefty-empty-state.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf],
})
export class LeftyEmptyStateComponent {
  @Input()
  illustration = '/lefty-images/empty-illus.png';

  @Input()
  title = '';

  @Input()
  description = '';

  @Input()
  @HostBinding('class.not-centered')
  notCentered = false;

  @Input()
  @HostBinding('class.large')
  large = false;

  @Input()
  @HostBinding('class.no-illustration')
  noIllustration = false;

  get hasTitle(): boolean {
    return isNotEmptyString(this.title);
  }

  get hasDescription(): boolean {
    return isNotEmptyString(this.description);
  }
}
