import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { LeftyComponent } from '@frontend2/ui';
import { RESET_PASSWORD_PATH } from '../routes.constants';
import { redirectAfterLoginRouteCommand } from '../routes.helpers';
import { LoggedBootstrapping } from '@frontend2/proto/librarian/proto/frontend_misc_pb';

@Component({
  selector: 'alfred-login-route',
  templateUrl: 'login.route.html',
  styleUrls: ['./login.route.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlfredLoginRouteComponent extends LeftyComponent {
  private readonly _router = inject(Router);

  get resetPasswordRoute(): string {
    return `../${RESET_PASSWORD_PATH}`;
  }

  async handleLoginSucceed(bootstrap: LoggedBootstrapping): Promise<void> {
    this._router.navigate(redirectAfterLoginRouteCommand(bootstrap), {
      replaceUrl: true,
    });
  }
}
