import {
  ChangeDetectionStrategy,
  Component,
  Input,
  output,
} from '@angular/core';
import { Networks } from '@frontend2/core';
import {
  getFilterablePostTypesOfNetwork,
  getNetworkOfPostType,
  getPostTypeIcon,
  readablePostType,
} from '@frontend2/proto-helpers/common/common_pb.helpers';
import { Network, PostType } from '@frontend2/proto/common/proto/common_pb';
import { LeftyIconComponent } from '../../icon/icon.component';
import { NetworkIconComponent } from '../../icon/network.component';
import { LeftyCheckboxComponent } from '../../checkbox/checkbox.component';
import { LeftyListItemComponent } from '../../lefty-list/lefty-list-item.component';
import { LeftyListComponent } from '../../lefty-list/lefty-list.component';

@Component({
  selector: 'networks-with-posts-type-list',
  templateUrl: './network-with-post-type-list.component.html',
  styleUrls: ['./network-with-post-type-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    LeftyListComponent,
    LeftyListItemComponent,
    LeftyCheckboxComponent,
    NetworkIconComponent,
    LeftyIconComponent,
  ],
})
export class NetworksWithPostsTypeListComponent {
  @Input()
  selection = new Map<Network, PostType[]>();

  @Input()
  withSelectAll = false;

  @Input()
  set networks(values: Network[]) {
    for (const network of values) {
      this.options.set(network, getFilterablePostTypesOfNetwork(network));
    }
  }

  readonly selectionChange = output<Map<Network, PostType[]>>();

  readonly options = new Map<Network, PostType[]>();

  readonly networkUnfolded: Map<Network, boolean> = new Map();

  networkIsUnfolded(network: Network): boolean {
    return this.networkUnfolded.get(network) ?? false;
  }

  readableNetwork(network: Network): string {
    return Networks.readable(network);
  }

  networkIsFoldable(network: Network): boolean {
    const postTypes = this.options.get(network) ?? [];
    return postTypes.length > 1;
  }

  isNetworkSelected(network: Network): boolean {
    const selectedPostTypes = this.selection.get(network) ?? [];
    const availablePostTypes = this.options.get(network) ?? [];
    let allSelected = true;
    availablePostTypes.forEach((availablePostType) => {
      if (!selectedPostTypes.includes(availablePostType)) {
        allSelected = false;
        return;
      }
    });

    return allSelected;
  }

  isPostTypeSelected(postType: PostType): boolean {
    const network = getNetworkOfPostType(postType);
    const types = this.selection.get(network) ?? [];
    return types.includes(postType);
  }

  toggleFold(network: Network): void {
    if (this.networkIsFoldable(network) === false) {
      return;
    }
    const current = this.networkIsUnfolded(network);
    this.networkUnfolded.set(network, !current);
  }

  toggleNetwork(network: Network): void {
    if (this.isNetworkSelected(network)) {
      this.selection.delete(network);
    } else {
      const types = this.options.get(network)?.slice() ?? [];
      this.selection.set(network, types);
    }

    this.selectionChange.emit(this.selection);
  }

  togglePostType(postType: PostType): void {
    const network = getNetworkOfPostType(postType);
    const types = this.selection.get(network)?.slice() ?? [];
    if (this.isPostTypeSelected(postType)) {
      const newTypes = types.filter((t) => t !== postType);
      if (newTypes.length === 0) {
        this.selection.delete(network);
      } else {
        this.selection.set(network, newTypes);
      }
    } else {
      this.selection.set(network, [...types, postType]);
    }

    this.selectionChange.emit(this.selection);
  }

  hasIcon(postType: PostType): boolean {
    return getNetworkOfPostType(postType) === Network.INSTA;
  }

  postTypeIcon(postType: PostType): string {
    return getPostTypeIcon(postType);
  }

  readablePostType(postType: PostType): string {
    return readablePostType(postType);
  }

  selectAll(): void {
    this.selection = new Map(this.options);
    this.selectionChange.emit(this.selection);
  }

  clearAll(): void {
    this.selection = new Map();
    this.selectionChange.emit(this.selection);
  }
}
