<lefty-dialog
  [autoDismissable]="true"
  (dismiss$)="close()"
  size="small"
  [(visible)]="visible"
  [hideFooter]="true"
>
  <h3
    class="centered"
    title
  >
    {{ title }}
  </h3>
  <h4
    class="contact-message message"
    *ngIf="hasMessage"
  >
    {{ message }}
  </h4>
  <div class="contact-info body-1">
    <a
      href="mailto:lefty@contact@lefty.io"
      target="_top"
      >contact&#64;lefty.io</a
    >
  </div>
</lefty-dialog>
