<lefty-card [withBorder]="true">
  <div class="main-section">
    <div class="left-col">
      <div class="title">
        <div
          class="icon-container"
          *ngIf="hasTitleIcon"
        >
          <lefty-icon [icon]="titleIcon"></lefty-icon>
        </div>
        <h4>{{ title }}</h4>
      </div>
      <p
        class="description"
        *ngIf="hasDescription"
      >
        {{ description }}
      </p>
    </div>
    <div
      class="toggle-container"
      *ngIf="noToggle === false"
    >
      <lefty-toggle
        [checked]="value"
        (checkedChange)="handleValueChange($event)"
        [disabled]="disabled"
      ></lefty-toggle>
    </div>
  </div>
  <div
    class="secondary-container"
    *ngIf="mustShowSecondaryContent"
  >
    <ng-content></ng-content>
  </div>
</lefty-card>
