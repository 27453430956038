import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { NgModule, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getDatabase, provideDatabase } from '@angular/fire/database';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NavigationEnd, RouterModule } from '@angular/router';
import { datadogRum } from '@datadog/browser-rum';
import { API_HOST, LeftyAuthBloc, LeftyAuthModule } from '@frontend2/api';
import {
  IS_IN_IFRAME,
  LeftyParentAppBridge,
  isNotEmptyString,
  isNotNil,
} from '@frontend2/core';
import {
  CampaignsCache,
  LeftyErrorHandlerModule,
  LeftyEventsModule,
  UiModule,
  injectCampaignLabelsCache,
  injectInfluencerLabelsCache,
  injectRouter,
  maybeProvideLeftyRouter,
  provideIsReadOnlyApp,
} from '@frontend2/ui';
import posthog from 'posthog-js';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routes';
import {
  isCampaignSharingApp,
  provideAlfredoAppUrl,
} from './campaign_sharing_token.helpers';
import { AffiliationProgramsCache } from './common/affiliation-programs.cache';
import { AlfredCommonModule } from './common/common.module';
import { CreateGiftService } from './common/create-gift.service';
import { AlfredEventsBus } from './common/events/events';
import { provideNestorAppUrl } from './common/nestor-app-url.token';
import { LandingPagesCache } from './landing-pages/landing-pages.cache';
import { AlfredLoginRouteComponent } from './login/login.route';
import { AlfredResetPasswordFormComponent } from './reset-password/components/reset-password-form.component';
import { AlfredResetPasswordRouteComponent } from './reset-password/reset-password.route';
import { AlfredWorkspaceSelectionRouteComponent } from './workspace-selection/workspace-selection.route';

@NgModule({
  declarations: [
    AppComponent,
    AlfredLoginRouteComponent,
    AlfredWorkspaceSelectionRouteComponent,
    AlfredResetPasswordRouteComponent,
    AlfredResetPasswordFormComponent,
  ],
  imports: [
    BrowserModule,
    LeftyErrorHandlerModule,
    UiModule,
    LeftyAuthModule,
    AlfredCommonModule,

    LeftyEventsModule.forRoot(AlfredEventsBus),
    RouterModule.forRoot(appRoutes, {
      // The app is inserted inside an iframe (inside Alfred Dart)
      // and it works better when router use 'hash' path (/#/campaigns ...)
      // (it does reload the app each time we change the iframe URL)
      useHash: true,
    }),
    ReactiveFormsModule,
  ],
  providers: [
    { provide: API_HOST, useValue: environment.apiHost },
    provideNestorAppUrl(environment.isProduction),
    provideAlfredoAppUrl(environment.isProduction),
    provideIsReadOnlyApp(isCampaignSharingApp()),
    LeftyParentAppBridge.INITIALIZER,
    provideHttpClient(withInterceptorsFromDi()),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => getFirestore()),
    provideAuth(() => getAuth()),
    provideDatabase(() => getDatabase()),
    ...maybeProvideLeftyRouter(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  readonly auth = inject(LeftyAuthBloc);
  readonly router = injectRouter();
  readonly parentAppBridge = inject(LeftyParentAppBridge);
  readonly createGiftService = inject(CreateGiftService);

  constructor() {
    this._syncData();
    this._wirePosthog();
    this._wireDatadogRouter();
    this._subscribePreselectInfluencersForGift();
  }

  private _syncData(): void {
    this.parentAppBridge.syncDataService(inject(LeftyAuthBloc));
    this.parentAppBridge.syncDataService(inject(LandingPagesCache));
    this.parentAppBridge.syncDataService(injectInfluencerLabelsCache());
    this.parentAppBridge.syncDataService(injectCampaignLabelsCache());
    this.parentAppBridge.syncDataService(inject(CampaignsCache));
    this.parentAppBridge.syncDataService(inject(AffiliationProgramsCache));
  }

  private _wirePosthog(): void {
    if (isNotEmptyString(environment.posthogToken) && IS_IN_IFRAME === false) {
      this.router.events.pipe(takeUntilDestroyed()).subscribe((event) => {
        if (event instanceof NavigationEnd) {
          posthog.capture('$pageview');
        }
      });
    }
  }

  private _wireDatadogRouter(): void {
    if (
      'datadog' in environment &&
      isNotNil(environment.datadog) &&
      IS_IN_IFRAME === false
    ) {
      this.router.events.pipe(takeUntilDestroyed()).subscribe((event) => {
        if (event instanceof NavigationEnd) {
          datadogRum.startView({
            name: event.urlAfterRedirects,
          });
        }
      });
    }
  }

  private _subscribePreselectInfluencersForGift(): void {
    this.parentAppBridge.preselectInfluencersForGifts$
      .pipe(takeUntilDestroyed())
      .subscribe((event) => {
        this.createGiftService.setPreselectedInfluencers(event.influencers);
      });
  }
}
