<lefty-form
  [label]="label"
  [required]="required"
  [optional]="optional"
  [helpText]="helpText"
  [externalHelpLink]="externalHelpLink"
  [helpLink]="helpLink"
  [hintText]="hintText"
  [tooltip]="tooltip"
  [large]="large"
  [topHintText]="topHintText"
>
  <input
    type="file"
    #input
    [attr.accept]="accept"
    class="display-none"
    (change)="onFilesAdded()"
  />

  <div
    class="container"
    [class.empty]="isEmptyState"
  >
    <div
      class="drop-container"
      *ngIf="isEmptyState"
      [class.loading]="loading"
      [class.ondragover]="draggingOver"
      (dragover)="onDragOver()"
      (dragleave)="onDragLeave()"
      (drop)="onDrop($event)"
    >
      <ng-container *ngIf="loading === false">
        <button
          (click)="addFile()"
          class="add-file-button"
          type="button"
          i18n
        >
          Add file
        </button>
        <span
          class="drop-img-helper"
          i18n
        >
          or drop to upload
        </span>

        <div
          class="file-format-helper"
          *ngIf="showFileFormatHelper"
        >
          {{ fileFormatHelper }}
        </div>

        <span
          class="error-message"
          *ngIf="showErrorMessage"
        >
          {{ errorMessage }}
        </span>
      </ng-container>

      <lefty-progress
        *ngIf="loading === true"
        [activeProgress]="progress"
      >
      </lefty-progress>
    </div>

    <div
      *ngIf="!isEmptyState"
      class="al-card with-border file-card"
    >
      <h4 class="filename">{{ filename }}</h4>
      <span class="size caption-1">{{ formattedSize }}</span>

      <button
        type="button"
        class="plain"
        (click)="clear()"
        i18n
      >
        Remove
      </button>
    </div>
  </div>
</lefty-form>
