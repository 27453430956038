import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Optional,
  Self,
} from '@angular/core';
import { isNotEmptyString } from '@frontend2/core';

import { NgControl } from '@angular/forms';
import { LeftyFormValueBase } from '../../form';
import { LeftyToggleComponent } from '../../lefty-toggle/lefty-toggle.component';
import { LeftyIconComponent } from '../../icon/icon.component';
import { NgIf } from '@angular/common';
import { LeftyCardComponent } from '../lefty-card.component';

@Component({
  selector: 'lefty-card-with-toggle',
  templateUrl: './lefty-card-with-toggle.component.html',
  styleUrls: ['../expandable-shared.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LeftyCardComponent, NgIf, LeftyIconComponent, LeftyToggleComponent],
})
export class LeftyCardWithToggleComponent extends LeftyFormValueBase<boolean> {
  constructor(@Self() @Optional() ngControl?: NgControl) {
    super(false, ngControl);
  }
  @Input()
  noToggle = false;

  @Input()
  titleIcon = '';

  @Input()
  title = '';

  @Input()
  description = '';

  get mustShowSecondaryContent(): boolean {
    return this.noToggle || this.value === true;
  }

  get hasTitleIcon(): boolean {
    return isNotEmptyString(this.titleIcon);
  }

  get hasDescription(): boolean {
    return isNotEmptyString(this.description);
  }

  toggleValue(): void {
    this.handleValueChange(!this.value);
  }

  writeValue(obj: unknown): void {
    if (typeof obj === 'boolean') {
      this.value = obj;
    }
  }
}
