import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Empty, JsonValue } from '@bufbuild/protobuf';
import { injectLibrarianLandingPagesClient } from '@frontend2/api';
import { updateElementInArray } from '@frontend2/core';
import { LandingPagePb } from '@frontend2/proto/librarian/proto/landing_pages_pb';
import { IframeSyncedCacheBloc } from '@frontend2/ui';
import { injectAlfredEventBus } from '../common/events/events';

@Injectable({ providedIn: 'root' })
export class LandingPagesCache extends IframeSyncedCacheBloc<LandingPagePb[]> {
  readonly landingRpc = injectLibrarianLandingPagesClient();
  readonly alfredEvents = injectAlfredEventBus();

  constructor() {
    super([]);

    this.alfredEvents
      .on('update_landing_page')
      .pipe(takeUntilDestroyed())
      .subscribe((page) => {
        this._addOrUpdateLandingPage(page);
      });

    this.alfredEvents
      .on('create_landing_page')
      .pipe(takeUntilDestroyed())
      .subscribe((page) => {
        this._addOrUpdateLandingPage(page);
      });

    this.alfredEvents
      .on('archive_or_restore_landing_page')
      .pipe(takeUntilDestroyed())
      .subscribe((page) => {
        return this._addOrUpdateLandingPage(
          new LandingPagePb({ ...page, archived: !page.archived }),
        );
      });
  }

  override async fetch(): Promise<LandingPagePb[]> {
    return this.landingRpc.getAllPagesAPI(new Empty()).then((response) => {
      return response.pages;
    });
  }

  getLandingById(id: bigint): LandingPagePb {
    return (
      this.cachedData().find((element) => element.id === id) ??
      new LandingPagePb()
    );
  }

  private _addOrUpdateLandingPage(page: LandingPagePb): void {
    // don't want to update cache if not loaded yet
    if (this.isLoaded() === false) {
      return;
    }

    const newList = updateElementInArray(this.cachedData(), {
      predicate: (p) => p.id === page.id,
      stopOnFirst: true,
      appendIfNotFound: page,
      modifier: () => page,
    });
    this.updateCache(newList);
  }

  readonly syncName = 'landing_pages';

  convertToJson(obj: LandingPagePb[]): string {
    return JSON.stringify(obj);
  }

  convertFromJson(jsonString: string): LandingPagePb[] {
    const array = JSON.parse(jsonString) as JsonValue[];
    return array.map((val) => LandingPagePb.fromJson(val));
  }
}
