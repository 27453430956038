import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NavItem, LeftyRouterLinkDirective } from '../router-utils';
import { LeftyListItemComponent } from '../lefty-list/lefty-list-item.component';
import { NgFor } from '@angular/common';
import { LeftyListComponent } from '../lefty-list/lefty-list.component';
import { LeftyPopupComponent } from '../lefty-popup/lefty-popup.component';
import { LeftyIconComponent } from '../icon/icon.component';

@Component({
  selector: 'lefty-nav-more-button',
  templateUrl: './lefty-navigation-more.component.html',
  styleUrls: ['./lefty-navigation-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    LeftyIconComponent,
    LeftyPopupComponent,
    LeftyListComponent,
    NgFor,
    LeftyListItemComponent,
    LeftyRouterLinkDirective,
  ],
})
export class LeftyNavigationMoreComponent {
  @Input()
  navItems: NavItem[] = [];
}
