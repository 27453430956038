import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  signal,
} from '@angular/core';
import { LeftyValidators, createOutput } from '@frontend2/ui';
import { FormControl, Validators } from '@angular/forms';
import { isNotEmptyString } from '@frontend2/core';
import { ResetPasswordState } from '../reset-password.route';

@Component({
  selector: 'alfred-reset-password-form-component',
  templateUrl: './reset-password-form.component.html',
  styleUrls: ['./reset-password-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlfredResetPasswordFormComponent {
  @Input()
  state = ResetPasswordState.enterEmail;

  @Input()
  hasInvalidToken = false;

  @Input()
  loading = false;

  @Output()
  readonly emailIsEntered$ = createOutput<string>();

  @Output()
  readonly gotoHomePage$ = createOutput<void>();

  @Output()
  readonly newPassword$ = createOutput<string>();

  get isEnterEmail(): boolean {
    return this.state === ResetPasswordState.enterEmail;
  }

  get isGoToInbox(): boolean {
    return this.state === ResetPasswordState.gotoInbox;
  }

  get isEnterNewPassword(): boolean {
    return this.state === ResetPasswordState.enterNewPassword;
  }

  get isPasswordChanged(): boolean {
    return this.state === ResetPasswordState.passwordChanged;
  }

  emailFormControl = new FormControl<string>('', [
    LeftyValidators.emailValidator,
    Validators.required,
  ]);

  passwordFormControl = new FormControl<string>('', [
    Validators.minLength(6),
    Validators.required,
  ]);

  confirmPasswordFormControl = new FormControl<string>('', [
    Validators.minLength(6),
    Validators.required,
  ]);

  passwordChangeSubmited(): void {
    const currentPassword = this.passwordFormControl.value;
    if (
      currentPassword === this.confirmPasswordFormControl.value &&
      isNotEmptyString(currentPassword)
    ) {
      this.passwordsDontMatch.set(false);
      this.newPassword$.next(currentPassword);
    } else {
      this.passwordsDontMatch.set(true);
    }
  }

  passwordsDontMatch = signal(false);
}
