<div
  class="timeline-container"
  *ngIf="isShowMore()"
>
  <div
    *ngFor="let point of points"
    class="timeline-point"
  >
    <div class="line-stroke">
      <div class="top-div"></div>
      <div class="bottom-div"></div>
    </div>
    <div
      class="dot"
      [ngClass]="getColorClass(point)"
    ></div>
    <div class="label-text">
      {{ point.label }} -
      <span class="date">{{ formatDate(point.date) }} </span>
    </div>
  </div>
</div>

<div
  class="timeline-container"
  *ngIf="!isShowMore()"
>
  <div class="timeline-point">
    <div class="line-stroke">
      <div class="top-div"></div>
      <div class="bottom-div"></div>
    </div>
    <div
      class="dot"
      [ngClass]="getColorClass(points[0])"
    ></div>
    <div class="label-text">
      {{ points[0].label }} -
      <span class="date">{{ formatDate(points[0].date) }} </span>
    </div>
  </div>

  <div
    class="show-more"
    (click)="showMoreClicked.set(true)"
  >
    <p i18n>Show {{ points.length - 2 }} more</p>
  </div>

  <div class="timeline-point">
    <div class="line-stroke">
      <div class="top-div"></div>
      <div class="bottom-div"></div>
    </div>
    <div
      class="dot"
      [ngClass]="getColorClass(points[points.length - 1])"
    ></div>
    <div class="label-text">
      {{ points[points.length - 1].label }} -
      <span class="date"
        >{{ formatDate(points[points.length - 1].date) }}
      </span>
    </div>
  </div>
</div>
