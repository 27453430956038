import { inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { LeftyAuthBloc } from '@frontend2/api';

// redirect to main path if already logged in
export const alfredLoginGuard = (): boolean | UrlTree => {
  const router = inject(Router);
  const auth = inject(LeftyAuthBloc);

  if (auth.isLogged) {
    return router.parseUrl('/');
  }
  return true;
};
