import {
  SalesforceCategoryPb,
  SalesforceProductVariantPb,
} from '@frontend2/proto/librarian/proto/salesforce_pb';
import { isNotEmptyArray, isNotNil } from '../../utils/common.helpers';
import { isEmptyString } from '../../utils/strings.helpers';

//so many recursive functions, normally with the correct data it should be fine. this is just in case!
const MAX_RECURSION_DEPTH = 50;

export function isGhostSalesforceCategoryPb(
  category: SalesforceCategoryPb,
): boolean {
  return isEmptyString(category.id);
}

export function findParentFromSubcategory(
  subcategoryId: string,
  categories: SalesforceCategoryPb[],
  depth = 0,
): SalesforceCategoryPb {
  if (depth > MAX_RECURSION_DEPTH) {
    return new SalesforceCategoryPb();
  }

  for (const category of categories) {
    for (const subcategory of category.subcategories) {
      if (subcategory.id === subcategoryId) {
        return category;
      }
      // Recursively search in subcategories of the subcategory
      const parentCategory = findParentFromSubcategory(
        subcategoryId,
        category.subcategories,
        depth + 1,
      );
      if (!isGhostSalesforceCategoryPb(parentCategory)) {
        return parentCategory;
      }
    }
  }
  return new SalesforceCategoryPb();
}

//a recursive function to get category's id and ids of all subcategories
export function getAllCategoryIds(category: SalesforceCategoryPb): string[] {
  const ids: string[] = [];

  function collectIds(cat: SalesforceCategoryPb, depth = 0): void {
    if (depth > MAX_RECURSION_DEPTH) {
      return;
    }
    ids.push(cat.id);
    cat.subcategories.forEach((subcategory) =>
      collectIds(subcategory, depth + 1),
    );
  }

  collectIds(category);

  return ids;
}

//a function to get all parents of a category in order (first one is the top category and last one is direct parent)
export function getAllCategoryParents(
  categoryId: string,
  categories: SalesforceCategoryPb[],
): SalesforceCategoryPb[] {
  let depth = 0;

  const parentCategories: SalesforceCategoryPb[] = [];

  let parentCategory = findParentFromSubcategory(categoryId, categories, depth);

  while (
    isNotNil(parentCategory) &&
    !isGhostSalesforceCategoryPb(parentCategory) &&
    depth < MAX_RECURSION_DEPTH
  ) {
    parentCategories.unshift(parentCategory);
    parentCategory = findParentFromSubcategory(parentCategory.id, categories);
    depth++;
  }

  return parentCategories;
}

export function getSalesforceProductVariantName(
  variant: SalesforceProductVariantPb,
): string {
  return variant.attributes.map((a) => a.value).join(' / ');
}

//looks in all categories an their subcategories to fing the id
export function getCategoryFromId(
  categoryId: string,
  categories: SalesforceCategoryPb[],
): SalesforceCategoryPb | undefined {
  function findCategory(
    id: string,
    categoriesToSearch: SalesforceCategoryPb[],
    currentDepth = 0,
  ): SalesforceCategoryPb | undefined {
    if (currentDepth > MAX_RECURSION_DEPTH) {
      return;
    }

    for (const category of categoriesToSearch) {
      if (category.id === id) {
        return category;
      }
      if (isNotEmptyArray(category.subcategories)) {
        const foundInSubcategory = findCategory(
          id,
          category.subcategories,
          currentDepth + 1,
        );
        if (isNotNil(foundInSubcategory)) {
          return foundInSubcategory;
        }
      }
    }
    return undefined;
  }
  return findCategory(categoryId, categories);
}

//get the top level category ids from selected category ids
export function getTopLevelCategories(
  categories: SalesforceCategoryPb[],
  selectedCategoryIds: string[],
): string[] {
  let filteredCategoryIds: string[] = [...selectedCategoryIds];

  for (const selectedId of selectedCategoryIds) {
    const category = getCategoryFromId(selectedId, categories);
    if (isNotNil(category)) {
      const allsubCategoryIds = getAllCategoryIds(category).filter(
        (c) => c !== category.id,
      );
      filteredCategoryIds = filteredCategoryIds.filter(
        (c) => !allsubCategoryIds.includes(c),
      );
    }
  }

  return filteredCategoryIds;
}
