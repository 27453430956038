import {
  DraftGiftError,
  DraftGiftPb,
  DraftGiftPb_DraftGiftErrorsNames,
  GiftPb,
  GiftState,
  GiftStatus,
} from '@frontend2/proto/librarian/proto/gifts_pb';
import { isNil, joinWithAndComma, plural } from '../../utils/common.helpers';
import { isEmptyString, isNotEmptyString } from '../../utils/strings.helpers';
import { isGhostAddressFieldProto } from './creator_fields_pb.helpers';

export function isGhostGift(gift: GiftPb): boolean {
  return gift.id === BigInt(0);
}

export function isGhostDraftGift(gift: DraftGiftPb): boolean {
  return gift.id === BigInt(0);
}

export function giftStatusToReadable(giftStatus: GiftStatus): string {
  switch (giftStatus) {
    case GiftStatus.UNSPECIFIED:
      return '-';
    case GiftStatus.PENDING:
      return $localize`Pending`;
    case GiftStatus.SHIPPED:
      return $localize`Shipped`;
    case GiftStatus.IN_TRANSIT:
      return $localize`In transit`;
    case GiftStatus.DELIVERED:
      return $localize`Delivered`;
    case GiftStatus.CANCELED:
      return $localize`Canceled`;
    case GiftStatus.PENDING_INFLUENCER_APPROVAL:
      return $localize`Waiting for influencer's approval`;
  }
}

export function giftIsSynchronized(gift: GiftPb): boolean {
  return gift.isSyncedWithEcommerceStore === true;
}

export function giftIsHandDelivered(gift: GiftPb): boolean {
  return (
    isNil(gift.recipientAddress) ||
    isGhostAddressFieldProto(gift.recipientAddress)
  );
}

export function giftStateToReadable(giftState: GiftState): string {
  switch (giftState) {
    case GiftState.ARCHIVED:
      return $localize`Archived`;
    case GiftState.LIVE:
      return $localize`Active`;
    default:
      return $localize`Unknown`;
  }
}

export function draftGiftErrorToReadable(error: DraftGiftError): string {
  switch (error) {
    case DraftGiftError.UNSPECIFIED:
      return $localize`Some fields are not valid anymore.`;
    case DraftGiftError.MISSING_PRODUCTS:
      return $localize`Some selected items are currently unavailable.`;

    case DraftGiftError.MISSING_STORE:
      return $localize`Selected store is currently unavailable.`;

    case DraftGiftError.MISSING_SHIPPING_METHODS:
      return $localize`Shipping method is unavailable.`;

    case DraftGiftError.MISSING_QUANTITIES:
      return $localize`Not enough stock for some products.`;

    case DraftGiftError.MISSING_INFLUENCERS:
      return $localize`Some influencers are currently unavailable.`;
  }
}

export function draftGiftErrorMessageForError(
  draft: DraftGiftPb,
  error: DraftGiftError,
): string {
  const names = draft.errorsNames ?? new DraftGiftPb_DraftGiftErrorsNames();
  const draftErrors = draft.errors;
  if (!draftErrors.includes(error)) {
    return '';
  }

  switch (error) {
    case DraftGiftError.MISSING_PRODUCTS: {
      const errorNames = names.missingProductsNames.map((s) => `"${s}"`);
      const count = names.missingProductsNames.length;

      return $localize`${joinWithAndComma(errorNames)} ${plural(count, { one: $localize`is`, other: `are` })} currently unavailable.`;
    }
    case DraftGiftError.MISSING_STORE:
      return $localize`"${names.missingStoreName}" is currently unavailable.`;

    case DraftGiftError.MISSING_SHIPPING_METHODS:
      return $localize`"${names.missingShippingMethodName}" is unavailable.`;

    case DraftGiftError.MISSING_QUANTITIES: {
      const errorNames = names.missingQuantitiesProductsNames.map(
        (s) => `"${s}"`,
      );
      return $localize`Not enough stock for ${joinWithAndComma(errorNames)}.`;
    }

    case DraftGiftError.MISSING_INFLUENCERS: {
      const count = names.missingInfluencersNames.length;
      const errorNames = names.missingInfluencersNames.map((s) => `"${s}"`);

      return $localize`${joinWithAndComma(errorNames)} ${plural(count, { one: $localize`is`, other: `are` })} currently unavailable.`;
    }
    default:
      return '';
  }
}

export function buildGiftDraftErrorWithDetail(draft: DraftGiftPb): string[] {
  const errors = draft.errors;
  const errorMessages: string[] = [];

  for (const error of errors) {
    let message = draftGiftErrorMessageForError(draft, error);

    if (isEmptyString(message)) {
      message = draftGiftErrorToReadable(error);
    }
    errorMessages.push(message);
  }
  return errorMessages.filter(isNotEmptyString);
}
