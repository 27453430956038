<lefty-footer>
  <div class="content">
    <div class="left-side">
      <h4>{{ formattedSelectionCount }} {{ selectionLabel }}</h4>
      <button
        class="outline large"
        *ngIf="showSelectAll"
        (click)="selectAll$.next($event)"
        i18n
      >
        Select all
      </button>
      <ng-content select="[left]"></ng-content>
    </div>
    <div class="center">
      <ng-content select="[action]"></ng-content>

      <lefty-menu-icon
        buttonClass="large"
        [popupPlacement]="popupPlacement"
      >
        <ng-content select="[action-dropdown]"> </ng-content>
      </lefty-menu-icon>
    </div>
    <div class="right-side">
      <ng-content select="[right]"></ng-content>
      <button
        class="large"
        (click)="cancel$.next($event)"
        i18n
      >
        Cancel
      </button>
    </div>
  </div>
</lefty-footer>
