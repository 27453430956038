import { Injectable, inject } from '@angular/core';
import { Int64Value } from '@bufbuild/protobuf';
import { PaymentInfo } from '@frontend2/proto-helpers/librarian/payments_pb.helpers';
import {
  AffiliationPromoCodePb,
  AffiliationPromoCodeWithInfoPb,
} from '@frontend2/proto/librarian/proto/affiliation_promo_codes_pb';
import { RefreshVisualsReq } from '@frontend2/proto/librarian/proto/campaigns_pb';
import {
  Casting,
  ConvertCastingToCampaignResponse,
} from '@frontend2/proto/librarian/proto/casting_pb';
import { ReportSummary } from '@frontend2/proto/librarian/proto/competitive_watch_pb';
import {
  EcommerceStoreWithInfoPb,
  ManualStorePb,
  SalesforceStorePb,
  ShopifyStorePbV2,
} from '@frontend2/proto/librarian/proto/ecommerce_stores_pb';
import { DraftGiftPb, GiftPb } from '@frontend2/proto/librarian/proto/gifts_pb';
import { HomeDashboardMetric } from '@frontend2/proto/librarian/proto/home_dashboard_pb';
import { LandingPagePb } from '@frontend2/proto/librarian/proto/landing_pages_pb';
import { PaymentMethodPb } from '@frontend2/proto/librarian/proto/payments_pb';
import { ProductPb } from '@frontend2/proto/librarian/proto/products_pb';
import { ProfileMergeRequestsPb } from '@frontend2/proto/librarian/proto/profile_merge_requests_pb';
import {
  SendRegisteredAuthorProductRequestResponse,
  UpdateRegisteredAuthorProductRequestStatusRequest,
} from '@frontend2/proto/librarian/proto/registered_author_product_requests_pb';
import { UserBrandPb } from '@frontend2/proto/librarian/proto/user_brand_pb';
import { LeftyEvents, LeftyEventsBus } from '@frontend2/ui';
import { HomeMetricCard } from '../../home/home-metrics.model';

interface SelectedMetric {
  readonly metricId: HomeDashboardMetric;
  readonly value: boolean;
  readonly fromModal: boolean;
}

type AlfredEvents = LeftyEvents & {
  create_or_update_product: ProductPb;
  archive_or_unarchive_products: bigint[];
  load_product_sidebar: Int64Value;
  delete_products: bigint[];
  create_or_update_user_brand: UserBrandPb;
  create_landing_page: LandingPagePb;
  update_landing_page: LandingPagePb;
  archive_or_restore_landing_page: LandingPagePb;
  create_gifts: GiftPb[];
  update_gifts: GiftPb[];
  archive_or_unarchive_gift_bulk: GiftPb[];
  add_or_edit_gift_draft: DraftGiftPb;
  gift_drafts_deleted: bigint[];
  shopify_disconnected: ShopifyStorePbV2;
  create_or_update_manual_store: ManualStorePb;
  archive_or_unarchive_store: EcommerceStoreWithInfoPb;
  edit_store_settings: EcommerceStoreWithInfoPb;
  reorder_list: HomeMetricCard[];
  show_or_hide_metrics: HomeMetricCard[];
  select_metric: SelectedMetric;
  refresh_campaign: RefreshVisualsReq;
  delete_payment_method: PaymentMethodPb;
  add_or_edit_note_on_payment: PaymentInfo;
  updated_product_request_status: UpdateRegisteredAuthorProductRequestStatusRequest;
  product_request_sent: SendRegisteredAuthorProductRequestResponse;
  restocked_product_variants: Map<bigint, number>;
  added_salesforce_store: SalesforceStorePb[];
  convert_casting_to_campaign_response: ConvertCastingToCampaignResponse;
  convert_casting_to_campaign_done: ConvertCastingToCampaignResponse;
  archive_cw_report: bigint;
  restore_cw_report: bigint;
  update_casting: Casting;
  create_casting: Casting;
  create_or_update_cw_report: ReportSummary;
  affiliation_promo_codes_status_updated: AffiliationPromoCodeWithInfoPb[];
  affiliation_promo_code_updated: AffiliationPromoCodeWithInfoPb;
  affiliation_promo_codes_created: AffiliationPromoCodePb[];
  ongoing_merge_request: ProfileMergeRequestsPb;
  archive_casting: bigint;
  restore_casting: bigint;
};

@Injectable({ providedIn: 'root' })
export class AlfredEventsBus extends LeftyEventsBus<AlfredEvents> {}

export function injectAlfredEventBus(): AlfredEventsBus {
  return inject(AlfredEventsBus);
}
