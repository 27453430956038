<lefty-form
  [label]="label"
  [required]="required"
  [optional]="optional"
  [helpText]="helpText"
  [helpLink]="helpLink"
  [hintText]="hintText"
  [errorMessage]="errorMessage"
  [tooltip]="tooltip"
  [large]="large"
  [topHintText]="topHintText"
>
  <div class="input-box">
    <div #inputBox></div>
  </div>
</lefty-form>
