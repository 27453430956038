import { Inject, Injectable } from '@angular/core';
import { StringValue } from '@bufbuild/protobuf';
import {
  BroncoTask,
  LibrarianPoolClient,
  LibrarianPoolClientProvider,
} from '@frontend2/api';
import { Messages, isNotEmptyString } from '@frontend2/core';
import { PoolExportReq } from '@frontend2/proto/librarian/proto/pool_pb';
import { TOAST_UNLIMITED_DURATION, Toast } from '../toast/toast.models';
import { ToastManager } from '../toast/toast.service';
import { BroncoService } from './bronco.service';
import { DownloaderService } from './downloader.service';

@Injectable()
export abstract class Exporter<Request> {
  constructor(
    private bronco: BroncoService,
    private downloader: DownloaderService,
    private toastManager: ToastManager,
  ) {}

  abstract doExport(req: Request): Promise<StringValue>;

  abstract get progressMessage(): string;
  abstract get errorMessage(): string;
  abstract successMessage(link: string): string;

  private showLoadingToast(): Toast {
    return this.toastManager.showLoading(this.progressMessage);
  }

  getLink(task: BroncoTask): string | undefined {
    const output = task.output;
    if (!output) {
      return;
    }

    try {
      return JSON.parse(output)['url'];
    } catch (e) {
      return;
    }
  }

  getHtmlLink(task: BroncoTask): string {
    return `<a href="${this.getLink(task)}" download>${Messages.download}</a>`;
  }

  private subscribeBronco(
    handle: string,
    request: Request,
    existingToast: Toast,
  ): void {
    this.bronco
      .subscribe(handle, {
        successMessage: (t) =>
          t ? this.successMessage(this.getHtmlLink(t)) : '',
        errorMessage: () => this.errorMessage,
        progressMessage: () => this.progressMessage,
        toast: existingToast,
        // unlimited, we want user to have time to click on download button
        duration: TOAST_UNLIMITED_DURATION,
      })
      .wait()
      .then((task) => {
        const link = this.getLink(task);
        if (link) {
          this.downloader.downloadLink(link);
        }
      });
  }

  async export(req: Request): Promise<string> {
    const toast = this.showLoadingToast();
    const token = await this.doExport(req);
    const tokenValue = token.value;

    if (isNotEmptyString(tokenValue)) {
      this.subscribeBronco(tokenValue, req, toast);
    } else {
      this.toastManager.close(toast.id);
    }

    return tokenValue;
  }
}

@Injectable()
export abstract class XlsxExporter<Request> extends Exporter<Request> {
  override get progressMessage(): string {
    return $localize`We are preparing your xlsx export. This can take a few minutes`;
  }

  override get errorMessage(): string {
    return $localize`Failed to export as xlsx`;
  }

  override successMessage(link: string): string {
    return $localize`Your xlsx export is ready - ${link}`;
  }
}

@Injectable()
export abstract class PPTExporter<Request> extends Exporter<Request> {
  override get progressMessage(): string {
    return $localize`We are preparing your PPT export. This can take a few minutes`;
  }

  override get errorMessage(): string {
    return $localize`Failed to export as PPT`;
  }

  override successMessage(link: string): string {
    return $localize`Your PPT export is ready - ${link}`;
  }
}

@Injectable({ providedIn: 'root' })
export class PoolXlsxExporter extends XlsxExporter<PoolExportReq> {
  constructor(
    @Inject(LibrarianPoolClientProvider)
    private librarian: LibrarianPoolClient,
    bronco: BroncoService,
    downloader: DownloaderService,
    toastManager: ToastManager,
  ) {
    super(bronco, downloader, toastManager);
  }

  doExport(req: PoolExportReq): Promise<StringValue> {
    return this.librarian.requestPoolExportExcelTask(req);
  }
}
