import { Injectable, inject } from '@angular/core';
import { Permission } from '@frontend2/proto/common/proto/authentication_pb';
import { Feature } from '@frontend2/proto/librarian/proto/common_pb';
import { LeftyAuthBloc } from './auth.bloc';
import { FeaturesService } from './features.service';

@Injectable({ providedIn: 'root' })
export class PermissionService {
  constructor(private auth: LeftyAuthBloc) {}
  readonly featureService = inject(FeaturesService);

  hasPermissions(permissions: Permission[]): boolean {
    const user = this.auth.user;

    for (const perm of permissions) {
      if (user.permissions.includes(perm)) {
        return true;
      }
    }

    return false;
  }

  get canManageCampaigns(): boolean {
    return (
      this.hasPermissions([Permission.CAMPAIGNS]) &&
      this.featureService.hasFeature([Feature.CAMPAIGNS_FEATURE])
    );
  }

  get canManageDirectory(): boolean {
    return this.hasPermissions([Permission.DIRECTORY]);
  }

  get canGenerateAudience(): boolean {
    return this.hasPermissions([Permission.AUDIENCE]);
  }

  get canSendMessages(): boolean {
    return (
      this.hasPermissions([Permission.AUDIENCE]) &&
      this.featureService.hasFeature([Feature.MESSAGES_FEATURE])
    );
  }

  get canManageAffiliations(): boolean {
    return (
      this.hasPermissions([Permission.AFFILIATIONS]) &&
      this.featureService.hasFeature([Feature.AFFILIATION_FEATURE])
    );
  }

  get canManageCastings(): boolean {
    return (
      this.hasPermissions([Permission.CASTINGS]) &&
      this.featureService.hasFeature([Feature.CAMPAIGNS_FEATURE])
    );
  }

  get canManageLandingPages(): boolean {
    return (
      this.hasPermissions([Permission.LANDING_PAGES]) &&
      this.featureService.hasFeature([Feature.LANDING_PAGE_FEATURE])
    );
  }

  get canPay(): boolean {
    return (
      this.hasPermissions([Permission.PAYMENTS]) &&
      this.featureService.hasFeature([Feature.PAYMENTS_FEATURE])
    );
  }

  get canEditSettings(): boolean {
    return this.hasPermissions([Permission.SETTINGS]);
  }

  get canEditPaymentMethods(): boolean {
    return (
      this.featureService.hasFeature([Feature.PAYMENTS_FEATURE]) &&
      this.hasPermissions([Permission.SETTINGS, Permission.PAYMENTS])
    );
  }

  get canEditPermissions(): boolean {
    return this.hasPermissions([Permission.PERMISSIONS]);
  }

  get canManageProducts(): boolean {
    return (
      this.hasPermissions([Permission.PRODUCTS]) &&
      this.featureService.hasFeature([Feature.PRODUCTS_SEEDING_FEATURE])
    );
  }

  get canManageAnalyics(): boolean {
    return (
      this.hasPermissions([Permission.ANALYTICS]) &&
      this.featureService.hasFeature([Feature.ANALYTICS_FEATURE])
    );
  }
}
