<div
  *ngIf="isEnterEmail"
  class="form-col"
>
  <lefty-form-input
    label="Email"
    placeholder="name@company.com"
    i18n-label
    #emailField
    autocomplete="email"
    [formControl]="emailFormControl"
  ></lefty-form-input>
  <p
    *ngIf="hasInvalidToken"
    i18n
    class="error-message"
  >
    Reset link is not valid please retry
  </p>
  <button
    i18n
    leftyButton="primary"
    size="large"
    [disabled]="loading || emailFormControl.invalid"
    [loading]="loading"
    (click)="emailIsEntered$.next(emailFormControl.value ?? '')"
  >
    Get reset link
  </button>
  <button
    i18n
    leftyButton="plain"
    size="large"
    [disabled]="loading"
    (click)="gotoHomePage$.next()"
  >
    Back to sign in
  </button>
</div>

<div *ngIf="isGoToInbox">
  <p class="desc">Go to your inbox and follow the link</p>
</div>

<div
  *ngIf="isEnterNewPassword"
  class="form-col"
>
  <lefty-form-input
    label="New password"
    i18n-label
    type="password"
    [formControl]="passwordFormControl"
    autocomplete="new-password"
  ></lefty-form-input>

  <lefty-form-input
    label="Confirm password"
    i18n-label
    type="password"
    autocomplete="new-password"
    [formControl]="confirmPasswordFormControl"
  ></lefty-form-input>
  <p
    *ngIf="passwordsDontMatch()"
    i18n
    class="error-message"
  >
    Confimation doesn't match the password
  </p>
  <button
    i18n
    leftyButton="primary"
    size="large"
    [disabled]="
      loading ||
      confirmPasswordFormControl.invalid ||
      passwordFormControl.invalid
    "
    [loading]="loading"
    (click)="passwordChangeSubmited()"
  >
    Save new password
  </button>
</div>

<div
  *ngIf="isPasswordChanged"
  class="form-col"
>
  <p class="desc">You password has been reset !</p>
  <button
    i18n
    leftyButton="primary"
    size="large"
    [disabled]="loading"
    [loading]="loading"
    (click)="gotoHomePage$.next()"
  >
    Continue to lefty
  </button>
</div>
