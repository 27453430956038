import { Injectable } from '@angular/core';
import { injectLibrarianEntityLabelsClient } from '@frontend2/api';
import { BaseEntityType } from '@frontend2/proto/librarian/proto/common_pb';
import { EntityLabel } from '@frontend2/proto/librarian/proto/entity_labels_pb';
import { injectLeftyEventsBus } from '../events/events.service';

@Injectable({ providedIn: 'root' })
export class CreateLabelService {
  readonly entityLabelsClient = injectLibrarianEntityLabelsClient();
  readonly leftyEvents = injectLeftyEventsBus();

  async create(entityType: BaseEntityType, name: string): Promise<EntityLabel> {
    const newLabel = new EntityLabel({
      entityType,
      name,
    });

    const id = await this.entityLabelsClient.addEntityLabelAPI(newLabel);

    newLabel.id = id.value;

    this.leftyEvents.emit('create_label', newLabel);

    return newLabel;
  }
}
