import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { Placement } from '@popperjs/core';
import { Subject } from 'rxjs';
import { LeftyListComponent } from '../lefty-list/lefty-list.component';
import { LeftyComponent } from '../utils';
import { LeftyPopupComponent } from '../lefty-popup/lefty-popup.component';
import { LeftyIconComponent } from '../icon/icon.component';
import { NgClass, NgIf } from '@angular/common';

@Component({
  selector: 'lefty-dropdown-button',
  templateUrl: 'lefty-dropdown-button.component.html',
  styleUrls: ['lefty-dropdown-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    LeftyIconComponent,
    LeftyPopupComponent,
    LeftyListComponent,
  ],
})
export class LeftyDropdownButtonComponent extends LeftyComponent {
  constructor() {
    super();

    this.disposer.addFunction(() => this.dropdownVisibleChange$.complete);
  }

  @Output()
  readonly dropdownVisibleChange$ = new Subject<boolean>();

  @Input()
  noArrowDropdown = false;

  @Input()
  matchMinSourceWidth = true;

  @Input()
  popupClass = '';

  @Input()
  popupPlacement: Placement = 'bottom-start';

  @Input()
  buttonClass = '';

  @Input()
  disabled = false;

  private _listEl?: LeftyListComponent | undefined;

  get listEl(): LeftyListComponent | undefined {
    return this._listEl;
  }

  @ViewChild(LeftyListComponent)
  set listEl(value: LeftyListComponent | undefined) {
    this._listEl = value;

    this.setState(() => {
      this.isEmpty = this.listEl?.hasChildren !== true;
    });
  }

  @HostBinding('class.display-none')
  isEmpty = false;

  private _visible = false;

  get visible(): boolean {
    return this._visible;
  }

  set visible(val: boolean) {
    if (val === this._visible) {
      return;
    }

    this.setState(() => (this._visible = val));
    this.dropdownVisibleChange$.next(val);
  }

  close(): void {
    this.visible = false;
  }

  open(): void {
    this.visible = true;
  }

  toggle(): void {
    this.visible = !this.visible;
  }
}
