import { Injectable } from '@angular/core';
import { injectLibrarianCampaignsClient } from '@frontend2/api';
import { Network } from '@frontend2/proto/common/proto/common_pb';
import { GetVisualCreatorsRequest } from '@frontend2/proto/librarian/proto/campaigns_pb';
import { CreatorTiniestCard } from '@frontend2/proto/librarian/proto/creators_pb';
import { CreatorAutocompleteResponse } from '@frontend2/proto/librarian/proto/users_pb';

@Injectable({
  providedIn: 'root',
})
export class CampaignAuthorsService {
  readonly librarianCampaigns = injectLibrarianCampaignsClient();

  private _cachedResponse = new CreatorAutocompleteResponse();
  private _cachedRequest = new GetVisualCreatorsRequest();

  async getAuthors(
    req: GetVisualCreatorsRequest,
  ): Promise<CreatorAutocompleteResponse> {
    if (req.equals(this._cachedRequest)) {
      return this._cachedResponse;
    } else {
      this._cachedRequest = req;
    }
    this._cachedResponse = await this.librarianCampaigns.getVisualCreators(req);
    return this._cachedResponse;
  }

  async fetchAllCreatorsForNetwork(
    network: Network,
    campaignIds: bigint[],
  ): Promise<CreatorTiniestCard[]> {
    if (network === Network.NETWORK_UNKNOWN) {
      return [];
    }
    const req = new GetVisualCreatorsRequest({
      network: network,
      campaignIds: campaignIds,
    });
    return (await this.getAuthors(req)).creatorMatches;
  }

  async fetchAllCreatorsForCampaign(
    campaignId: bigint,
  ): Promise<CreatorTiniestCard[]> {
    const req = new GetVisualCreatorsRequest({
      campaignIds: [campaignId],
    });
    return (await this.getAuthors(req)).creatorMatches;
  }
}
