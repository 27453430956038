import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Optional,
  Self,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { isNil } from '@frontend2/core';
import { Placement } from '@popperjs/core';
import { LeftyFormValueBase } from '../form';
import {
  ButtonType,
  LeftyButtonDirective,
} from '../lefty-button-directive/lefty-button.directive';
import {
  NumberRange,
  createNumberRange,
  isNumberRange,
} from '../lefty-range-input/lefty-range-input.models';
import { LeftyRangeInputComponent } from '../lefty-range-input/lefty-range-input.component';
import { LeftyPopupComponent } from '../lefty-popup/lefty-popup.component';
import { LeftyIconComponent } from '../icon/icon.component';
import { LeftyFormComponent } from '../lefty-form/lefty-form.component';

@Component({
  selector: 'lefty-range-input-dropdown',
  templateUrl: './lefty-range-input-dropdown.component.html',
  styleUrls: ['./lefty-range-input-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    LeftyFormComponent,
    LeftyButtonDirective,
    LeftyIconComponent,
    LeftyPopupComponent,
    LeftyRangeInputComponent,
  ],
})
export class LeftyRangeInputDropdownComponent extends LeftyFormValueBase<NumberRange> {
  constructor(@Self() @Optional() ngControl?: NgControl) {
    super(createNumberRange(), ngControl);
  }

  @Input() buttonType: ButtonType = 'secondary';

  @Input() placeholder = $localize`Range`;

  @Input()
  splitMessage = $localize`to`;

  @Input()
  allowSingleValue = false;

  @Input()
  popupPlacement: Placement = 'bottom-start';

  get buttonText(): string {
    if (this.showPlaceholder) {
      return this.placeholder;
    } else {
      return this.numberRangeRenderer(this.value);
    }
  }

  numberRangeRenderer(numberRange: NumberRange): string {
    if (this.isEmpty) {
      return '';
    }

    const start = numberRange.start ? numberRange.start.toString() : '';
    const end = numberRange.end ? numberRange.end.toString() : '';

    if (isNil(numberRange.start) && this.allowSingleValue) {
      return $localize`Less than ${end}`;
    }
    if (isNil(numberRange.end) && this.allowSingleValue) {
      return $localize`More than ${start}`;
    }
    return `${start} ${this.splitMessage} ${end}`;
  }

  get isEmpty(): boolean {
    return isNil(this.value.start) && isNil(this.value.end);
  }

  get hasSingleValue(): boolean {
    return isNil(this.value.start) || isNil(this.value.end);
  }

  get showPlaceholder(): boolean {
    return this.isEmpty || (!this.allowSingleValue && this.hasSingleValue);
  }

  override writeValue(obj: unknown): void {
    if (isNumberRange(obj)) {
      this.value = obj;
    }
  }

  clearAll(): void {
    this.handleValueChange(createNumberRange());
  }
}
