import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Optional,
  Output,
  Self,
  Type,
  computed,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgControl } from '@angular/forms';
import { escapeRegExp, isNotEmptyString, isNotNil } from '@frontend2/core';

import { EntityLabel } from '@frontend2/proto/librarian/proto/entity_labels_pb';
import { filter } from 'rxjs';
import { CacheBloc } from '../bloc';
import { RendersValue } from '../dynamic-component.component';
import {
  LeftyFormAutocompleteWrapperComponent,
  LeftyFormAutocompleteComponent,
} from '../lefty-form-autocomplete/lefty-form-autocomplete.component';
import { createOutput } from '../utils';
import {
  LeftyLabelCampaignItemCompactComponent,
  LeftyLabelCampaignItemComponent,
  LeftyLabelCampaignItemHiddenCountComponent,
  LeftyLabelInfluencerItemCompactComponent,
  LeftyLabelInfluencerItemComponent,
  LeftyLabelInfluencerItemHiddenCountComponent,
} from './label-item.component';
import {
  injectCampaignLabelsCache,
  injectInfluencerLabelsCache,
} from './labels.cache';
import {
  createGhostLabel,
  createTemporaryLabel,
  isTempLabel,
} from './labels.helpers';

@Component({
  template: '',
})
export abstract class LeftyLabelsAutocompleteComponent extends LeftyFormAutocompleteWrapperComponent<EntityLabel> {
  constructor(
    readonly labelsCache: CacheBloc<Map<bigint, EntityLabel>>,
    @Optional() @Self() ngControl?: NgControl,
  ) {
    super(createGhostLabel(), ngControl);

    this.popupMatchInputWidth = true;

    this.popupVisibleChange
      .pipe(
        filter((visible) => visible === true),
        takeUntilDestroyed(),
      )
      .subscribe(() => this.labelsCache.load());
  }

  readonly isLoading = this.labelsCache.isLoading;

  override options = computed(() => {
    let labels = Array.from(this.labelsCache.cachedData().values()).filter(
      (label) => label.archived === false,
    );

    if (this.allowUnusedLabels === false) {
      labels = labels.filter((l) => (l.count ?? 0) > 0);
    }

    labels = this._sortLabels(labels);

    return labels;
  });

  private _sortLabels(labels: EntityLabel[]): EntityLabel[] {
    labels = [...labels];
    if (this.hideCount) {
      labels.sort((a, b) =>
        this.itemRenderer(a)
          .toLowerCase()
          .localeCompare(this.itemRenderer(b).toLowerCase()),
      );
    } else {
      labels.sort((a, b) => (b.count ?? 0) - (a.count ?? 0));
    }
    return labels;
  }

  override itemRenderer = (label: EntityLabel): string => {
    return label.name;
  };

  readonly filterLabels = (
    options: EntityLabel[],
    query: string,
    limit?: number,
  ): EntityLabel[] => {
    query = query.trim();

    if (isNotEmptyString(query)) {
      const regexp = new RegExp(escapeRegExp(query), 'i');
      options = options.filter((label) => regexp.test(label.name));
    }

    options = options.slice(0, limit ?? this.limit);

    if (this.canCreateLabel && isNotEmptyString(query)) {
      // if nothing match exactly the query
      // we add a 'fake' label so user can click on it to trigger `createLabel$` event
      if (options.some((l) => l.name === query) === false) {
        options = [createTemporaryLabel(query), ...options];
      }
    }

    return options;
  };

  @Input()
  canCreateLabel = false;

  @Output()
  readonly createLabel$ = createOutput<string>();

  @Input()
  allowUnusedLabels = false;

  @Input()
  hideCount = false;

  @Input()
  compact = false;

  trackBy(index: number, label: EntityLabel): bigint {
    return label.id ?? BigInt(0);
  }

  override select(val: EntityLabel | undefined): void {
    super.select(val);

    if (isNotNil(val) && isTempLabel(val)) {
      this.createLabel$.next(val.name);
    }
  }

  override writeValue(obj: unknown): void {
    if (obj instanceof EntityLabel) {
      this.value = obj;
    } else {
      this.value = createGhostLabel();
    }
  }
}

@Component({
  selector: 'lefty-influencer-labels-autocomplete',
  template: `<lefty-form-autocomplete
    [label]="label"
    [placeholder]="placeholder"
    [loading]="isLoading()"
    [selection]="value"
    (selectionChange)="select($event)"
    (popupVisibleChange)="popupVisibleChange.next($event)"
    [popupPlacement]="popupPlacement"
    [componentFactory]="componentFactory"
    [itemRenderer]="itemRenderer"
    [options]="options()"
    [trackByFn]="trackBy"
    [disabled]="disabled"
    (inputTextChange)="inputTextChange.next($event)"
    [limit]="limit"
    [popupMatchInputWidth]="popupMatchInputWidth"
    [shouldClearInputOnSelection]="shouldClearInputOnSelection"
    [keepPopupVisible]="keepPopupVisible"
    [popupClassName]="popupClassName"
    [inputClassName]="inputClassName"
    [emptyPlaceholder]="emptyPlaceholder"
    [prefix]="prefix"
    [suffix]="suffix"
    [trailingGlyph]="trailingGlyph"
    [leadingGlyph]="leadingGlyph"
    [openIfNoInput]="openIfNoInput"
    [filteringFn]="filterLabels"
  ></lefty-form-autocomplete>`,
  styleUrls: ['labels-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LeftyFormAutocompleteComponent],
})
export class LeftyInfluencerLabelsAutocompleteComponent extends LeftyLabelsAutocompleteComponent {
  constructor(@Optional() @Self() ngControl?: NgControl) {
    super(injectInfluencerLabelsCache(), ngControl);
  }

  override componentFactory = (): Type<RendersValue<EntityLabel>> => {
    if (this.hideCount) {
      return LeftyLabelInfluencerItemHiddenCountComponent;
    }
    if (this.compact) {
      return LeftyLabelInfluencerItemCompactComponent;
    }
    return LeftyLabelInfluencerItemComponent;
  };
}

@Component({
  selector: 'lefty-campaign-labels-autocomplete',
  template: `<lefty-form-autocomplete
    [optional]="optional"
    [large]="large"
    [label]="label"
    [placeholder]="placeholder"
    [loading]="isLoading()"
    [selection]="value"
    (selectionChange)="select($event)"
    (popupVisibleChange)="popupVisibleChange.next($event)"
    [popupPlacement]="popupPlacement"
    [componentFactory]="componentFactory"
    [itemRenderer]="itemRenderer"
    [options]="options()"
    [trackByFn]="trackBy"
    [disabled]="disabled"
    (inputTextChange)="inputTextChange.next($event)"
    [limit]="limit"
    [popupMatchInputWidth]="popupMatchInputWidth"
    [shouldClearInputOnSelection]="shouldClearInputOnSelection"
    [keepPopupVisible]="keepPopupVisible"
    [popupClassName]="popupClassName"
    [inputClassName]="inputClassName"
    [emptyPlaceholder]="emptyPlaceholder"
    [prefix]="prefix"
    [suffix]="suffix"
    [trailingGlyph]="trailingGlyph"
    [leadingGlyph]="leadingGlyph"
    [openIfNoInput]="openIfNoInput"
    [filteringFn]="filterLabels"
  ></lefty-form-autocomplete>`,
  styleUrls: ['labels-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LeftyFormAutocompleteComponent],
})
export class LeftyCampaignLabelsAutocompleteComponent extends LeftyLabelsAutocompleteComponent {
  constructor(@Optional() @Self() ngControl?: NgControl) {
    super(injectCampaignLabelsCache(), ngControl);
  }

  override componentFactory = (): Type<RendersValue<EntityLabel>> => {
    if (this.hideCount) {
      return LeftyLabelCampaignItemHiddenCountComponent;
    }
    if (this.compact) {
      return LeftyLabelCampaignItemCompactComponent;
    }
    return LeftyLabelCampaignItemComponent;
  };
}
