import { computed, Injectable } from '@angular/core';
import { Network } from '@frontend2/proto/common/proto/common_pb';
import { Campaign } from '@frontend2/proto/librarian/proto/campaigns_pb';
import { EntityField } from '@frontend2/proto/librarian/proto/common_pb';
import { CreatorTiniestCard } from '@frontend2/proto/librarian/proto/creators_pb';
import { EntityLabel } from '@frontend2/proto/librarian/proto/entity_labels_pb';
import { CreatorPoolResponse } from '@frontend2/proto/librarian/proto/pool_pb';
import { Bloc } from '../bloc';
import {
  DynamicFilterCategory,
  NativeFilterOption,
} from './dynamic-filters.helpers';
import {
  createListOptionValues,
  ListOptionValues,
} from './filters/list/list.helpers';

export interface DynamicFilterConfig {
  readonly nativeFilterOptions: NativeFilterOption[];

  readonly listOptionValues: ListOptionValues;
  readonly campaignId: bigint;
  readonly directoryInfluencers: CreatorPoolResponse[];
  readonly activeNetwork: Network;
  readonly categories: DynamicFilterCategory[];
}

export function createDynamicFilterConfig(
  partial?: Partial<DynamicFilterConfig>,
): DynamicFilterConfig {
  return {
    categories: partial?.categories ?? [],
    nativeFilterOptions: partial?.nativeFilterOptions ?? [],
    listOptionValues: partial?.listOptionValues ?? createListOptionValues(),
    campaignId: partial?.campaignId ?? BigInt(0),
    directoryInfluencers: partial?.directoryInfluencers ?? [],
    activeNetwork: partial?.activeNetwork ?? Network.NETWORK_UNKNOWN,
  };
}

@Injectable()
// helper to propagate `LeftyDynamicFiltersComponent` configuration to all sub components
// instead of passinging down a lot of @Input
export class DynamicFiltersConfigBloc extends Bloc<DynamicFilterConfig> {
  constructor() {
    super(createDynamicFilterConfig());
  }

  readonly categories = computed(() => {
    return this.state().categories;
  });

  readonly nativeFilterOptions = computed(
    () => this.state().nativeFilterOptions,
  );

  setConfig(cfg: Partial<DynamicFilterConfig>): void {
    this.setState(createDynamicFilterConfig(cfg));
  }

  setListOptionValues(listOptionValues: ListOptionValues): void {
    this.setConfig({ ...this.state(), listOptionValues: listOptionValues });
  }

  setCampaigns(campaigns: Map<bigint, Campaign>): void {
    this.setListOptionValues(
      createListOptionValues({
        ...this.state().listOptionValues,
        availableCampaigns: campaigns,
      }),
    );
  }

  setCampaignLabels(labels: Map<bigint, EntityLabel>): void {
    this.setListOptionValues(
      createListOptionValues({
        ...this.state().listOptionValues,
        availableCampaignLabels: labels,
      }),
    );
  }

  setInfluencerLabels(labels: Map<bigint, EntityLabel>): void {
    this.setListOptionValues(
      createListOptionValues({
        ...this.state().listOptionValues,
        availableInfluencerLabels: labels,
      }),
    );
  }

  setPostLabels(labels: Map<bigint, EntityLabel>): void {
    this.setListOptionValues(
      createListOptionValues({
        ...this.state().listOptionValues,
        availablePostLabels: labels,
      }),
    );
  }

  setInfluencers(influencers: Map<string, CreatorTiniestCard>): void {
    this.setListOptionValues(
      createListOptionValues({
        ...this.state().listOptionValues,
        availableInfluencers: influencers,
      }),
    );
  }

  setCreatorFields(fields: EntityField[]): void {
    this.setListOptionValues(
      createListOptionValues({
        ...this.state().listOptionValues,
        availableCreatorFields: fields,
      }),
    );
  }

  setCampaignFields(fields: EntityField[]): void {
    this.setListOptionValues(
      createListOptionValues({
        ...this.state().listOptionValues,
        availableCampaignFields: fields,
      }),
    );
  }

  setNetworks(networks: Network[]): void {
    this.setListOptionValues(
      createListOptionValues({
        ...this.state().listOptionValues,
        availableNetworks: networks,
      }),
    );
  }

  setDirectoryInfluencers(influencers: CreatorPoolResponse[]): void {
    this.setConfig({
      ...this.state(),
      directoryInfluencers: influencers,
    });
  }
}
