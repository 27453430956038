import { Component, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import {
  CampaignFieldType,
  CreatorFieldType,
} from '@frontend2/proto/librarian/proto/common_pb';
import {
  setCustomFieldDecimalNumberValue,
  setCustomFieldNumberValue,
  setCustomFieldTextValue,
} from '../../custom-fields.helpers';
import { CustomFieldInputBaseComponent } from '../input.base';

@Component({
  template: '',
})
export abstract class CustomFieldTextInputBaseComponent extends CustomFieldInputBaseComponent {
  constructor(@Self() @Optional() ngControl?: NgControl) {
    super(ngControl);
  }

  set textValue(val: string) {
    switch (this.fieldType) {
      case CreatorFieldType.TEXT:
      case CampaignFieldType.TEXT:
        this.handleValueChange(setCustomFieldTextValue(this.value, val));
        break;
      case CreatorFieldType.NUMBER:
        this.handleValueChange(
          setCustomFieldNumberValue(this.value, this._parseNumber(val)),
        );
        break;
      case CreatorFieldType.DECIMAL_NUMBER:
        this.handleValueChange(
          setCustomFieldDecimalNumberValue(
            this.value,
            this._parseDecimalNumber(val),
          ),
        );
        break;
      case CreatorFieldType.UNKNOWN:
      case CreatorFieldType.BOOLEAN:
      case CreatorFieldType.DATE:
      case CreatorFieldType.LOCATION:
      case CreatorFieldType.ADDRESS:
      case CreatorFieldType.LIST:
      case CreatorFieldType.STRING_LIST:
        break;
    }
  }

  private _parseNumber(text: string): bigint {
    return BigInt(text);
  }

  private _parseDecimalNumber(text: string): number {
    return Number(text);
  }
}
