import {
  ChangeDetectionStrategy,
  Component,
  Input,
  computed,
  signal,
} from '@angular/core';
import { plural } from '@frontend2/core';
import { EntityLabel } from '@frontend2/proto/librarian/proto/entity_labels_pb';
import { RendersValue } from '../dynamic-component.component';
import {
  LABEL_MAX_SIZE,
  createGhostLabel,
  isTempLabel,
  isValidLabel,
} from './labels.helpers';
import { LeftyIconComponent } from '../icon/icon.component';
import { NgIf } from '@angular/common';

@Component({ template: '' })
abstract class _LabelItemBaseComponent implements RendersValue<EntityLabel> {
  abstract get isCompact(): boolean;
  abstract get hideCount(): boolean;

  private readonly label = signal<EntityLabel>(createGhostLabel());

  readonly count = computed(() => this.label().count ?? 0);
  readonly name = computed(() => this.label().name);
  readonly countLabel = computed(() => this.getCountLabel(this.count()));

  readonly isCreateLabel = computed(() => isTempLabel(this.label()));
  readonly isValid = computed(() => isValidLabel(this.label()));

  // When creating a label
  // we use a fake label item with a fake `FavoritesLabel`
  // so we can display `Create label: xxx` in the dropdown
  //
  // However if the label the use entered is not valid, we must display an error
  readonly hasError = computed(
    () => this.isCreateLabel() && this.isValid() === false,
  );

  readonly maxSize = LABEL_MAX_SIZE;

  @Input()
  set value(val: EntityLabel | undefined) {
    this.label.set(val ?? createGhostLabel());
  }

  setValue(newValue?: EntityLabel | undefined): void {
    this.value = newValue;
  }

  abstract getCountLabel(count: number): string;
}

@Component({ selector: 'lefty-label-influencer-base-item', template: '' })
abstract class _LabelInfluencerBaseComponent extends _LabelItemBaseComponent {
  override getCountLabel(count: number): string {
    return plural(count, {
      one: $localize`Influencer`,
      other: $localize`Influencers`,
    });
  }
}

@Component({
  selector: 'lefty-label-influencer-item',
  styleUrls: ['label-item.component.scss'],
  templateUrl: 'label-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, LeftyIconComponent],
})
export class LeftyLabelInfluencerItemComponent extends _LabelInfluencerBaseComponent {
  override get isCompact(): boolean {
    return false;
  }

  override get hideCount(): boolean {
    return false;
  }
}

@Component({
  selector: 'lefty-label-influencer-item-compact',
  styleUrls: ['label-item.component.scss'],
  templateUrl: 'label-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, LeftyIconComponent],
})
export class LeftyLabelInfluencerItemCompactComponent extends _LabelInfluencerBaseComponent {
  override get isCompact(): boolean {
    return true;
  }

  override get hideCount(): boolean {
    return false;
  }
}

@Component({
  selector: 'lefty-label-influencer-item-hidden-count',
  styleUrls: ['label-item.component.scss'],
  templateUrl: 'label-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, LeftyIconComponent],
})
export class LeftyLabelInfluencerItemHiddenCountComponent extends _LabelInfluencerBaseComponent {
  override get isCompact(): boolean {
    return true;
  }

  override get hideCount(): boolean {
    return true;
  }
}

@Component({ selector: 'lefty-label-campaign-base-item', template: '' })
abstract class _LabelCampaignBaseComponent extends _LabelItemBaseComponent {
  override getCountLabel(count: number): string {
    return plural(count, {
      one: $localize`Campaign`,
      other: $localize`Campaigns`,
    });
  }
}

@Component({
  selector: 'lefty-label-campaign-item',
  styleUrls: ['label-item.component.scss'],
  templateUrl: 'label-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, LeftyIconComponent],
})
export class LeftyLabelCampaignItemComponent extends _LabelCampaignBaseComponent {
  override get isCompact(): boolean {
    return false;
  }

  override get hideCount(): boolean {
    return false;
  }
}

@Component({
  selector: 'lefty-label-campaign-item-compact',
  styleUrls: ['label-item.component.scss'],
  templateUrl: 'label-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, LeftyIconComponent],
})
export class LeftyLabelCampaignItemCompactComponent extends _LabelCampaignBaseComponent {
  override get isCompact(): boolean {
    return true;
  }

  override get hideCount(): boolean {
    return false;
  }
}

@Component({
  selector: 'lefty-label-campaign-item-hidden-count',
  styleUrls: ['label-item.component.scss'],
  templateUrl: 'label-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, LeftyIconComponent],
})
export class LeftyLabelCampaignItemHiddenCountComponent extends _LabelCampaignBaseComponent {
  override get isCompact(): boolean {
    return true;
  }

  override get hideCount(): boolean {
    return true;
  }
}
