import { LeftyFormat } from '@frontend2/core';
import {
  CommonFilter,
  DecimalNumberFilter,
  NumberFilter,
} from '@frontend2/proto/librarian/proto/common_pb';
import { NumberRange } from '../../../lefty-range-input/lefty-range-input.models';
import {
  isNumberDynamicFilter,
  isValidRangeValue,
} from '../../dynamic-filters.helpers';

export type NumberOperator =
  | 'isEqual'
  | 'isNot'
  | 'isLower'
  | 'isGreater'
  | 'isBetween'
  | 'isEmpty';

export interface FilterNumberValue {
  readonly operator: NumberOperator;
  readonly value: number;
  readonly range: NumberRange;
}

export function createFilterNumberValue(
  partial?: Partial<FilterNumberValue>,
): FilterNumberValue {
  return {
    operator: partial?.operator ?? 'isEqual',
    value: partial?.value ?? 0,
    range: partial?.range ?? { type: 'NumberRange' },
  };
}

export function getFilterNumberValueFromCommonFilter(
  filter: CommonFilter,
): FilterNumberValue {
  const filterValue = filter.filter;
  if (filterValue.case === 'isEmpty') {
    return createFilterNumberValue({ operator: 'isEmpty' });
  }
  if (
    (isNumberDynamicFilter(filter) || isNumberDynamicFilter(filter)) &&
    (filterValue.case === 'numberFilter' ||
      filterValue.case === 'decimalFilter')
  ) {
    const numberfilter = filterValue.value.value;
    switch (numberfilter.case) {
      case 'is':
        return createFilterNumberValue({
          operator: 'isEqual',
          value: Number(numberfilter.value),
        });
      case 'isNot':
        return createFilterNumberValue({
          operator: 'isNot',
          value: Number(numberfilter.value),
        });
      case 'range': {
        if (
          isValidRangeValue(numberfilter.value.max) &&
          isValidRangeValue(numberfilter.value.min)
        ) {
          return createFilterNumberValue({
            operator: 'isBetween',
            range: {
              type: 'NumberRange',
              start: Number(numberfilter.value.min),
              end: Number(numberfilter.value.max),
            },
          });
        }
        if (isValidRangeValue(numberfilter.value.max)) {
          return createFilterNumberValue({
            operator: 'isLower',
            value: Number(numberfilter.value.max),
          });
        }
        if (isValidRangeValue(numberfilter.value.min)) {
          return createFilterNumberValue({
            operator: 'isGreater',
            value: Number(numberfilter.value.min),
          });
        }
        break;
      }
      default:
        return createFilterNumberValue();
    }
  }
  return createFilterNumberValue();
}

export function formattedNumberValue(filter: NumberFilter): string {
  const filterValue = filter.value;
  switch (filterValue.case) {
    case 'is':
    case 'isNot':
      return formatNumbers(filterValue.value, false);
    case 'range':
      return formatRange(filterValue.value.min, filterValue.value.max, false);

    case 'followersRange':
      return formatRange(
        filterValue.value.followersRange?.min ?? 0,
        filterValue.value.followersRange?.max ?? 0,
        false,
      );
    default:
      return '';
  }
}

export function formattedDecimalNumberValue(
  filter: DecimalNumberFilter,
): string {
  const filterValue = filter.value;
  switch (filterValue.case) {
    case 'is':
    case 'isNot':
      return formatNumbers(filterValue.value, false);
    case 'range':
      return formatRange(filterValue.value.min, filterValue.value.max);

    case 'engagementRange':
      return formatRange(
        filterValue.value.engagementRange?.min ?? 0,
        filterValue.value.engagementRange?.max ?? 0,
        true,
      );
    default:
      return '';
  }
}

function formatRange(
  min: number | bigint,
  max: number | bigint,
  isPercent = false,
): string {
  if ((min === BigInt(0) && max === BigInt(0)) || (min === 0 && max === 0)) {
    return '';
  }
  if (isValidRangeValue(min) && isValidRangeValue(max)) {
    return (
      formatNumbers(min, isPercent) +
      $localize` and ` +
      formatNumbers(max, isPercent)
    );
  }
  if (isValidRangeValue(min)) {
    return formatNumbers(min, isPercent);
  }
  if (isValidRangeValue(max)) {
    return formatNumbers(max, isPercent);
  }
  return '';
}

function formatNumbers(count: number | bigint, isPercent = false): string {
  return isPercent
    ? LeftyFormat.percent(Number(count) / 100)
    : LeftyFormat.format(count, { showZero: true });
}

export function readableNumberOperator(op: NumberOperator): string {
  switch (op) {
    case 'isEqual':
      return $localize`is`;
    case 'isNot':
      return $localize`is not`;
    case 'isLower':
      return $localize`is lower than`;
    case 'isGreater':
      return $localize`is greater than`;
    case 'isBetween':
      return $localize`is between`;
    case 'isEmpty':
      return $localize`is empty`;
  }
}
