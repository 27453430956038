<div class="form-input">
  <div
    *ngIf="canShowLabel"
    class="form-label"
  >
    {{ label }}
  </div>
  <button
    [leftyButton]="buttonType"
    #popupTrigger
    focusableElement
    class="selector-btn"
  >
    <div class="button-content">
      @if (hasSelection() === false) {
        <span class="placeholder empty">
          {{ placeholder() }}
        </span>
      } @else {
        <span class="placeholder">
          {{ buttonText() }}
          @if (selectionSize() !== 0) {
            <span class="count">({{ selectionSize() }})</span>
          }
        </span>
      }
    </div>
    <lefty-icon icon="arrow_drop_down"></lefty-icon>
  </button>

  <lefty-popup
    [placement]="popupPlacement"
    [popupTrigger]="popupTrigger"
    [matchMinSourceWidth]="true"
    [(visible)]="popupVisible"
  >
    <div class="popup-content">
      <div class="header">
        <span
          class="selector"
          (click)="clearAll()"
          [class.active]="hasSelection()"
          i18n
        >
          Clear all
        </span>
      </div>
      <div class="input-container">
        <lefty-form-input
          [(value)]="inputValue"
          [placeholder]="inputPlaceholder"
          (keypress$)="handleKeypress($event)"
          (keydown$)="handleKeyDown($event)"
        >
        </lefty-form-input>
      </div>
      @if (hasSelection()) {
        <div class="chips-container">
          @for (chip of chips(); track $index) {
            <lefty-chip
              [dark]="true"
              [chip]="chip"
              (remove$)="unselectKeyword($event.value)"
            >
            </lefty-chip>
          }
        </div>
      }
      @if (hasInputValue()) {
        <div class="options">
          <lefty-list>
            @for (opt of options(); track $index) {
              <lefty-select-dropdown-item
                [itemActive]="isActive(opt)"
                (click)="selectKeyword(opt)"
                [closeOnActivate]="false"
              >
                <span class="type">{{ getKeywordLabel(opt.keywordType) }}:</span
                >&nbsp;{{ opt.value }}
              </lefty-select-dropdown-item>
            }
          </lefty-list>
        </div>
      }
    </div>

    <div class="operator-footer">
      <lefty-logic-operator-selector
        [value]="operator()"
        (valueChange)="setOperator($event)"
      ></lefty-logic-operator-selector>
    </div>
  </lefty-popup>
</div>
