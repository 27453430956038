import { LeftyColors } from '@frontend2/core';
import { DataItem } from 'billboard.js';
import { LeftyChartsOptions } from '../charts.models';

export interface BubbleValue<Metadata> {
  readonly name: string;
  readonly value: number;
  readonly x: number;
  readonly y: number;
  readonly colorPalette: string[];
  readonly metadata?: Metadata;
}

export function createBubbleValue<Metadata>(
  args?: Partial<BubbleValue<Metadata>>,
): BubbleValue<Metadata> {
  return {
    name: args?.name ?? '',
    value: args?.value ?? 0,
    x: args?.x ?? 0,
    y: args?.y ?? 0,
    colorPalette: args?.colorPalette ?? LeftyColors.teal,
    metadata: args?.metadata,
  };
}

export interface LeftyBubbleChartsOptions extends LeftyChartsOptions {
  readonly showTooltip?: boolean;
  readonly valueTooltipFormatter?: (
    value: number | null,
    categoryName?: string,
  ) => string;

  /// List of [BubbleValue.name] we want to highlight,
  /// color while be indigo and label always visible
  readonly highlights?: string[];

  readonly onBubbleclick?: (d: DataItem, element: SVGElement) => void;

  // If 100 is the highest value among data bound, the representation bubble of 100 will have radius of MAX_R.
  // And the lesser will have radius relatively from tha max value.
  //
  // default is 90
  readonly maxR?: number;

  // Set if min or max value will be 0 on bubble chart.
  //
  // default is true
  readonly zerobased?: boolean;
}
