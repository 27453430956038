<lefty-list>
  <lefty-list-item
    class="workspace"
    *ngFor="let space of filteredSpaces"
    (trigger$)="select$.next(space)"
  >
    <h4 class="name">{{ space.name }}</h4>
    <lefty-icon icon="arrow_drop_down"></lefty-icon>
  </lefty-list-item>

  <p
    *ngIf="filteredSpaces.length === 0"
    class="not-found"
  >
    {{ notFoundText }}
  </p>
</lefty-list>
