<lefty-card [withBorder]="true">
  <div
    class="main-section"
    [class.clickable]="!alwaysExtended"
    [class.compact-padding]="compactPaddingOnHeader"
    (click)="toggleExpand()"
  >
    <div class="left-col">
      <div class="title">
        @if (hasTitleIcon) {
          <div class="icon-container">
            <lefty-icon
              [icon]="titleIcon"
              size="x-large"
            ></lefty-icon>
          </div>
        }

        <h4 class="fill-space">{{ title }}</h4>
        <ng-content select="[header]"></ng-content>

        @if (alwaysExtended === false) {
          <div class="collapse-icon-container">
            <lefty-icon
              icon="arrow_drop_down"
              [class.active]="expanded()"
              class="collapse-icon"
              size="x-large"
            ></lefty-icon>
          </div>
        }
      </div>
      @if (hasDescription) {
        <p class="description">
          {{ description }}
        </p>
      }
    </div>
  </div>
  @if (mustShowContent) {
    <div
      class="secondary-container"
      [class.compact-padding]="compactPaddingOnContent"
      [class.no-padding]="noPaddingOnContent"
    >
      <ng-content></ng-content>
    </div>
  }
</lefty-card>
