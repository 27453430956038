<lefty-form
  [label]="label"
  [required]="required"
  [optional]="optional"
  [helpText]="helpText"
  [externalHelpLink]="externalHelpLink"
  [helpLink]="helpLink"
  [hintText]="hintText"
  [tooltip]="tooltip"
  [large]="large"
  [topHintText]="topHintText"
>
  <lefty-feedback
    *ngIf="!isLargeLogo"
    [type]="feedbackType()"
    class="size-error"
    >{{ feedbackText() }}</lefty-feedback
  >
  <input
    type="file"
    #input
    [attr.accept]="accept"
    style="display: none"
    (change)="onFilesAdded()"
  />

  <div
    class="media-container"
    [class.empty]="isEmptyState"
  >
    <div
      class="drop-container"
      *ngIf="isEmptyState"
      [class.loadingBlob]="loading"
      [class.ondragover]="draggingOver"
      (dragover)="onDragOver()"
      (dragleave)="onDragLeave()"
      (drop)="onDrop($event)"
    >
      <ng-container *ngIf="loading === false">
        <div
          *ngIf="isLargeLogo"
          class="empty-logo large-logo"
        >
          <div
            class="no-photo-text"
            i18n
          >
            No logo
          </div>
        </div>
        <div
          *ngIf="!isLargeLogo"
          class="empty-logo tiny-logo"
        >
          <div class="no-photo-text">{{ brandInitial }}</div>
        </div>
      </ng-container>

      <lefty-progress
        *ngIf="loading === true"
        [activeProgress]="progress"
      >
      </lefty-progress>
    </div>
    <div
      class="file-card"
      *ngIf="!isEmptyState"
    >
      <img
        [class.large-logo]="isLargeLogo"
        [class.tiny-logo]="!isLargeLogo"
        [attr.src]="dataSrc"
      />
    </div>
  </div>
  <div class="actions">
    <button
      (click)="addFile()"
      class="add-file-button"
      type="button"
      i18n
    >
      Upload new logo
    </button>

    <button
      type="button"
      *ngIf="!isEmptyState"
      class="clear primary plain"
      (click)="clear()"
      i18n
    >
      Remove
    </button>
  </div>
</lefty-form>
