import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  Input,
  input,
  model,
  signal,
} from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import {
  findParentFromSubcategory,
  getAllCategoryIds,
  isGhostSalesforceCategoryPb,
  isNotEmptyArray,
} from '@frontend2/core';
import { SalesforceCategoryPb } from '@frontend2/proto/librarian/proto/salesforce_pb';
import {
  ButtonSize,
  LeftyButtonDirective,
} from '../lefty-button-directive/lefty-button.directive';
import { LeftyCategoriesGridComponent } from './categories-grid/lefty-categories-grid.component';
import { LeftyCheckboxComponent } from '../checkbox/checkbox.component';
import { LeftyIconComponent } from '../icon/icon.component';
import { LeftyListItemComponent } from '../lefty-list/lefty-list-item.component';
import { LeftyListComponent } from '../lefty-list/lefty-list.component';
import { LeftyPopupComponent } from '../lefty-popup/lefty-popup.component';

@Component({
  selector: 'lefty-nested-category-selector',
  templateUrl: 'lefty-nested-category-selector.component.html',
  styleUrls: ['lefty-nested-category-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    LeftyButtonDirective,
    LeftyPopupComponent,
    LeftyListComponent,
    LeftyListItemComponent,
    LeftyIconComponent,
    LeftyCheckboxComponent,
  ],
})
export class LeftyNestedCategorySelectorComponent implements AfterViewInit {
  constructor() {
    toObservable(this.selectedCategoryIds)
      .pipe(takeUntilDestroyed())
      .subscribe(() => this.updateCategoryChips());
  }

  @Input()
  buttonSize: ButtonSize = 'medium';

  readonly categories = input<SalesforceCategoryPb[]>([]);

  readonly selectedCategoryIds = model<string[]>([]);

  readonly currentExpandedCategory = signal<SalesforceCategoryPb>(
    new SalesforceCategoryPb(),
  );

  readonly hasExpandedCategory = computed(
    () => isGhostSalesforceCategoryPb(this.currentExpandedCategory()) === false,
  );

  readonly visibleCategories = computed(() =>
    isGhostSalesforceCategoryPb(this.currentExpandedCategory())
      ? this.categories()
      : this.currentExpandedCategory().subcategories,
  );

  readonly categoriesGrid = input<LeftyCategoriesGridComponent>();

  hasSubcategory(category: SalesforceCategoryPb): boolean {
    return isNotEmptyArray(category.subcategories);
  }

  isSelected(category: SalesforceCategoryPb): boolean {
    return this.selectedCategoryIds().includes(category.id);
  }

  expandCategory(category: SalesforceCategoryPb): void {
    if (this.hasSubcategory(category)) {
      this.currentExpandedCategory.set(category);
    }
  }

  clickedOnCategory(category: SalesforceCategoryPb): void {
    if (this.hasSubcategory(category)) {
      this.expandCategory(category);
    } else {
      this.selectCategory(!this.isSelected(category), category);
    }
  }

  selectCategory(val: boolean, category: SalesforceCategoryPb): void {
    const categoryIds = [...getAllCategoryIds(category), category.id];
    if (val) {
      if (this.isSelected(category) === false) {
        const newIds = new Set([...this.selectedCategoryIds(), ...categoryIds]);
        this.selectedCategoryIds.set(Array.from(newIds));
      }
    } else {
      this.selectedCategoryIds.set(
        this.selectedCategoryIds().filter((c) => !categoryIds.includes(c)),
      );
    }
  }

  checkboxClicked(e: MouseEvent): void {
    e.stopPropagation();
  }

  goBack(): void {
    const parent = findParentFromSubcategory(
      this.currentExpandedCategory().id,
      this.categories(),
    );
    this.currentExpandedCategory.set(parent);
  }

  updateCategoryChips(): void {
    if (this.categoriesGrid) {
      this.categoriesGrid()?.updateCategories(
        this.selectedCategoryIds(),
        this.categories(),
      );
    }
  }

  ngAfterViewInit(): void {
    this.categoriesGrid()?.removeCategory.subscribe((c) => {
      this.selectCategory(false, c);
    });
  }
}
