import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  Optional,
  Output,
  Self,
  TrackByFunction,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { ConnectError } from '@connectrpc/connect';
import { injectLibrarianPoolClient } from '@frontend2/api';
import { isNotEmptyString, isNotNil, normalizeLeftyId } from '@frontend2/core';
import { BasicPagination } from '@frontend2/proto/common/proto/common_pb';
import { CreatorCardSnippet } from '@frontend2/proto/librarian/proto/creators_pb';
import {
  SimplePrefixSearchRequest,
  SimplePrefixSearchResponse_SimplePrefixSearchCreatorCard,
} from '@frontend2/proto/librarian/proto/pool_pb';
import { debounceTime } from 'rxjs';
import { ComponentFactory } from '../dynamic-component.component';
import { LeftyFormValueBase } from '../form';
import { createOutput } from '../utils';
import { InfluencerAutocompleteItemComponent } from './influencer-autocomplete-item/influencer-autocomplete-item.component';
import { LeftyFormAutocompleteComponent } from '../lefty-form-autocomplete/lefty-form-autocomplete.component';

@Component({
  selector: 'lefty-directory-influencer-autocomplete',
  template: `<lefty-form-autocomplete
    leadingGlyph="search"
    [options]="options"
    [value]="selection"
    [loading]="loading"
    [limit]="-1"
    name="inf-autocomplete"
    [itemRenderer]="itemRenderer"
    (inputTextChange)="handleSearchValueChange($event)"
    [popupMatchInputWidth]="true"
    [errorMessage]="errorMessage"
    [placeholder]="placeholder"
    [inputClassName]="inputClassName"
    (selectionChange)="influencerSelected($event)"
    [componentFactory]="componentFactory"
    (scrollEnd$)="appendPagnination()"
    [label]="label"
    [noFiltering]="true"
    [trackByFn]="trackByFn"
    [openIfNoInput]="openIfNoInput"
    [disabled]="disabled"
    [shouldClearInputOnSelection]="shouldClearInputOnSelection"
    [disableClearSelection]="disableClearSelection"
  >
  </lefty-form-autocomplete>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LeftyFormAutocompleteComponent],
})
export class DirectoryInfluencerAutocompleteComponent
  extends LeftyFormValueBase<CreatorCardSnippet | undefined>
  implements AfterViewInit
{
  constructor(@Self() @Optional() ngControl?: NgControl) {
    super(undefined, ngControl);
    this.searchValueChange.pipe(debounceTime(300)).subscribe(() => {
      this.searchInfluencer();
    });
  }

  private librarianPool = injectLibrarianPoolClient();

  @Input()
  searchValue = '';

  @Input()
  optionsSize = 10;

  @Input()
  allowNotFoundInfluencer = false;

  @Input()
  inputClassName = '';

  @Input()
  placeholder = $localize`Select influencer`;

  @Input()
  set selection(val: CreatorCardSnippet | undefined) {
    this.value = val;
  }

  get selection(): CreatorCardSnippet | undefined {
    return this.value;
  }

  @Input()
  loading = false;

  @Input()
  openIfNoInput = false;

  @Input()
  hideInfluencersWithIds: string[] = [];

  @Input()
  shouldClearInputOnSelection = false;

  @Input()
  disableClearSelection = false;

  @Output()
  readonly searchValueChange = createOutput<string>();

  handleSearchValueChange(newValue: string): void {
    this.errorMessage = '';
    if (this.searchValue === newValue) {
      return;
    }

    this.searchValue = newValue;
    this.searchValueChange.next(newValue);
  }

  options: CreatorCardSnippet[] = [];

  paginationFrom = 0;
  totalHits = 0;

  trackByFn: TrackByFunction<CreatorCardSnippet> = (
    _index: number,
    item: CreatorCardSnippet,
  ) => {
    item.userName;
  };

  influencerSelected(val: CreatorCardSnippet | undefined): void {
    this.handleValueChange(val);
  }

  async searchInfluencer(): Promise<void> {
    this.paginationFrom = 0;
    const req = this._buildSearchReq(this.paginationFrom);
    this.setState(() => (this.loading = true));
    const allOptions = await this._getInfluencers(req);
    this.options = this.filterInfluencersWithIds(allOptions);
    this.setState(() => (this.loading = false));
  }

  private _buildSearchReq(paginationFrom: number): SimplePrefixSearchRequest {
    return new SimplePrefixSearchRequest({
      prefix: this.searchValue,
      pagination: new BasicPagination({
        from: paginationFrom,
        size: this.optionsSize,
      }),
    });
  }

  filterInfluencersWithIds(
    options: CreatorCardSnippet[],
  ): CreatorCardSnippet[] {
    return options.filter(
      (f) =>
        !this.hideInfluencersWithIds.some(
          (i) => normalizeLeftyId(i) === normalizeLeftyId(f.userId),
        ),
    );
  }

  private async _getInfluencers(
    req: SimplePrefixSearchRequest,
  ): Promise<CreatorCardSnippet[]> {
    if (isNotEmptyString(this.searchValue) || this.openIfNoInput) {
      try {
        const response = await this.librarianPool.simplePrefixSearchAPI(req);
        this.totalHits = Number(response.totalHits);
        this.paginationFrom = this.paginationFrom + this.optionsSize;
        return this._processOptions(response.responses);
      } catch (e: unknown) {
        if (e instanceof ConnectError) {
          this.errorMessage = e.message;
        }
      }
    }
    return [];
  }

  async appendPagnination(): Promise<void> {
    if (this.paginationFrom < this.totalHits) {
      const nextInfluencers = await this._getInfluencers(
        this._buildSearchReq(this.paginationFrom),
      );
      this.options = [...this.options, ...nextInfluencers];
      this.changeDetection.markForCheck();
    }
  }

  private _processOptions(
    response: SimplePrefixSearchResponse_SimplePrefixSearchCreatorCard[],
  ): CreatorCardSnippet[] {
    const creators = response.map((r) => r.baseSnippet).filter(isNotNil);
    if (this.allowNotFoundInfluencer && isNotEmptyString(this.searchValue)) {
      const exist = creators.some((u: CreatorCardSnippet) => {
        u.userName.toLowerCase() === this.searchValue.toLowerCase();
      });

      if (exist === false) {
        return [
          new CreatorCardSnippet({ userName: this.searchValue }),
          ...creators,
        ];
      }
    }
    return creators;
  }

  itemRenderer = (item: CreatorCardSnippet): string => item.userName;

  componentFactory: ComponentFactory<CreatorCardSnippet> = () =>
    InfluencerAutocompleteItemComponent;

  override writeValue(obj: unknown): void {
    if (obj instanceof CreatorCardSnippet) {
      this.value = obj;
    } else {
      this.value = new CreatorCardSnippet();
      this.searchValue = '';
    }
  }

  ngAfterViewInit(): void {
    if (this.openIfNoInput) {
      this.searchInfluencer();
    }
  }
}
