import { Component, HostBinding, Input, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import {
  CampaignFieldType,
  CreatorFieldType,
  EntityField,
} from '@frontend2/proto/librarian/proto/common_pb';
import { EntityFieldValue } from '@frontend2/proto/librarian/proto/entity_fields_pb';
import { LeftyFormValueBase } from '../../form';
import {
  getCustomFieldFormattedValue,
  getCustomFieldIcon,
  getCustomFieldLabel,
  isAddressCustomField,
  isBooleanCustomField,
  isDateCustomField,
  isDecimalNumberCustomField,
  isEmptyCustomFieldValue,
  isListCustomField,
  isLocationCustomField,
  isNumberCustomField,
  isTextCustomField,
} from '../custom-fields.helpers';

@Component({
  template: '',
})
export abstract class CustomFieldInputBaseComponent extends LeftyFormValueBase<EntityFieldValue> {
  @Input()
  readOnly = false;

  @Input()
  placeholder = '';

  @Input()
  active = false;

  @Input()
  field = new EntityField();

  isMultiselection(): boolean {
    return this.field.multiSelection;
  }

  get isList(): boolean {
    return isListCustomField(this.field);
  }

  get isText(): boolean {
    return isTextCustomField(this.field);
  }

  decimalDigits = '1';

  constructor(@Self() @Optional() ngControl?: NgControl) {
    super(new EntityFieldValue(), ngControl);
  }

  get fieldType(): CreatorFieldType | CampaignFieldType | undefined {
    if (this.field.fieldType.value === CreatorFieldType.DECIMAL_NUMBER) {
      this.decimalDigits = '0.01';
    }
    return this.field.fieldType.value;
  }

  get entityFieldValue(): EntityFieldValue {
    return this.value;
  }

  get entityNativeType(): string {
    switch (this.fieldType) {
      case CreatorFieldType.TEXT:
      case CampaignFieldType.TEXT:
        return 'text';
      case CreatorFieldType.DECIMAL_NUMBER:
      case CreatorFieldType.NUMBER:
        return 'number';
      default:
        return '';
    }
  }

  get icon(): string {
    return getCustomFieldIcon(this.field);
  }

  get fieldLabel(): string {
    return getCustomFieldLabel(this.field);
  }

  @HostBinding('class.has-value')
  get hasValue(): boolean {
    return isEmptyCustomFieldValue(this.field, this.value);
  }

  override writeValue(obj: unknown): void {
    if (obj instanceof EntityFieldValue) {
      this.value = obj;
    } else {
      this.value = new EntityFieldValue();
    }
  }

  get isTextFieldInput(): boolean {
    return (
      isTextCustomField(this.field) ||
      isNumberCustomField(this.field) ||
      isDecimalNumberCustomField(this.field)
    );
  }

  get formattedValue(): string {
    return getCustomFieldFormattedValue(this.value);
  }

  get isBoolFieldInput(): boolean {
    return isBooleanCustomField(this.field);
  }

  get isDateFieldInput(): boolean {
    return isDateCustomField(this.field);
  }

  get isListFieldInput(): boolean {
    return isListCustomField(this.field);
  }

  get isLocationFieldInput(): boolean {
    return isLocationCustomField(this.field);
  }

  get isAddressFieldInput(): boolean {
    return isAddressCustomField(this.field);
  }
}
