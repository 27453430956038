import { Component, Optional, Self, inject } from '@angular/core';
import { NgControl } from '@angular/forms';
import { setCustomFieldEnumValue } from '../../custom-fields.helpers';
import { CreatorEntityFieldsCache } from '../../entity-fields.cache';
import { CustomFieldInputBaseComponent } from '../input.base';

@Component({
  template: '',
})
export abstract class CustomFieldListInputBaseComponent extends CustomFieldInputBaseComponent {
  readonly entityFieldCache = inject(CreatorEntityFieldsCache);

  constructor(@Self() @Optional() ngControl?: NgControl) {
    super(ngControl);
  }

  get options(): string[] {
    return this.field.listValues;
  }

  readableEnum(item: string): string {
    return item;
  }

  get listValue(): string[] {
    const fieldValue = this.value.value;
    switch (fieldValue.case) {
      case 'enumValue':
        return fieldValue.value.values;

      default:
        return [];
    }
  }

  set listValue(val: string[]) {
    this.handleValueChange(setCustomFieldEnumValue(this.value, val));
  }
}
