import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
} from '@angular/core';
import {
  LeftyFormat,
  MassSelectState,
  createSelection,
  getSelectionCount,
  hasAllSelected,
} from '@frontend2/core';
import { Placement } from '@popperjs/core';
import { LeftyComponent, createOutput } from '../utils';
import { LeftyMenuIconComponent } from '../lefty-menu-icon/lefty-menu-icon.component';
import { NgIf } from '@angular/common';
import { LeftyFooterComponent } from './lefty-footer.component';

@Component({
  selector: 'lefty-mass-select-footer',
  templateUrl: 'lefty-mass-select-footer.component.html',
  styleUrls: ['lefty-mass-select-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LeftyFooterComponent, NgIf, LeftyMenuIconComponent],
})
export class LeftyMassSelectFooterComponent<T> extends LeftyComponent {
  readonly popupPlacement: Placement = 'top-start';

  @Input()
  state: MassSelectState<T> = createSelection();

  get selectionCount(): number {
    return getSelectionCount(this.state);
  }

  get formattedSelectionCount(): string {
    return LeftyFormat.format(this.selectionCount, { showZero: true });
  }

  get isAllSelected(): boolean {
    return hasAllSelected(this.state);
  }

  get showSelectAll(): boolean {
    return this.isAllSelected === false;
  }

  @Input()
  selectionLabel = $localize`selected`;

  @Output()
  readonly selectAll$ = createOutput<Event>();

  @Output()
  readonly cancel$ = createOutput<Event>();
}
