import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UiModule } from '@frontend2/ui';
import { AddOrEditManualStoreDialogComponent } from './components/add-or-edit-manual-store-dialog/add-or-edit-manual-store-dialog.component';
import { AddProductDialogComponent } from './components/add-product-dialog/add-product-dialog.component';
import { AlfredAddSalesforceStoreDialogComponent } from './components/add-salesforce-store-dialog/add-salesforce-store-dialog.component';
import { AlfredAddSalesforceConnectingDialogComponent } from './components/add-salesforce-store-dialog/connecting/add-salesforce-connecting-dialog.component';
import { AlfredAddSalesforceIntroDialogComponent } from './components/add-salesforce-store-dialog/intro/add-salesforce-intro-dialog.component';
import { AlfredAddSalesforceStepOneDialogComponent } from './components/add-salesforce-store-dialog/step-1/add-salesforce-step-one-dialog.component';
import { AlfredAddSalesforceStepTwoDialogComponent } from './components/add-salesforce-store-dialog/step-2/add-salesforce-step-two-dialog.component';
import { AlfredAddSalesforceStepThreeDialogComponent } from './components/add-salesforce-store-dialog/step-3/add-salesforce-step-three-dialog.component';
import { AlfredAddSalesforceStepFourDialogComponent } from './components/add-salesforce-store-dialog/step-4/add-salesforce-step-four-dialog.component';
import { AlfredAddSalesforceSummaryDialogComponent } from './components/add-salesforce-store-dialog/summary/add-salesforce-summary-dialog.component';
import { AddressCardComponent } from './components/address-card/address-card.component';
import { ChatPreviewComponent } from './components/alfred-chat-preview/chat-preview.component';
import { CreatorDropdownComponent } from './components/filter-dropdown-items/creator-dropdown-item.component';
import { StoreDropdownComponent } from './components/filter-dropdown-items/store-dropdown-item.component';
import { InfluencerProfilePicturesComponent } from './components/influencer-profile-pictures-item/influencer-profile-pictures-item.component';
import { AlfredInfluencerTableCellComponent } from './components/influencer-table-cell/influencer-table-cell.component';
import { ProductListWithSelectionComponent } from './components/product-list-with-selection/product-list-with-selection.component';
import { AddressDropdownItemComponent } from './components/save-address-dialog/address-dropdown-item.component';
import { SaveAddressDialogComponent } from './components/save-address-dialog/save-address-dialog.component';
import { AlfredSelectOfflineStoreDialogComponent } from './components/select-offiline-store-dialog.component/select-offline-store-dialog.component';
import { SelectPreselectedProductsVariantDialogComponent } from './components/select-preselected-products-variant-dialog/select-preselected-products-variant-dialog';
import { AlfredSelectSalesforceStoreDialogComponent } from './components/select-salesforce-store-dialog/select-salesforce-store-dialog.component';
import { SelectShopifyStoreDialogComponent } from './components/select-shopify-store-dialog/select-shopify-store-dialog.component';
import { AlfredSelectStoreDialogComponent } from './components/select-store-dialog/select-store-dialog.component';
import { AlfredSidePanelRowComponent } from './components/side-panel-row/side-panel-row.component';
import { AlfredSidePanelSectionComponent } from './components/side-panel-section/side-panel-section.component';
import { AlfredStoreCardItemComponent } from './components/store-card-item/store-card-item.component';
import { StoreTableCellComponent } from './components/store-table-cell/store-cell.component';
import { AlfredUnsavedChangesDialogComponent } from './components/unsaved-changes-dialog/unsaved-changes-dialog.component';
import { VariantSelectionDialogComponent } from './components/variant-selection-dialog/variant-selection-dialog.component';
import { AlfredWorkspacesListComponent } from './components/workspaces-list/workspaces-list.component';
import { AddPaymentMethodDialogComponent } from './payments/add-payment-method-dialog/add-payment-method-dialog.component';
import {
  CanEditPaymentMethodsDirective,
  CanEditPermissionsDirective,
  CanEditSettingsDirective,
  CanManageAffiliationsDirective,
  CanManageAnalyticsDirective,
  CanManageCampaignsDirective,
  CanManageLandingPagesDirective,
  CanManageProductsDirective,
  CanPayDirective,
} from './permissions/permission.directive';
import { SidebarCardComponent } from './right-panel/card-section/right-panel-card-section.component';
import { AlfredInfluencerCardComponent } from './right-panel/influencer-address-card/influencer-address-card.component';
import { ProductItemInRightPanelComponent } from './right-panel/product-item/product-item-in-right-panel.component';

@NgModule({
  imports: [UiModule, CommonModule, ReactiveFormsModule, FormsModule],
  declarations: [
    CanEditSettingsDirective,
    CanEditPermissionsDirective,
    CanManageCampaignsDirective,
    CanManageAffiliationsDirective,
    CanManageLandingPagesDirective,
    CanEditPaymentMethodsDirective,
    CanPayDirective,
    CanManageProductsDirective,
    CanManageAnalyticsDirective,
    AlfredUnsavedChangesDialogComponent,
    AlfredWorkspacesListComponent,
    ChatPreviewComponent,
    SelectPreselectedProductsVariantDialogComponent,
    AddPaymentMethodDialogComponent,
    AddOrEditManualStoreDialogComponent,
    AlfredSelectStoreDialogComponent,
    AlfredStoreCardItemComponent,
    AlfredSelectOfflineStoreDialogComponent,
    ProductListWithSelectionComponent,
    VariantSelectionDialogComponent,
    AlfredInfluencerTableCellComponent,
    StoreTableCellComponent,
    AlfredAddSalesforceStoreDialogComponent,
    AlfredAddSalesforceIntroDialogComponent,
    AlfredAddSalesforceStepOneDialogComponent,
    AlfredAddSalesforceStepTwoDialogComponent,
    AlfredAddSalesforceStepThreeDialogComponent,
    AlfredAddSalesforceStepFourDialogComponent,
    AlfredAddSalesforceConnectingDialogComponent,
    AlfredAddSalesforceSummaryDialogComponent,
    SidebarCardComponent,
    ProductItemInRightPanelComponent,
    AlfredInfluencerCardComponent,
    AlfredSidePanelRowComponent,
    AlfredSidePanelSectionComponent,
    AlfredSelectSalesforceStoreDialogComponent,
    InfluencerProfilePicturesComponent,
    SelectShopifyStoreDialogComponent,
    SaveAddressDialogComponent,
    AddressDropdownItemComponent,
    AddressCardComponent,
    AddProductDialogComponent,
    CreatorDropdownComponent,
    StoreDropdownComponent,
  ],
  exports: [
    CanEditSettingsDirective,
    CanEditPermissionsDirective,
    CanManageCampaignsDirective,
    CanManageAffiliationsDirective,
    CanManageLandingPagesDirective,
    CanPayDirective,
    CanEditPaymentMethodsDirective,
    CanManageProductsDirective,
    CanManageAnalyticsDirective,
    AlfredUnsavedChangesDialogComponent,
    AlfredWorkspacesListComponent,
    ChatPreviewComponent,
    SelectPreselectedProductsVariantDialogComponent,
    AddPaymentMethodDialogComponent,
    AddOrEditManualStoreDialogComponent,
    AlfredSelectStoreDialogComponent,
    AlfredStoreCardItemComponent,
    AlfredSelectOfflineStoreDialogComponent,
    ProductListWithSelectionComponent,
    VariantSelectionDialogComponent,
    AlfredInfluencerTableCellComponent,
    StoreTableCellComponent,
    AlfredAddSalesforceStoreDialogComponent,
    SidebarCardComponent,
    ProductItemInRightPanelComponent,
    AlfredInfluencerCardComponent,
    AlfredSidePanelRowComponent,
    AlfredSidePanelSectionComponent,
    AlfredSelectSalesforceStoreDialogComponent,
    InfluencerProfilePicturesComponent,
    SelectShopifyStoreDialogComponent,
    SaveAddressDialogComponent,
    AddressCardComponent,
    AddProductDialogComponent,
  ],
  providers: [],
})
export class AlfredCommonModule {}
