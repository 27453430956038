import { Timestamp } from '@bufbuild/protobuf';
import {
  endOfDay,
  formatDate,
  getDuration,
  getDurationInDays,
  isNil,
  isNotNil,
} from '@frontend2/core';
import {
  CommonFilter,
  TimeRestriction,
  TimestampFilter,
} from '@frontend2/proto/librarian/proto/common_pb';
import { INFINITY_DATE } from '../../dynamic-filters.helpers';

export type DateOperator =
  | 'isEqual'
  | 'isBefore'
  | 'isAfter'
  | 'isBetween'
  | 'isEmpty';

export function readableDateOperator(op: DateOperator): string {
  switch (op) {
    case 'isEqual':
      return $localize`is`;
    case 'isBefore':
      return $localize`is before`;
    case 'isAfter':
      return $localize`is after`;
    case 'isBetween':
      return $localize`is between`;
    case 'isEmpty':
      return $localize`is empty`;
  }
}

export interface FilterDateValue {
  readonly operator: DateOperator;
  readonly dateValue: Date;
  readonly range: TimeRestriction;
}

export function createFilterDateValue(
  partial?: Partial<FilterDateValue>,
): FilterDateValue {
  return {
    operator: partial?.operator ?? 'isEmpty',
    dateValue: partial?.dateValue ?? new Date(),
    range: partial?.range ?? new TimeRestriction(),
  };
}

export function getFilterDateValueFromCommonFilter(
  filter: CommonFilter,
): FilterDateValue {
  const filterValue = filter.filter;
  if (filterValue.case === 'isEmpty') {
    return createFilterDateValue({ operator: 'isEmpty' });
  }
  if (filterValue.case === 'dateFilter') {
    const datefilter = filterValue.value;
    const range = datefilter.is;
    if (isNil(range)) {
      return createFilterDateValue({ operator: 'isEmpty' });
    }
    if (isNotNil(range.start) && isNotNil(range.end)) {
      const duration = getDuration(range.start.toDate(), range.end.toDate());
      if (getDurationInDays(duration) > 1) {
        return createFilterDateValue({
          operator: 'isBetween',
          range: new TimeRestriction({
            start: range.start,
            end: range.end,
          }),
        });
      }
      return createFilterDateValue({
        operator: 'isEqual',
        dateValue: range.start.toDate(),
      });
    }
    if (isNotNil(range.start)) {
      return createFilterDateValue({
        operator: 'isAfter',
        dateValue: range.start.toDate(),
      });
    }
    if (isNotNil(range.end)) {
      return createFilterDateValue({
        operator: 'isBefore',
        dateValue: range.end.toDate(),
      });
    }
  }
  return createFilterDateValue();
}

export function filterDateValueToFromCommonFilter(
  filter: FilterDateValue,
): TimestampFilter {
  switch (filter.operator) {
    case 'isAfter':
      return new TimestampFilter({
        is: new TimeRestriction({
          start: Timestamp.fromDate(filter.dateValue),
        }),
      });

    case 'isBefore':
      return new TimestampFilter({
        is: new TimeRestriction({
          end: Timestamp.fromDate(filter.dateValue),
        }),
      });

    case 'isBetween':
      return new TimestampFilter({
        //when the end is INFINIY_DATE it means that it is unset
        is: new TimeRestriction({
          start: filter.range.start ?? Timestamp.fromDate(new Date()),
          end: filter.range.end ?? INFINITY_DATE,
        }),
      });
    case 'isEqual': {
      const end = endOfDay(filter.dateValue);
      return new TimestampFilter({
        is: new TimeRestriction({
          start: Timestamp.fromDate(filter.dateValue),
          end: Timestamp.fromDate(end),
        }),
      });
    }
    case 'isEmpty':
      return new TimestampFilter();
  }
}

export function formattedTimestampFilterValue(filter: TimestampFilter): string {
  const filterValue = filter.is;
  if (isNil(filterValue)) {
    return '';
  }
  if (isNotNil(filterValue.start) && isNotNil(filterValue.end)) {
    const duration = getDuration(
      filterValue.start.toDate(),
      filterValue.end.toDate(),
    );
    if (getDurationInDays(duration) > 1) {
      //if unset
      if (filterValue.end === INFINITY_DATE) {
        return '';
      }
      return (
        formatDate(filterValue.start.toDate()) +
        $localize` and ` +
        formatDate(filterValue.end.toDate())
      );
    }
    return formatDate(filterValue.start.toDate());
  }
  if (isNotNil(filterValue.start)) {
    return formatDate(filterValue.start.toDate());
  }
  if (isNotNil(filterValue.end)) {
    return formatDate(filterValue.end.toDate());
  }
  return '';
}
