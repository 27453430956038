<img
  height="40"
  appLogo
  class="logo"
/>
<ng-container
  *ngIf="!isInvalidSubscription() || hasMultipleWorkspaces ; else singleWorkspaceInvalid"
>
  <lefty-card [withBorder]="true">
    <div
      class="card-title"
      *ngIf="isInvalidSubscription() "
    >
      <h4 i18n>
        Hi {{userName}}, the current workspace is not active anymore.
      </h4>
      <p i18n>
        Please choose among your other active workspaces or contact your account
        manager for any request.
      </p>
    </div>
    <h2
      class="card-title"
      i18n
      *ngIf="!isInvalidSubscription()"
    >
      Select a workspace
    </h2>
    <div class="filter-name-container">
      <lefty-form-input
        #searchWorkspaceInput
        placeholder="Search by name"
        i18n-placeholder
      >
      </lefty-form-input>
    </div>
    <alfred-workspaces-list
      [spaceFilterName]="searchWorkspaceInput.value"
      (select$)="workspaceSelected($event)"
    ></alfred-workspaces-list>
  </lefty-card>
</ng-container>
<ng-template #singleWorkspaceInvalid>
  <lefty-card
    [withBorder]="true"
    [withPadding]="true"
    class="single-workspace-invalid"
  >
    <img
      class="noaccess-image"
      [src]="noAccessIllustration"
    />
    <h4 i18n>Hi {{userName}}, it seems that your plan has expired.</h4>
    <p i18n>Please contact us to regain access to the Lefty platform.</p>
    <button
      leftyButton="primary"
      (click)="contantUsDialog.open()"
    >
      {{Messages.contactUs}}
    </button></lefty-card
  >
</ng-template>
<div class="sub-text">
  <p i18n>You are logged as <b>{{ email }}</b></p>
  <p i18n>
    Not your account?
    <a
      class="logout-button"
      (click)="logout()"
    >
      Logout
    </a>
  </p>
</div>

<lefty-contact-us-dialog #contantUsDialog></lefty-contact-us-dialog>
