<ng-container *ngIf="!isGhostNavItem(config)">
  <!-- we use differnet classes for leftyRouterLink and isActive() so that they dont interfere with eachother, they have the same styles-->
  <a
    *ngIf="!config.isExternalLink"
    class="navigation-btn"
    [leftyRouterLink]="config.link"
    [leftyTooltip]="config.label"
    [class.active]="isActive()"
    leftyRouterLinkActive="router-active"
    [offset]="5"
    [tooltipPlacement]="tooltipPlacement"
  >
    <lefty-icon [icon]="config.icon"></lefty-icon>
  </a>

  <a
    *ngIf="config.isExternalLink"
    class="navigation-btn"
    [attr.href]="config.link"
    target="_blank"
    rel="noopener"
    [offset]="5"
    [leftyTooltip]="config.label"
    [tooltipPlacement]="tooltipPlacement"
  >
    <lefty-icon [icon]="config.icon"></lefty-icon>
  </a>
</ng-container>
