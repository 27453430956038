// import 'dart:html';

import {
  ChangeDetectionStrategy,
  Component,
  computed,
  Input,
  input,
  output,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  createChip,
  DEFAULT_KEYWORD_OPERATOR,
  isEmptyArray,
  isEmptyString,
  isNotEmptyString,
  isNotNil,
} from '@frontend2/core';
import { formatGenerikKeyword } from '@frontend2/proto-helpers/common/common_pb.helpers';
import {
  GenerikKeyword,
  KeywordType,
  LogicOperator,
} from '@frontend2/proto/common/proto/common_pb';
import { GenerikKeywordsFilter } from '@frontend2/proto/librarian/proto/common_pb';
import { DropdownBase } from '../dropdown';
import {
  ButtonType,
  LeftyButtonDirective,
} from '../lefty-button-directive/lefty-button.directive';
import { isDownKey, isEnterKey, isSpaceKey, isUpKey } from '../utils';
import { LeftyLogicOperatorComponent } from '../lefty-logic-operator-selector/logic-operator.component';
import { ActiveItemDirective } from '../active-item.directive';
import { LeftySelectDropdownItemComponent } from '../lefty-form-select/lefty-select-dropdown-item.component';
import { LeftyListComponent } from '../lefty-list/lefty-list.component';
import { LeftyChipComponent } from '../lefty-chip/lefty-chip.component';
import { LeftyFormInputComponent } from '../lefty-form-input/lefty-form-input.component';
import { LeftyPopupComponent } from '../lefty-popup/lefty-popup.component';
import { LeftyIconComponent } from '../icon/icon.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'lefty-keywords-selector-dropdown',
  templateUrl: 'keywords-selector.component.html',
  styleUrls: ['../selector/selector.scss', 'keywords-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    LeftyButtonDirective,
    LeftyIconComponent,
    LeftyPopupComponent,
    LeftyFormInputComponent,
    LeftyChipComponent,
    LeftyListComponent,
    LeftySelectDropdownItemComponent,
    ActiveItemDirective,
    LeftyLogicOperatorComponent,
  ],
})
export class LeftyKeywordsSelectorDropdownComponent extends DropdownBase {
  constructor() {
    super();

    this.close$.pipe(takeUntilDestroyed()).subscribe(() => {
      if (this.emitChangesOnClose()) {
        this._notifyChange(this.$keywordsFilter());
      }
    });
  }

  readonly emitChangesOnClose = input(false);

  readonly $keywordsFilter = signal(new GenerikKeywordsFilter());

  readonly $selection = computed(() => this.$keywordsFilter().keywords);

  @Input()
  set keywordsFilter(value: GenerikKeywordsFilter) {
    this.$keywordsFilter.set(value);
  }

  readonly keywordsFilterChange = output<GenerikKeywordsFilter>();

  readonly operator = computed(() => {
    const op = this.$keywordsFilter().operator;
    if (op === LogicOperator.UNKNOWN_OPERATOR) {
      return DEFAULT_KEYWORD_OPERATOR;
    }
    return op;
  });

  readonly selectionSize = computed(() => this.$selection().length);
  readonly hasSelection = computed(() => this.selectionSize() > 0);

  get canShowLabel(): boolean {
    return isNotEmptyString(this.label);
  }

  readonly placeholder = input('');

  readonly buttonText = computed(() => {
    if (isEmptyArray(this.$selection())) {
      return this.placeholder();
    }
    return this.$selection().map(formatGenerikKeyword).join(', ');
  });

  @Input()
  inputPlaceholder = 'guerlain, beauty or fashion';

  @Input()
  buttonType: ButtonType = 'secondary';

  readonly chips = computed(() =>
    this.$selection().map((key) =>
      createChip(key, { formattedValue: formatGenerikKeyword(key) }),
    ),
  );

  readonly inputValue = signal('');

  readonly hasInputValue = computed(() => isNotEmptyString(this.inputValue()));

  readonly options = computed(() => this._buildOptions(this.inputValue()));

  private _buildOptions(keyword: string): GenerikKeyword[] {
    keyword = keyword.trim();

    if (isEmptyString(keyword)) {
      return [];
    }

    return [
      new GenerikKeyword({
        value: keyword,
        keywordType: KeywordType.KW_MENTION,
      }),
      new GenerikKeyword({
        value: keyword,
        keywordType: KeywordType.KW_HASHTAG,
      }),
      new GenerikKeyword({
        value: keyword,
        keywordType: KeywordType.KW_KEYWORD,
      }),
    ];
  }

  getKeywordLabel(type: KeywordType): string {
    switch (type) {
      case KeywordType.KW_MENTION:
        return $localize`Mention`;
      case KeywordType.KW_HASHTAG:
        return $localize`Hashtag`;
      case KeywordType.KW_KEYWORD:
        return $localize`Keyword`;
      default:
        return '';
    }
  }

  readonly activeIndex = signal(0);

  readonly activeItem = computed(() => {
    const options = this.options();
    if (options.length === 0 || this.activeIndex() >= options.length) {
      return;
    }
    return options[this.activeIndex()];
  });

  isActive(item: GenerikKeyword): boolean {
    return this.activeItem() === item;
  }

  activate(item: GenerikKeyword): void {
    this.activeIndex.set(this.options().indexOf(item));
  }

  selectKeyword(keyword: GenerikKeyword): void {
    const keywordValue = keyword.value.trim();
    if (isEmptyString(keywordValue)) {
      return;
    }

    if (this.$selection().includes(keyword) === false) {
      this._setKeywordsFilterAndMaybeNotify(
        new GenerikKeywordsFilter({
          ...this.$keywordsFilter(),
          keywords: [...this.$selection(), keyword],
        }),
      );
    }

    this.inputValue.set('');
  }

  unselectKeyword(keyword: GenerikKeyword): void {
    this._setKeywordsFilterAndMaybeNotify(
      new GenerikKeywordsFilter({
        ...this.$keywordsFilter(),
        keywords: this.$selection().filter((k) => k !== keyword),
      }),
    );
  }

  setOperator(operator: LogicOperator): void {
    this._setKeywordsFilterAndMaybeNotify(
      new GenerikKeywordsFilter({
        ...this.$keywordsFilter(),
        operator,
      }),
    );
  }

  private _setKeywordsFilterAndMaybeNotify(value: GenerikKeywordsFilter): void {
    this.$keywordsFilter.set(value);
    if (this.emitChangesOnClose() === false) {
      this._notifyChange(value);
    }
  }

  private _notifyChange(value: GenerikKeywordsFilter): void {
    this.keywordsFilterChange.emit(value);
  }

  handleKeyDown(event: KeyboardEvent): void {
    if (isUpKey(event.code)) {
      event.preventDefault();
      if (this.activeIndex() > 0) {
        this.activeIndex.update((i) => {
          return i - 1;
        });
      } else {
        this.activeIndex.set(this.options().length - 1);
      }
    } else if (isDownKey(event.code)) {
      event.preventDefault();
      if (this.activeIndex() < this.options().length - 1) {
        this.activeIndex.update((i) => {
          return i + 1;
        });
      } else {
        this.activeIndex.set(0);
      }
    }
  }

  private _handleEnterKey(event: KeyboardEvent): void {
    const activeItem = this.activeItem();

    if (isEmptyString(this.inputValue())) {
      this.close();
    } else if (isNotNil(activeItem)) {
      event.preventDefault();
      event.stopPropagation();
      this.selectKeyword(activeItem);
    }
  }

  private _handleSpaceKey(event: KeyboardEvent): void {
    const activeItem = this.activeItem();
    if (isNotNil(activeItem)) {
      event.preventDefault();
      event.stopPropagation();
      this.selectKeyword(activeItem);
    }
  }

  handleKeypress(event: KeyboardEvent): void {
    if (isEnterKey(event.code)) {
      this._handleEnterKey(event);
    } else if (isSpaceKey(event.code)) {
      this._handleSpaceKey(event);
    }
  }

  clearAll(): void {
    this._setKeywordsFilterAndMaybeNotify(new GenerikKeywordsFilter());
  }

  override toggle(): void {
    this.inputValue.set('');
    this.activeIndex.set(0);
    super.toggle();
  }
}
