<lefty-form
  [label]="label"
  [required]="required"
  [optional]="optional"
  [helpText]="helpText"
  [externalHelpLink]="externalHelpLink"
  [helpLink]="helpLink"
  [hintText]="hintText"
  [errorMessage]="errorMessage"
  [tooltip]="tooltip"
  [large]="large"
  [topHintText]="topHintText"
>
  <button
    #popupTrigger
    class="selector-btn"
    [leftyButton]="buttonType"
    focusableElement
  >
    <div class="button-content">
      <span
        class="placeholder"
        [class.empty]="showPlaceholder"
      >
        {{ buttonText }}</span
      >
    </div>
    <lefty-icon icon="arrow_drop_down"></lefty-icon>
  </button>
  <lefty-popup
    [placement]="popupPlacement"
    [popupTrigger]="popupTrigger"
    [matchMinSourceWidth]="true"
    #popup
  >
    <div class="popup-content">
      <div class="header">
        <button
          leftyButton="plain"
          class="button-clear"
          i18n
          [disabled]="isEmpty"
          (click)="clearAll()"
        >
          Clear All
        </button>
      </div>
      <div class="body">
        <lefty-range-input
          [value]="this.value"
          (valueChange)="handleValueChange($event)"
          [splitMessage]="splitMessage"
        ></lefty-range-input>
      </div>
    </div>
  </lefty-popup>
</lefty-form>
