import { Int64Value } from '@bufbuild/protobuf';
import { isEmptyString } from './strings.helpers';

export function parseBigInt(value: string): bigint {
  return BigInt(parseInt(value));
}

export function parseInt64Value(value: string): Int64Value {
  if (isEmptyString(value)) {
    return new Int64Value();
  }

  return new Int64Value({ value: parseBigInt(value) });
}
