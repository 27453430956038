import {
  CampaignPerformance,
  InfluencerState,
  InfluencerStatus,
  NetworkCampaignPerformance,
} from '@frontend2/proto/common/proto/campaign_pb';
import { Network, PostType } from '@frontend2/proto/common/proto/common_pb';
import {
  Campaign,
  CampaignMultiReport,
  MultiTimeline,
} from '@frontend2/proto/librarian/proto/campaigns_pb';
import { isAfterNow, isBeforeNow } from '../../times';
import { isEmptyArray, isNil, isNotNil } from '../../utils/common.helpers';
import {
  getNetworkFromCampaignKeyword,
  getPostTypesOfNetwork,
} from '../common/common_pb.helpers';

export function getCampaignNetworksWithPost(campaign: Campaign): Network[] {
  const noNetworks: Network[] = [];
  const unsortedNetworksWithPosts =
    campaign?.postsPerNetworkCount?.networkPostsCount
      .filter((e) => e.postsCount > 0)
      .map((e) => e.network);
  if (isNotNil(unsortedNetworksWithPosts)) {
    return unsortedNetworksWithPosts;
  }
  return noNetworks;
}

export function getCampaignTrackingNetwork(campaign: Campaign): Network[] {
  const noNetworks: Network[] = [];
  const unsortedTrackingNetworks = campaign?.keywords.map((e) =>
    getNetworkFromCampaignKeyword(e.keywordType),
  );
  if (isNotNil(unsortedTrackingNetworks)) {
    return unsortedTrackingNetworks;
  }
  return noNetworks;
}

export function getCampaignSortedNetworks(networks: Network[]): Network[] {
  const combinedNetworks = [...new Set(networks)].sort((a, b) => {
    return a - b;
  });

  return combinedNetworks;
}

export function getCampaignNetworks(campaign: Campaign): Network[] {
  const trackingNetwork = getCampaignTrackingNetwork(campaign);
  const networkposts = getCampaignNetworksWithPost(campaign);
  const combinedNetworks = [...trackingNetwork, ...networkposts];
  const combinedNetworksSorted = getCampaignSortedNetworks(combinedNetworks);

  return combinedNetworksSorted;
}

export enum CampaignTrackingStatus {
  default,
  running,
  upcomming,
  completed,
}

export function getCampaignTrackingStatus(
  campaign: Campaign,
): CampaignTrackingStatus {
  if (isAfterNow(campaign.end)) {
    return CampaignTrackingStatus.completed;
  }
  if (isBeforeNow(campaign.start)) {
    return CampaignTrackingStatus.upcomming;
  }
  return CampaignTrackingStatus.running;
}

export function readableCampaignTrackingStatus(
  trackingStatus: CampaignTrackingStatus,
): string {
  switch (trackingStatus) {
    case CampaignTrackingStatus.completed:
      return $localize`Completed`;
    case CampaignTrackingStatus.running:
      return $localize`Running`;
    case CampaignTrackingStatus.upcomming:
      return $localize`Upcoming`;
    default:
      return $localize`All campaigns`;
  }
}

export function isGhostCampaign(campaign: Campaign): boolean {
  return isNil(campaign.id) || campaign.id === BigInt(0);
}

export function readableInfluencerStatus(status: InfluencerStatus): string {
  switch (status) {
    case InfluencerStatus.INFLUENCER_INVITED:
      return $localize`Invited`;
    case InfluencerStatus.INFLUENCER_REJECTED:
      return $localize`Rejected`;
    case InfluencerStatus.INFLUENCER_ACCEPTED:
      return $localize`Accepted`;
    default:
      return $localize`Not invited`;
  }
}

export function readableInfluencerState(state: InfluencerState): string {
  switch (state) {
    case InfluencerState.STATE_ACCEPTED:
      return $localize`Accepted`;
    case InfluencerState.STATE_CONTACTED:
      return $localize`Contacted`;
    case InfluencerState.STATE_IN_DISCUSSION:
      return $localize`In discussion`;
    case InfluencerState.STATE_PAID:
      return $localize`Paid`;
    case InfluencerState.STATE_WAITING:
      return $localize`Waiting`;
    case InfluencerState.STATE_REJECTED:
      return $localize`Rejected`;
    default:
      return $localize`All status`;
  }
}

export function getPerfMediaCountByType(
  perf: NetworkCampaignPerformance,
  types: PostType[],
): number {
  const byTypes = perf.performanceByType.filter((p) =>
    types.includes(p.postType),
  );
  return byTypes.reduce((sum, perf) => sum + perf.count, 0);
}

export function getPerfMediaCountByNetwork(
  perf: NetworkCampaignPerformance,
  network: Network,
): number {
  const types = getPostTypesOfNetwork(network);
  return getPerfMediaCountByType(perf, types);
}

export function getLikesFromPerformance(
  perf: NetworkCampaignPerformance,
): bigint {
  return perf.networkStats?.interactions?.likes ?? BigInt(0);
}

export function getCommentsFromPerformance(
  perf: NetworkCampaignPerformance,
): bigint {
  return perf.networkStats?.interactions?.comments ?? BigInt(0);
}

export function getImpressionsFromPerformance(
  perf: NetworkCampaignPerformance,
): bigint {
  return perf.networkStats?.impressions ?? BigInt(0);
}

export function getSharesFromPerformance(
  perf: NetworkCampaignPerformance,
): bigint {
  return perf.networkStats?.interactions?.shares ?? BigInt(0);
}

export function getEmvFromPerformance(
  perf: NetworkCampaignPerformance,
): bigint {
  return perf.networkStats?.emv ?? BigInt(0);
}

export function getTotalMediaCountFromCampaingPerformance(
  perf: CampaignPerformance,
): number {
  return isEmptyArray(perf.networksPerf)
    ? 0
    : perf.networksPerf
        .flatMap((e) => e.performanceByType)
        .reduce((sum, perf) => sum + perf.count, 0);
}

export function getPotentialReachFromCampaingPerformance(
  perf: CampaignPerformance,
): number {
  if (isEmptyArray(perf.networksPerf)) {
    return 0;
  }
  return perf.networksPerf
    .map((e) => Number(e.potentialReach))
    .reduce((a, b) => a + b);
}

export function getTotalLikesFromCampaingPerformance(
  perf: CampaignPerformance,
): number {
  if (isEmptyArray(perf.networksPerf)) {
    return 0;
  }
  return perf.networksPerf
    .map((e) => Number(getLikesFromPerformance(e)))
    .reduce((a, b) => a + b);
}

export function getTotalImpressionsFromCampaingPerformance(
  perf: CampaignPerformance,
): number {
  if (isEmptyArray(perf.networksPerf)) {
    return 0;
  }
  return perf.networksPerf
    .map((e) => Number(getImpressionsFromPerformance(e)))
    .reduce((a, b) => a + b);
}

export function getTotalCommentsFromCampaingPerformance(
  perf: CampaignPerformance,
): number {
  if (isEmptyArray(perf.networksPerf)) {
    return 0;
  }
  return perf.networksPerf
    .map((e) => Number(getCommentsFromPerformance(e)))
    .reduce((a, b) => a + b);
}

export function getTotalSharesFromCampaingPerformance(
  perf: CampaignPerformance,
): number {
  if (isEmptyArray(perf.networksPerf)) {
    return 0;
  }
  return perf.networksPerf
    .map((e) => Number(getSharesFromPerformance(e)))
    .reduce((a, b) => a + b);
}

export function getMultiReportNetworks(report: CampaignMultiReport): Network[] {
  return (
    report.performance?.networksPerf
      .map((perf) => perf.network)
      .filter(isNotNil) ?? []
  );
}

export function getNetworkCampaignPerformanceOfNetwork(
  perf: CampaignPerformance,
  network: Network,
): NetworkCampaignPerformance | undefined {
  return perf.networksPerf.find((p) => p.network === network);
}

export function getCampaignCost(
  campaign: Campaign,
  report: CampaignMultiReport,
): bigint {
  return report.influencersTotalCost + campaign.cost;
}

export function isGhostCampaignMultiReport(
  report: CampaignMultiReport,
): boolean {
  return CampaignMultiReport.equals(new CampaignMultiReport(), report);
}

export function isGhostMultiTimeline(val: MultiTimeline): boolean {
  return isEmptyArray(val.points);
}
