import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Optional,
  Output,
  Self,
  computed,
  signal,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { isNil } from '@frontend2/core';
import {
  allCurrenciesWithSort,
  getCurrencyCode,
} from '@frontend2/proto-helpers/common/common_pb.helpers';
import { CurrencyEnum } from '@frontend2/proto/common/proto/common_pb';
import { LeftyFormValueBase } from '../form';
import { NgIf } from '@angular/common';
import { LeftyFormSelectComponent } from '../lefty-form-select/lefty-form-select.component';

@Component({
  selector: 'lefty-currency-selector',
  templateUrl: 'lefty-currency-selector.component.html',
  styleUrls: ['./lefty-currency-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LeftyFormSelectComponent, NgIf],
})
export class LeftyCurrencySelectorComponent extends LeftyFormValueBase<
  CurrencyEnum | undefined
> {
  constructor(@Self() @Optional() ngControl?: NgControl) {
    super(undefined, ngControl);
  }

  @Input()
  set selectedCurrency(val: CurrencyEnum | undefined) {
    this.value = val;
  }

  get selectedCurrency(): CurrencyEnum | undefined {
    return this.value;
  }

  @Output()
  readonly selectedCurrencyChange = this.valueChange;

  @Input()
  buttonClass = '';

  @Input()
  placeholder = $localize`Select currency`;

  readonly showMoreCurrencies = signal(false);

  readonly options = computed(() => {
    if (this.showMoreCurrencies()) {
      return allCurrenciesWithSort();
    }

    const primary = [...allCurrenciesWithSort().slice(0, 3)];
    return isNil(this.selectedCurrency) ||
      primary.includes(this.selectedCurrency)
      ? primary
      : [...primary, this.selectedCurrency];
  });

  showMore(): void {
    this.showMoreCurrencies.set(true);
  }

  currencyCode(c: CurrencyEnum | undefined): string {
    return isNil(c) || this.selectedCurrency === CurrencyEnum.NA
      ? this.placeholder
      : getCurrencyCode(c);
  }

  override writeValue(obj: unknown): void {
    if (typeof obj === 'number') {
      // no way to correctly check type of enum
      //
      // We cast the number to CurrencyEnum, but we are not 100% sure.
      this.value = obj as CurrencyEnum;
    }

    if (isNil(obj)) {
      this.value = undefined;
    }
  }
}
