import {
  BaseEntityType,
  CampaignFieldType,
  CreatorFieldType,
  EntityField,
} from '@frontend2/proto/librarian/proto/common_pb';
import { isNil } from '../../utils/common.helpers';

export type FieldType =
  | {
      value: CreatorFieldType;
      case: 'creatorFieldType';
    }
  | {
      value: CampaignFieldType;
      case: 'campaignFieldType';
    }
  | {
      case: undefined;
      value?: undefined;
    };

export function fieldTypeToReadable(fieldType: FieldType): string {
  switch (fieldType.case) {
    case 'creatorFieldType':
      return creatorFieldTypeToReadable(fieldType.value);

    case 'campaignFieldType':
      return campaignFieldTypeToReadable(fieldType.value);

    default:
      return $localize`Unknown`;
  }
}

export function creatorFieldTypeToReadable(
  fieldType: CreatorFieldType,
): string {
  switch (fieldType) {
    case CreatorFieldType.ADDRESS:
      return $localize`Address`;
    case CreatorFieldType.BOOLEAN:
      return $localize`True or False`;
    case CreatorFieldType.DATE:
      return $localize`Date`;
    case CreatorFieldType.DECIMAL_NUMBER:
      return $localize`Decimal number`;
    case CreatorFieldType.LIST:
      return $localize`List`;
    case CreatorFieldType.LOCATION:
      return $localize`Location`;
    case CreatorFieldType.NUMBER:
      return $localize`Number`;
    case CreatorFieldType.TEXT:
      return $localize`Text`;
    default:
      return $localize`Unknown`;
  }
}

export function campaignFieldTypeToReadable(
  fieldType: CampaignFieldType,
): string {
  switch (fieldType) {
    case CampaignFieldType.LIST:
      return $localize`List`;
    case CampaignFieldType.TEXT:
      return $localize`Text`;
    default:
      return $localize`Unknown`;
  }
}

export function isGhostEntityField(entityField: EntityField): boolean {
  return (
    isNil(entityField.entityFieldId) || entityField.entityFieldId === BigInt(0)
  );
}

export function createFieldType(
  entityType: BaseEntityType,
  fieldType: CreatorFieldType | CampaignFieldType,
):
  | {
      value: CreatorFieldType;
      case: 'creatorFieldType';
    }
  | {
      value: CampaignFieldType;
      case: 'campaignFieldType';
    }
  | {
      case: undefined;
      value?: undefined;
    } {
  switch (entityType) {
    case BaseEntityType.CAMPAIGN:
      return {
        value: fieldType as CampaignFieldType,
        case: 'campaignFieldType',
      };
    case BaseEntityType.CREATOR:
      return {
        value: fieldType as CreatorFieldType,
        case: 'creatorFieldType',
      };
    default:
      return {
        value: undefined,
        case: undefined,
      };
  }
}
