<button
  [ngClass]="buttonClass"
  [disabled]="disabled"
  focusableElement
  [class.active]="visible"
  #popupTrigger
>
  <ng-content></ng-content>

  <lefty-icon
    *ngIf="noArrowDropdown === false"
    icon="arrow_drop_down"
  ></lefty-icon>
</button>

<lefty-popup
  [ngClass]="popupClass"
  [placement]="popupPlacement"
  [matchMinSourceWidth]="matchMinSourceWidth"
  [(visible)]="visible"
  [popupTrigger]="popupTrigger"
>
  <lefty-list (click)="close()">
    <ng-content select="[option]"></ng-content>
  </lefty-list>
</lefty-popup>
