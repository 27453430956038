import { Injectable, inject } from '@angular/core';
import { Code } from '@connectrpc/connect';
import { isNil } from '@frontend2/core';
import { LeftyAuthBloc } from './auth.bloc';

enum RestrictionError {
  influencerTrackedLimit = 'influencers_tracked_limited',
  influencerCampaignLimit = 'influencers_in_campaign_limited',
  totalCampaignsLimit = 'total_campaigns_limited',
  runningCampaignsLimit = 'running_campaigns_limited',
  totalAffiliatesLimit = 'total_affiliates_limited',
}

@Injectable({ providedIn: 'root' })
export class LimitationsService {
  readonly auth = inject(LeftyAuthBloc);

  isUnlimited(value: number): boolean {
    return value === -1 || value === Number.POSITIVE_INFINITY;
  }

  normalizeNumber(value?: number): number {
    if (isNil(value)) {
      return 0;
    }
    return (this.isUnlimited(value) ? Number.POSITIVE_INFINITY : value) ?? 0;
  }

  get campaigns(): number {
    return this.normalizeNumber(this.auth.user.limitations?.campaigns);
  }

  get influencersInCampaign(): number {
    return this.normalizeNumber(
      this.auth.user.limitations?.influencersInCampaign,
    );
  }

  get influencersTracked(): number {
    return this.normalizeNumber(
      this.auth.user.limitations?.influencersInCampaign,
    );
  }

  get maxAffiliates(): number {
    return this.normalizeNumber(this.auth.user.limitations?.affiliates);
  }

  get affiliateUsed(): number {
    return this.normalizeNumber(this.auth.user.limitations?.affiliatesUsed);
  }

  get campaignUsed(): number {
    return this.normalizeNumber(this.auth.user.limitations?.campaignsUsed);
  }

  get runningCampaigns(): number {
    return this.normalizeNumber(this.auth.user.limitations?.runningCampaigns);
  }

  canCreateCampaign(): boolean {
    return this.campaigns > this.campaignUsed;
  }

  canAddToCampaigns(numberInfluencers: number): boolean {
    return numberInfluencers <= this.influencersInCampaign;
  }

  canAddAffiliates(affiliateToAdd: number): boolean {
    return affiliateToAdd + this.affiliateUsed <= this.maxAffiliates;
  }

  buildCampaignInfluencersLimitErrorMessage(
    encodedMessage: string | undefined,
  ): string | null {
    if (
      encodedMessage === RestrictionError.influencerCampaignLimit &&
      this.isUnlimited(this.influencersInCampaign) === false
    ) {
      return $localize`You have reached the maximum number (${this.influencersInCampaign}) of influencers in a campaign with your current plan. Please contact your account manager to upgrade.`;
    } else if (
      encodedMessage === RestrictionError.influencerTrackedLimit &&
      this.isUnlimited(this.influencersTracked) === false
    ) {
      return $localize`You have reached the maximum number (${this.influencersTracked}) of influencers you can track simultaneously with your current plan. Please contact your account manager to upgrade.`;
    }

    return null;
  }

  buildCreateCampaignErrorMessage(
    message?: string,
    status?: number,
  ): string | null {
    if (
      RestrictionError.runningCampaignsLimit === message &&
      this.isUnlimited(this.runningCampaigns) === false
    ) {
      return $localize`We're sorry, you cannot have more than ${this.runningCampaigns} simultaneously running campaigns on your plan.`;
    }

    if (
      RestrictionError.totalCampaignsLimit === message &&
      this.isUnlimited(this.runningCampaigns) === false
    ) {
      return $localize`We\'re sorry, you cannot create more than ${this.campaigns} campaigns on your plan.`;
    }

    if (status === Code.AlreadyExists) {
      return $localize`Campaign already exist.`;
    }

    return this.buildCampaignInfluencersLimitErrorMessage(message);
  }
}
