import { Injectable, computed } from '@angular/core';
import { Empty } from '@bufbuild/protobuf';
import { injectLibrarianAffiliationClient } from '@frontend2/api';
import { GetProgramsResponse } from '@frontend2/proto/librarian/proto/affiliation_pb';
import { IframeSyncedCacheBloc } from '@frontend2/ui';
import { injectAlfredEventBus } from './events/events';

@Injectable({ providedIn: 'root' })
export class AffiliationProgramsCache extends IframeSyncedCacheBloc<GetProgramsResponse> {
  private readonly librarianAffiliation = injectLibrarianAffiliationClient();
  readonly alfredEvents = injectAlfredEventBus();

  constructor() {
    super(new GetProgramsResponse());
  }

  readonly hasProgram = computed(() => this.programs().length > 0);

  readonly programs = computed(() => this.cachedData().programs);

  readonly notArchivedPrograms = computed(() =>
    this.programs().filter((p) => p.archived === false),
  );

  override async fetch(): Promise<GetProgramsResponse> {
    return await this.librarianAffiliation.getProgramsAPI(new Empty());
  }

  override get syncName(): string {
    return 'affiliation_programs';
  }
  override convertToJson(obj: GetProgramsResponse): string {
    return JSON.stringify(obj);
  }
  override convertFromJson(jsonString: string): GetProgramsResponse {
    return GetProgramsResponse.fromJsonString(jsonString);
  }
}
