import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Optional,
  Self,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { TimeRestriction } from '@frontend2/proto/librarian/proto/common_pb';
import {
  TimeRestrictionFormBase,
  datesToTimeRestriction,
} from './lefty-date-picker.helpers';
import { LeftyDatePickerComponent } from './lefty-date-picker.component';
import { LeftyFormComponent } from '../lefty-form/lefty-form.component';

@Component({
  selector: 'lefty-date-range-input',
  templateUrl: './lefty-date-range-input.component.html',
  styleUrls: ['./shared.scss', './lefty-date-range-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LeftyFormComponent, LeftyDatePickerComponent],
})
export class LeftyDateRangeInputComponent extends TimeRestrictionFormBase {
  constructor(@Self() @Optional() ngControl?: NgControl) {
    super(ngControl);
  }

  @Input()
  startDatePlaceholder = $localize`Start date`;

  @Input()
  endDatePlaceholder = $localize`End date`;

  private _startDate?: Date;

  set startDate(date: Date | undefined) {
    const newRange = datesToTimeRestriction([date, this.endDate]);
    if (newRange.equals(this.value)) {
      return;
    }

    this.handleValueChange(newRange);
  }

  get startDate(): Date | undefined {
    return this._startDate;
  }

  private _endDate?: Date;

  set endDate(date: Date | undefined) {
    const newRange = datesToTimeRestriction([this.startDate, date]);
    if (newRange.equals(this.value)) {
      return;
    }

    this.handleValueChange(newRange);
  }

  get endDate(): Date | undefined {
    return this._endDate;
  }

  override get value(): TimeRestriction {
    return super.value;
  }

  override set value(value: TimeRestriction) {
    super.value = value;

    if (value.start?.toDate().getTime() !== this._startDate?.getTime()) {
      this._startDate = value.start?.toDate();
    }

    if (value.end?.toDate().getTime() !== this._endDate?.getTime()) {
      this._endDate = value.end?.toDate();
    }
  }
}
