<lefty-form [label]="label">
  <button
    [leftyButton]="buttonType"
    [size]="buttonSize"
    #popupTrigger
    class="selector-btn"
    focusableElement
    [class.active]="popupVisible"
    [disabled]="disabled"
  >
    <div class="button-content">
      <span
        class="placeholder"
        [class.empty]="selection.length === 0"
      >
        @if (selectionCount > 0 && multiSelect === true) {
          <span class="count">({{ selectionCount }})</span>
        }

        {{ buttonText }}
      </span>
    </div>
    <lefty-icon icon="arrow_drop_down"></lefty-icon>
  </button>
</lefty-form>

<lefty-popup
  [placement]="popupPlacement"
  [popupTrigger]="popupTrigger"
  popupClassName="search-and-select-popup"
  [matchMinSourceWidth]="true"
  [visible]="popupVisible"
  (visibleChange)="onVisibleChange($event)"
>
  <div class="popup-content">
    @if (!hideHeader && multiSelect) {
      <div class="header">
        @if (canSelectAll) {
          <span
            class="selector"
            (click)="selectAll()"
            [class.active]="!allSelected"
          >
            {{ Messages.selectAll }}
          </span>
          -
        }
        <span
          class="selector"
          (click)="clearAll()"
          [class.active]="selection.length !== 0"
        >
          {{ Messages.clearAll }}
        </span>
      </div>
    }

    <div class="search-container">
      <lefty-form-input
        leadingGlyph="search"
        (valueChange)="this.searchValue.set($event)"
      >
      </lefty-form-input>
    </div>
    @if (loading()) {
      <lefty-spinner class="spinner"></lefty-spinner>
    }

    @if (showOptions) {
      <lefty-list class="items-list">
        <p
          class="not-found"
          *ngIf="options.length === 0"
          i18n
        >
          not found
        </p>
        @for (item of options; track item.baseSnippet?.userId) {
          <lefty-select-dropdown-item
            (click)="toggleItem(item)"
            [supportMultiSelect]="multiSelect"
            [selected]="isSelected(item)"
            [disabled]="isDisabled(item)"
            [value]="item"
            [itemRenderer]="itemRenderer"
            [closeOnActivate]="false"
            [componentFactory]="componentFactory"
          >
          </lefty-select-dropdown-item>
        }

        <intersection-observer (trigger$)="nextPage()"></intersection-observer>
      </lefty-list>
    }
  </div>
</lefty-popup>
