import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { isNotEmptyString, isNotNil } from '@frontend2/core';
import { DialogBase } from '../lefty-dialog/lefty-dialog.helpers';
import { NgIf } from '@angular/common';
import { LeftyDialogComponent } from '../lefty-dialog/lefty-dialog.component';

@Component({
  selector: 'lefty-contact-us-dialog',
  templateUrl: 'lefty-contact-us-dialog.component.html',
  styleUrls: ['lefty-contact-us-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LeftyDialogComponent, NgIf],
})
export class LeftyContactUsDialogComponent extends DialogBase {
  @Input()
  message = '';

  get hasMessage(): boolean {
    return isNotNil(this.message) && isNotEmptyString(this.message);
  }

  readonly title = $localize`Contact Us`;
}
