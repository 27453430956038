import {
  ChangeDetectionStrategy,
  Component,
  Input,
  output,
} from '@angular/core';
import { Messages, Networks } from '@frontend2/core';
import { Network, PostType } from '@frontend2/proto/common/proto/common_pb';
import { DropdownBase } from '../dropdown';
import {
  ButtonType,
  LeftyButtonDirective,
} from '../lefty-button-directive/lefty-button.directive';
import { NetworksWithPostsTypeListComponent } from './network-with-post-type-list/network-with-post-type-list.component';
import { LeftyPopupComponent } from '../lefty-popup/lefty-popup.component';
import { LeftyIconComponent } from '../icon/icon.component';

@Component({
  selector: 'networks-with-posts-type-selector',
  templateUrl: './network-with-post-type-selector.component.html',
  styleUrls: [
    '../selector/selector.scss',
    './network-with-post-type-selector.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    LeftyButtonDirective,
    LeftyIconComponent,
    LeftyPopupComponent,
    NetworksWithPostsTypeListComponent,
  ],
})
export class NetworksWithPostsTypeSelectorDropdownComponent extends DropdownBase {
  private _placeholder = Messages.allNetworks;

  @Input()
  set placeholder(val: string) {
    this._placeholder = val;
  }

  get placeholder(): string {
    if (this.selection.size === 0) {
      return this._placeholder;
    }

    return Array.from(this.selection.keys())
      .map((e) => this.readableNetwork(e))
      .join(', ');
  }

  @Input()
  selection = new Map<Network, PostType[]>();

  readonly selectionChange = output<Map<Network, PostType[]>>();

  @Input()
  buttonType: ButtonType = 'secondary';

  @Input()
  networks: Network[] = [];

  readableNetwork(network: Network): string {
    return Networks.readable(network);
  }

  setSelection(val: Map<Network, PostType[]>): void {
    this.selection = new Map(val);
    this.selectionChange.emit(this.selection);
  }
}
