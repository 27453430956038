import { InjectionToken, Provider, inject } from '@angular/core';
import { isNotEmptyString } from '@frontend2/core';

const ALFREDO_APP_URL_TOKEN = new InjectionToken<string>('ALFREDO_APP_URL');

const ALFREDO_PROD_APP_URL = 'https://share.lefty.io';
const ALFREDO_STAGING_APP_URL = 'https://share-staging.lefty.io';

export function provideAlfredoAppUrl(isProduction: boolean): Provider {
  return {
    provide: ALFREDO_APP_URL_TOKEN,
    useValue: isProduction ? ALFREDO_PROD_APP_URL : ALFREDO_STAGING_APP_URL,
  };
}

export function injectAlfredoAppUrl(): string {
  return inject(ALFREDO_APP_URL_TOKEN);
}

export const CAMPAIGN_SHARING_TOKEN_KEY = 'share';

export function parseCampaignSharingToken(): string {
  const params = new URLSearchParams(
    window.location.href.slice(window.location.href.indexOf('?')),
  );

  return params.get(CAMPAIGN_SHARING_TOKEN_KEY) ?? '';
}

export function isCampaignSharingApp(): boolean {
  const token = parseCampaignSharingToken();
  const origin = window.location.origin;

  return (
    origin === ALFREDO_PROD_APP_URL ||
    origin === ALFREDO_STAGING_APP_URL ||
    isNotEmptyString(token)
  );
}
