import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LeftyFileInputBase } from './lefty-file-input.base';
import { LeftyProgressComponent } from '../loading.component';
import { NgIf } from '@angular/common';
import { LeftyFormComponent } from '../lefty-form/lefty-form.component';

@Component({
  selector: 'lefty-file-input',
  templateUrl: 'lefty-file-input.component.html',
  styleUrls: ['lefty-file-input.base.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LeftyFormComponent, NgIf, LeftyProgressComponent],
})
export class LeftyFileInputComponent extends LeftyFileInputBase {}
