import {
  ChangeDetectionStrategy,
  Component,
  Input,
  computed,
  signal,
} from '@angular/core';
import { LeftyComponent } from '../utils';
import {
  formatDate,
  getEnumName,
  getEnumValues,
  isAfterDate,
  isNotNil,
} from '@frontend2/core';
import { NgIf, NgFor, NgClass } from '@angular/common';

@Component({
  selector: 'lefty-timeline',
  templateUrl: 'lefty-timeline.component.html',
  styleUrls: ['lefty-timeline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, NgFor, NgClass],
})
export class LeftyTimelineComponent extends LeftyComponent {
  constructor() {
    super();
  }

  _points = signal<TimelinePoint[]>([]);

  showMoreClicked = signal(false);

  isShowMore = computed(
    () => this.showMoreClicked() || this.points.length <= 2,
  );

  @Input()
  set points(val: TimelinePoint[]) {
    this._points.set(this.sortByDate(val));
  }

  get points(): TimelinePoint[] {
    return this._points();
  }

  //to determine the color that is associated with each label, if not set, the default values will be used
  @Input()
  set colorLabelMap(val: Map<string, TimelinePointColors>) {
    this._colorLabelMap = val;
  }

  get colorLabelMap(): Map<string, TimelinePointColors> {
    if (this._colorLabelMap.size === 0) {
      return this.defaultColorMap;
    } else {
      return this._colorLabelMap;
    }
  }

  _colorLabelMap = new Map<string, TimelinePointColors>();

  sortByDate(points: TimelinePoint[]): TimelinePoint[] {
    return points.sort((a, b) => (isAfterDate(a.date, b.date) ? 1 : -1));
  }

  formatDate(date: Date): string {
    return formatDate(date);
  }

  getColorForIndex(index: number): TimelinePointColors {
    const values =
      getEnumValues<TimelinePointColors>(TimelinePointColors).slice(1);
    return values[index % values.length];
  }

  get orderedLabels(): string[] {
    return [...new Set(this.points.map((p) => p.label))];
  }

  get defaultColorMap(): Map<string, TimelinePointColors> {
    const labels = this.orderedLabels;
    const map = new Map<string, TimelinePointColors>();
    for (let i = 0; i < labels.length; i++) {
      const label = labels[i];
      const colorEnum = this.getColorForIndex(i);
      map.set(label, colorEnum);
    }

    return map;
  }

  getColorClass(point: TimelinePoint): string {
    const col = this.colorLabelMap.get(point.label);
    if (isNotNil(col)) {
      return getEnumName(TimelinePointColors, col) ?? '';
    }
    return '';
  }
}

export interface TimelinePoint {
  readonly label: string;
  readonly date: Date;
}

export enum TimelinePointColors {
  white,
  grey,
  yellow,
  teal,
  green,
  red,
}
