import { Injectable, computed } from '@angular/core';
import {
  GiftCreateWithPrefillState,
  SelectedProductsWithVariants,
  createGiftCreateWithPrefillState,
} from './create-gift.models';

import { Int64Value } from '@bufbuild/protobuf';
import { injectLibrarianEcommerceStoresClient } from '@frontend2/api';
import { isGhostEcommerceStoreWithInfoPb } from '@frontend2/proto-helpers/librarian/ecommerce_stores_pb.helpers';
import { CreatorCardSnippet } from '@frontend2/proto/librarian/proto/creators_pb';
import { EcommerceStoreWithInfoPb } from '@frontend2/proto/librarian/proto/ecommerce_stores_pb';
import { DraftGiftPb } from '@frontend2/proto/librarian/proto/gifts_pb';
import { Bloc, injectToastManager, showToastException } from '@frontend2/ui';

//a service that works like a cache to pass on data to 'create gift'

@Injectable({ providedIn: 'root' })
export class CreateGiftService extends Bloc<GiftCreateWithPrefillState> {
  private readonly _librarianEcommerce = injectLibrarianEcommerceStoresClient();
  private readonly _toastManager = injectToastManager();

  constructor() {
    super(createGiftCreateWithPrefillState());
  }

  async setPreselectedProducts(
    products: SelectedProductsWithVariants[],
  ): Promise<void> {
    const storesIds = products.map((p) => p.product.product?.ecommerceStoreId);
    if (storesIds.length !== 1) {
      return;
    } else {
      const store = await this.getStoreById(storesIds[0] ?? BigInt(0));
      if (!isGhostEcommerceStoreWithInfoPb(store)) {
        this.setState(
          createGiftCreateWithPrefillState({
            productSelection: products,
            selectedEcommerceStore: store,
          }),
        );
      }
    }
  }

  setPreselectedInfluencers(influencers: CreatorCardSnippet[]): void {
    this.setState(
      createGiftCreateWithPrefillState({
        influencerSelection: influencers,
      }),
    );
  }

  setSelectedStore(store: EcommerceStoreWithInfoPb): void {
    this.setState(
      createGiftCreateWithPrefillState({
        ...this.state(),
        selectedEcommerceStore: store,
      }),
    );
  }

  setDraftGift(draft: DraftGiftPb): void {
    this.setState(
      createGiftCreateWithPrefillState({
        ...this.state(),
        draftGift: draft,
      }),
    );
  }

  resetPreselections(): void {
    this.setState(createGiftCreateWithPrefillState());
  }

  readonly hasSingleInfluencerPreSelection = computed(
    () => this.state().influencerSelection.length === 1,
  );

  readonly hasInfluencerPreSelection = computed(
    () => this.state().influencerSelection.length > 0,
  );

  readonly hasSelectedStore = computed(
    () => !isGhostEcommerceStoreWithInfoPb(this.state().selectedEcommerceStore),
  );

  async getStoreById(id: bigint): Promise<EcommerceStoreWithInfoPb> {
    try {
      return await this._librarianEcommerce.getEcommerceStoreAPI(
        new Int64Value({ value: id }),
      );
    } catch (e) {
      showToastException(this._toastManager, e);
      return new EcommerceStoreWithInfoPb();
    }
  }
}
