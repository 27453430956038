import { Routes } from '@angular/router';
import { SPACE_HINT_KEY, SPACE_PATH, leftyIsLoggedGuard } from '@frontend2/api';
import { IS_IN_IFRAME, LEFTY_LOGIN_PATH } from '@frontend2/core';
import { alfredLoginGuard } from './login/login.guard';
import { AlfredLoginRouteComponent } from './login/login.route';
import { AlfredResetPasswordRouteComponent } from './reset-password/reset-password.route';
import {
  INVALID_SUBCRIPTION_PATH,
  NOT_FOUND_PATH,
  RESET_PASSWORD_PATH,
  WORKSPACE_SELECTION_PATH,
} from './routes.constants';
import { wildcardPathRedirect } from './wildcard-path.redirect';
import { AlfredWorkspaceSelectionRouteComponent } from './workspace-selection/workspace-selection.route';

export const appRoutes: Routes = [
  {
    path: `${SPACE_PATH}/:${SPACE_HINT_KEY}`,
    loadChildren: () =>
      import('./workspaces/workspaces.module').then((m) => m.WorkspacesModule),
    pathMatch: 'prefix',
    canActivate: [leftyIsLoggedGuard],
  },

  {
    path: LEFTY_LOGIN_PATH,
    component: AlfredLoginRouteComponent,
    canActivate: [alfredLoginGuard],
  },

  {
    path: WORKSPACE_SELECTION_PATH,
    component: AlfredWorkspaceSelectionRouteComponent,
    data: { isInvalidSubscription: false },
    canActivate: [leftyIsLoggedGuard],
  },

  {
    path: INVALID_SUBCRIPTION_PATH,
    component: AlfredWorkspaceSelectionRouteComponent,
    data: { isInvalidSubscription: true },
    canActivate: [leftyIsLoggedGuard],
  },

  {
    path: RESET_PASSWORD_PATH,
    component: AlfredResetPasswordRouteComponent,
  },

  {
    path: NOT_FOUND_PATH,
    loadChildren: () =>
      import('./not-found/not-found.module').then(
        (m) => m.AlfredRouteNotFoundModule,
      ),
  },
];

if (IS_IN_IFRAME === false) {
  appRoutes.push({
    path: '**',
    pathMatch: 'full',
    redirectTo: wildcardPathRedirect,
  });
} else {
  appRoutes.push({
    path: '**',
    loadComponent: () =>
      import('./not-found/iframe-background.route').then(
        (m) => m.AlfredIframeBackgroundRouteComponent,
      ),
    pathMatch: 'full',
    data: {
      notFoundPage: true,
    },
  });
}
