import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LeftyColors, isNotEmptyString, isNotNil } from '@frontend2/core';
import { RendersValue } from '../dynamic-component.component';
import { StatusOption } from './lefty-status-select-dropdown.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'status-dropdown-item',
  template: `
    <div
      *ngIf="hasColor"
      [style.background]="status.color"
      class="dot"
    ></div>
    <span class="label body-1">{{ status.status }}</span>
  `,
  styleUrls: ['./lefty-status-dropdown-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf],
})
export class LeftyStatusDropdownItemComponent
  implements RendersValue<StatusOption>
{
  _status: StatusOption = { status: $localize`No status`, color: '' };
  @Input()
  set status(val: StatusOption | undefined) {
    if (isNotNil(val)) {
      this._status = val;
    } else {
      this._status = { status: $localize`No status`, color: '' };
    }
  }

  get status(): StatusOption {
    return this._status;
  }

  get hasColor(): boolean {
    return isNotEmptyString(this.status.color);
  }

  setValue(newValue?: StatusOption): void {
    this.status = newValue ?? {
      status: 'No status',
      color: LeftyColors.grey080,
    };
  }
}
