<div
  [class.display-none]="navItems.length === 0"
  class="navigation-btn"
  [class.active]="popup.visible"
  #popupTrigger
>
  <lefty-icon icon="more_horiz"></lefty-icon>
</div>

<lefty-popup
  [popupTrigger]="popupTrigger"
  #popup
  [offsetDistance]="5"
  placement="right"
  [showOnHover]="true"
>
  <lefty-list>
    <lefty-list-item
      *ngFor="let nav of navItems"
      [leftyRouterLink]="nav.link"
    >
      <lefty-icon [icon]="nav.icon"></lefty-icon>

      {{ nav.label }}
    </lefty-list-item>
  </lefty-list>
</lefty-popup>
