import { isNotNil, LeftyColors } from '@frontend2/core';
import bb, {
  Axis,
  bubble,
  Data,
  PrimitiveArray,
  TooltipOptions,
} from 'billboard.js';
import { IArcDataRow } from 'billboard.js/src/ChartInternal/data/IData';
import { buildBasicTooltipHTML } from '../tooltip.helpers';
import { BubbleValue, LeftyBubbleChartsOptions } from './bubble.models';

interface PreprocessData {
  readonly colors: Record<string, string>;
  readonly values: Record<string, number>;
  readonly maxValue: number;
  readonly maxY: number;
}

function _preprocessData<Metadata>(
  data: BubbleValue<Metadata>[],
  options?: LeftyBubbleChartsOptions,
): PreprocessData {
  const highlights = options?.highlights ?? [];
  const colors: Record<string, string> = {};
  const values: Record<string, number> = {};

  let maxValue = 0;
  let maxY = 0;

  for (const d of data) {
    maxValue = Math.max(maxValue, d.value);
    maxY = Math.max(maxY, d.y);

    colors[d.name] = _getColorPalette(d, highlights)[1];
    values[d.name] = d.value;
  }

  return { colors, values, maxY, maxValue };
}

function _getMaxBubbleRadius(options?: LeftyBubbleChartsOptions): number {
  return options?.maxR ?? 90;
}

function _calcBubbleSize(
  bubble: BubbleValue<unknown>,
  maxValue: number,
  maxY: number,
): number {
  // Using Billboard js
  // original bubble size calculation is broken for us
  // The best way to render a nice bubble charts, it to use a ratio based on maxY and maxValue in charts
  return (bubble.value / maxValue) * maxY;
}

function _getXName(bubble: BubbleValue<unknown>): string {
  return `${bubble.name}_x`;
}

function _buildXValuesPair(
  data: BubbleValue<unknown>[],
): Record<string, string> {
  const xValues: Record<string, string> = {};

  for (const d of data) {
    xValues[d.name] = _getXName(d);
  }

  return xValues;
}

function _createBillboardBubble(
  bubble: BubbleValue<unknown>,
  maxValue: number,
  maxY: number,
): PrimitiveArray[] {
  const bubbleSize = _calcBubbleSize(bubble, maxValue, maxY);
  const xName = _getXName(bubble);

  return [
    [bubble.name, { y: bubble.y, z: bubbleSize }],

    // specify x value for corresponding value
    // later we pair the xName to the corresponding bubble name (see `_buildXValuesPair`);
    [xName, bubble.x],
  ];
}

function _formatBubbleLabel(
  name: string,
  values: Record<string, number>,
  maxValue: number,
  options?: LeftyBubbleChartsOptions,
): string {
  const highlights = options?.highlights ?? [];

  if (highlights.includes(name) === false) {
    // if value is 75% smaller than maxValue, we don't show label
    // or it will be unreadable
    if (values[name] < maxValue * 0.25) {
      return '';
    }
  }

  return name;
}

function _buildBillboardData<Metadata>(
  data: BubbleValue<Metadata>[],
  options?: LeftyBubbleChartsOptions,
): Data & PreprocessData {
  const chartsData = _preprocessData(data, options);

  const columns = data
    .map((d) => {
      return _createBillboardBubble(d, chartsData.maxValue, chartsData.maxY);
    })
    .flat();

  const xs = _buildXValuesPair(data);

  return {
    ...chartsData,
    columns,
    xs,
    type: bubble(),
    onclick: options?.onBubbleclick,
    labels: {
      format: (val: number, id: string): string => {
        return _formatBubbleLabel(
          id,
          chartsData.values,
          chartsData.maxValue,
          options,
        );
      },
    },
  };
}

function _buildAxisOptions<Metadata>(
  data: BubbleValue<Metadata>[],
  options: LeftyBubbleChartsOptions | undefined,
): Axis {
  // add 20% gap on maxX Axis or bubbles are not fully visible
  let maxX = data.map((d) => d.x).reduce((a, b) => Math.max(a, b));
  maxX = maxX + maxX * 0.2;

  // and 30% gap on maxY Axis
  let maxY = data.map((d) => d.y).reduce((a, b) => Math.max(a, b));
  maxY = maxY + maxY * 0.3;

  const xFormat = options?.xFormatter;
  const yFormat = options?.yFormatter;

  return {
    x: {
      min: 0,
      max: maxX,
      tick: {
        fit: false,
        format: xFormat,
      },
    },
    y: {
      min: 0,
      max: maxY,
      tick: {
        format: yFormat,
        count: 5,
      },
    },
  };
}

function _buildTooltipOptions(
  values: Record<string, number>,
  options: LeftyBubbleChartsOptions | undefined,
): TooltipOptions {
  return {
    show: options?.showTooltip,
    contents: (c: unknown[]): string => {
      const data = c[0] as IArcDataRow;
      const id = data.id;
      const val = values[id];

      const formatter =
        options?.valueTooltipFormatter ?? ((v): string => `${v}`);

      return buildBasicTooltipHTML(data.name ?? '', formatter(val));
    },
  };
}

function _getColorPalette<Metadata>(
  bubble: BubbleValue<Metadata>,
  highlighted: string[],
): string[] {
  if (highlighted.includes(bubble.name)) {
    return LeftyColors.indigo;
  }
  return bubble.colorPalette;
}

function _buildHighlightsCSSStyle(
  name: string,
  colorPalette: string[],
): string {
  return `
      .bb-circles-${name} circle {
        stroke: ${colorPalette[2]} !important;
      }

      .bb-chart-text.bb-target.bb-target-${name} text {
        fill: ${colorPalette[4]} !important;
      }
      `;
}

// Add custom CSS rules to the SVG element
// this is the only solution we found, to support the `Highligts` feature, meaning we want to have
// a different color for a specific bubble
function _addCustomStyles<Metadata>(
  element: HTMLElement,
  data: BubbleValue<Metadata>[],
  options?: LeftyBubbleChartsOptions,
): void {
  element.classList.toggle('lefty-bubble-charts', true);

  const style = data
    .map((d) => {
      const name = d.name.replaceAll(' ', '-');
      const colorPalette = _getColorPalette(d, options?.highlights ?? []);
      return _buildHighlightsCSSStyle(name, colorPalette);
    })
    .join('\n');

  const svg = element.querySelector('svg');
  const defs = svg?.querySelector('defs');

  const styleElement = document.createElement('style');
  styleElement.innerHTML = style;

  defs?.append(styleElement);

  /// Hack !
  /// Move texts section at the end of tree
  /// So text element are draw on top of bubbles not behind
  const main = element.querySelector('svg .bb-main');
  const texts = element.querySelector('svg .bb-main .bb-chart-texts');
  if (isNotNil(texts)) {
    main?.append(texts);
  }
}

export function generateBubbleChart<Metadata>(
  bindto: HTMLElement,
  dataSets: BubbleValue<Metadata>[],
  options?: LeftyBubbleChartsOptions,
): bb.Chart {
  const data = _buildBillboardData(dataSets, options);
  const axis = _buildAxisOptions(dataSets, options);
  const tooltip = _buildTooltipOptions(data.values, options);

  const maxR = _getMaxBubbleRadius(options);

  const charts = bb.generate({
    bindto,
    tooltip,
    axis,
    padding: options?.padding,
    size: options?.size,
    data,
    legend: {
      show: false,
    },
    bubble: {
      maxR,
    },
    resize: {
      auto: true,
    },
    transition: {
      duration: 0,
    },
    grid: {
      y: {
        show: true,
      },
    },
    point: {
      sensitivity: 'radius',
    },
  });

  _addCustomStyles(bindto, dataSets, options);

  return charts;
}
