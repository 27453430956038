import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';

@Component({
  selector: 'lefty-footer',
  template: '<ng-content></ng-content>',
  styleUrls: ['lefty-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class LeftyFooterComponent {
  @HostBinding('class.danger')
  @Input()
  danger = false;
}
