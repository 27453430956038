import { AuthorPermission } from '@frontend2/proto/common/proto/common_pb';
import { CommonFilter } from '@frontend2/proto/librarian/proto/common_pb';
import { getAuthorPermissionFilterValue } from '../../dynamic-filters.helpers';

export function formattedInstaInsightValue(filter: CommonFilter): string {
  const op = getAuthorPermissionFilterValue(filter);

  return readableInstaInsightOperator(op);
}

export function readableInstaInsightOperator(op: AuthorPermission): string {
  switch (op) {
    case AuthorPermission.AUTHORIZED:
      return $localize`shared`;
    case AuthorPermission.UNAUTHORIZED:
      return $localize`not shared`;
    case AuthorPermission.EXPIRED:
      return $localize`expired`;
    default:
      return 'unknown';
  }
}
