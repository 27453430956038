import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Optional,
  Self,
} from '@angular/core';
import { LeftyFormValueBase } from '../form';
import { LeftyColors, hexToInteger } from '@frontend2/core';
import { NgControl } from '@angular/forms';
import { NgFor } from '@angular/common';
import { LeftyFormComponent } from '../lefty-form/lefty-form.component';

@Component({
  selector: 'lefty-color-selector',
  templateUrl: 'lefty-color-selector.component.html',
  styleUrls: ['lefty-color-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LeftyFormComponent, NgFor],
})
export class LeftyColorSelectorComponent extends LeftyFormValueBase<
  number | undefined
> {
  constructor(@Self() @Optional() ngControl?: NgControl) {
    super(undefined, ngControl);
  }

  @Input()
  colors = LeftyColors.gradient50;

  isSelected(color: string): boolean {
    return hexToInteger(color) === this.value;
  }

  override writeValue(color: unknown): void {
    if (typeof color === 'string') {
      this.value = hexToInteger(color);
    } else if (typeof color === 'number') {
      this.value = color;
    } else {
      this.value = undefined;
    }
  }

  select(color: string | number | undefined): void {
    if (typeof color === 'string') {
      this.handleValueChange(hexToInteger(color));
    } else if (typeof color === 'number') {
      this.handleValueChange(color);
    } else {
      this.handleValueChange(undefined);
    }
  }
}
