<button
  #popupTrigger
  leftyButton="secondary"
  leadingIcon="filters"
  i18n
  [size]="buttonSize"
>
  Filters
</button>
<lefty-popup [popupTrigger]="popupTrigger"
  ><lefty-list>
    @if (hasExpandedCategory()) {
      <lefty-list-item
        (click)="goBack()"
        [closeOnActivate]="false"
        class="back-category"
      >
        <lefty-icon
          icon="arrow_right"
          size="medium"
          class="back-icon arrow-icon"
        ></lefty-icon>
        <span class="fill-space category-name">
          {{ currentExpandedCategory().name }}
        </span>
      </lefty-list-item>
    }

    @for (category of visibleCategories(); track category.id) {
      <lefty-list-item
        class="category-items"
        [closeOnActivate]="false"
        (click)="clickedOnCategory(category)"
      >
        <lefty-checkbox
          (checkedChange)="selectCategory($event, category)"
          [checked]="isSelected(category)"
          (click)="checkboxClicked($event)"
        ></lefty-checkbox>

        <span class="fill-space category-name">{{ category.name }}</span>

        @if (hasSubcategory(category)) {
          <lefty-icon
            icon="arrow_right"
            size="medium"
            class="arrow-icon"
          ></lefty-icon>
        }
      </lefty-list-item>
    }
  </lefty-list>
</lefty-popup>
