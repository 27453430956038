import { environment } from '../environments/environment';

export const HOME_DASHBOARD_PATH = 'home';

export const PRODUCT_SEEDING_PATH = 'products';
export const INVENTORY_PATH = 'inventory';
export const PRODUCT_CREATION_PATH = 'create_product';
export const PRODUCT_EDIT_PATH = 'edit_product';
export const PRODUCT_DETAILS_KEY = 'productId';

export const PRODUCT_SEEDING_GIFTS_PATH = 'gifts';
export const CREATE_PRODUCT_GIFT_PATH = 'create_gift';
export const GIFT_DETAILS_KEY = 'giftId';
export const GIFT_DRAFT_KEY = 'draftId';

export const PRODUCT_SEEDING_STORES_PATH = 'stores';

export const PRODUCT_SEEDING_REQUESTS_PATH = 'requests';
export const SEND_PRODUCT_REQUEST_PATH = 'send_request';
export const PRODUCT_REQUEST_DETAILS_KEY = 'requestId';

export const PAYMENTS_PATH = 'payments';
export const PAYMENT_METHODS_PATH = 'payment_methods';
export const PAYMENT_DETAILS_KEY = 'paymentId';
export const HISTORY_PATH = 'history';

export const ACCOUNT_PATH = 'account';
export const USER_PERMISSIONS_PATH = 'permissions';
export const CPM_PATH = 'cpm';
export const PROFILE_PATH = 'profile';
export const NOTIFICATION_PATH = 'preferences';
export const BILLING_PLAN_PATH = 'plan';
export const BRAND_SETTINGS_PATH = 'brand';
export const LABELS_PATH = 'labels';
export const INFLUENCER_LABEL_PATH = `creators`;
export const POST_LABELS_PATH = `posts`;
export const INFLUENCERS_RECRUITED_PATH = 'recruited';
export const CAMPAIGN_LABEL_PATH = `campaigns`;
export const CUSTOM_FIELDS_PATH = 'fields';
export const INFLUENCER_CUSTOM_FIELDS_PATH = `creators`;
export const CAMPAIGN_CUSTOM_FIELDS_PATH = `campaigns`;
export const INVOICES_PATH = 'invoices';
export const ADD_OR_EDIT_BRAND = 'add_edit';
export const BRAND_DETAILS_KEY = 'brandId';
export const PRIMARY_BRAND_KEY = 'primary';
export const INTEGRATIONS_PATH = 'integrations';
export const POSTS = 'posts';
export const REPORT = 'report';
export const MESSAGES = 'messages';

export const CAMPAIGNS_PATH = 'campaigns';
export const AFFILIATION_PATH = 'affiliates';
export const DIRECTORY_PATH = 'directory';
export const CASTINGS_PATH = 'castings';
export const CHAT_PATH = 'chat';
export const DISCOVER_PATH = 'discover';
export const CW_REPORTS_PATH = 'markets';
export const CW_BRANDS_LIST_PATH = 'brands';
export const CW_BRAND_STATS_PATH = 'statistics';
export const SETTINGS_PATH = 'settings';
export const CANDIDATES_PATH = 'candidates';
export const CREATE_PATH = 'create';
export const CREATE_REPORT_PATH = 'create-report';
export const STATS_PATH = 'stats';
export const EDIT_PATH = 'edit';

export const AFFILIATES_PATH = 'creators';
export const AFFILIATION_LINKS_PATH = 'link';
export const AFFILIATION_PROMO_CODES_PATH = 'promo_codes';
export const AFFILIATION_CONVERSIONS_PATH = 'orders';
export const AFFILIATION_REPORT_PATH = 'report';
export const AFFILIATION_DESTINATION_URLS_PATH = 'url_destinations';
export const AFFILIATION_PROGRAMS_PATH = 'programs';
export const AFFILIATION_PIXELS_PATH = 'pixels';

export const PROMO_CODE_ID_KEY = 'promoCodeId';
export const ADD_PROMO_CODE_PATH = 'add_promo_code';
export const EDIT_PROMO_CODE_PATH = 'edit_promo_code';
export const PROMO_CODE_DETAILS_KEY = 'promoCodeId';

export const ALL_PATH = 'all';
export const PENDING_PATH = 'pending';
export const APPROVED_PATH = 'approved';
export const REJECTED_PATH = 'rejected';
export const SENT_PATH = 'sent';

export const LANDING_PAGE_PATH = 'landing_pages';
export const CASTING_ID_KEY = 'castingId';
export const COLLABORATORS_PATH = 'collaborators';
export const CREATORS_PATH = 'creators';
export const FORECAST_PATH = 'forecast';

export const LANDING_PAGE_ID_KEY = 'landingId';
export const NESTOR_URL =
  environment.isProduction === true
    ? 'https://creators.lefty.io'
    : 'https://talent-staging.lefty.io';

export const NESTOR_LANDING_PATH = 'landing';

export const INVALID_SUBCRIPTION_PATH = 'invalid_subscription';

export const NOT_FOUND_PATH = 'not_found';
export const STATUS_KEY = 'status';
export const DATE_START_KEY = 'start';
export const DATE_END_KEY = 'end';
export const DATES_KEY = 'dates';
export const TRACKING_STATUS_KEY = 'trackingStatus';
export const CAMPAIGN_TYPE = 'campaignType';

export const LABELS_KEY = 'labels';
export const EMAIL_KEY = 'email';
export const SORT_KEY = 'sort';
export const NAME_KEY = 'name';
export const METRIC_KEY = 'metric';
export const SPLIT_BY_KEY = 'splitBy';
export const TOP_INFLUENCERS_BY_KEY = 'topInfluencersBy';
export const TOP_POSTS_BY_KEY = 'topPostsBy';

export const SORT_DIRECTION_KEY = 'direction';
export const SHORT_SORT_DIRECTION_KEY = 'dir';

export const OWNER_KEY = 'owner';

export const USERNAME_OR_URL_KEY = 'username_or_url';

export const RESOLVE_URL = 'resolve';
export const CREATORS_URL = 'creators';

export const WORKSPACE_PATH = 's';

export const TOKEN_KEY = 'token';

export const HELP_URL = 'https://help.lefty.io';
export const OLYMPE_PROD_URL = 'https://dashboard.lefty.io';
export const OLYMPE_STAGING_URL = 'https://dashboard-staging.lefty.io';
export const CAMPAIGN_ID_KEY = 'campaignId';
export const REPORT_ID_KEY = 'marketId';
export const RESET_PASSWORD_PATH = 'reset_password';
export const WORKSPACE_SELECTION_PATH = 'spaces';

export const SHOPIFY_INTEGRATION_URL = 'https://apps.shopify.com/lefty';
export const RFM_SCORE_HELPER_LINK =
  'https://help.lefty.io/en/articles/7169282-rfm-scores-explained';

export const HELP_CAMPAIGN_SETUP_URL =
  'https://help.lefty.io/en/articles/8958948-campaign-setup-management-best-practices';

export const SALESFORCE_INTEGRATION_GUIDE_URL =
  'https://help.lefty.io/en/articles/9374533-product-seeding-salesforce-commerce-cloud-integration';
export const SALESFORCE_ACCOUNT_MANAGER_URL =
  'https://account.demandware.com/dw/account/Home#!/';
