<lefty-form
  [label]="label"
  [optional]="optional"
  [required]="required"
  [helpText]="helpText"
  [helpLink]="helpLink"
  [tooltip]="tooltip"
  [errorMessage]="errorMessage"
  [large]="large"
>
  <div class="toggle-container">
    <p class="hint-text">
      {{ hintText }}
    </p>

    <ng-content select="[buttonState]"></ng-content>

    <lefty-toggle
      focusableElement
      [size]="size"
      [checked]="value"
      (checkedChange)="handleValueChange($event)"
      (focus)="handleFocus($event)"
      (blur)="handleBlur($event)"
    >
    </lefty-toggle>
  </div>
</lefty-form>
