import {
  ChangeDetectionStrategy,
  Component,
  Input,
  signal,
} from '@angular/core';
import { isNotEmptyString } from '@frontend2/core';
import { LeftyIconComponent } from '../../icon/icon.component';
import { LeftyCardComponent } from '../lefty-card.component';

@Component({
  selector: 'expandable-lefty-card',
  templateUrl: './expandable-lefty-card.component.html',
  styleUrls: ['../expandable-shared.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LeftyCardComponent, LeftyIconComponent],
})
export class ExpandableLeftyCardComponent {
  @Input()
  alwaysExtended = false;

  @Input()
  titleIcon = '';

  @Input()
  title = '';

  @Input()
  description = '';

  @Input()
  noPaddingOnContent = false;

  @Input()
  compactPaddingOnContent = false;

  @Input()
  compactPaddingOnHeader = false;

  readonly expanded = signal(false);

  get mustShowContent(): boolean {
    return this.alwaysExtended || this.expanded();
  }

  get hasTitleIcon(): boolean {
    return isNotEmptyString(this.titleIcon);
  }

  get hasDescription(): boolean {
    return isNotEmptyString(this.description);
  }

  toggleExpand(): void {
    if (this.alwaysExtended) {
      return;
    }
    this.expanded.set(!this.expanded());
  }
}
