import { getProductVariantName, isNil, isNotEmptyArray } from '@frontend2/core';
import { CreatorCardSnippet } from '@frontend2/proto/librarian/proto/creators_pb';
import { EcommerceStoreWithInfoPb } from '@frontend2/proto/librarian/proto/ecommerce_stores_pb';
import {
  DraftGiftPb,
  DraftGiftPb_DraftGiftInfluencer,
  GetInfluencerAddressesResponse,
  GetInfluencerAddressesResponse_InfluencerAddress,
} from '@frontend2/proto/librarian/proto/gifts_pb';
import {
  ProductVariantPb,
  ProductWithInfoPb,
} from '@frontend2/proto/librarian/proto/products_pb';

export interface GiftCreateWithPrefillState {
  readonly productSelection: SelectedProductsWithVariants[];
  readonly influencerSelection: CreatorCardSnippet[];
  readonly selectedEcommerceStore: EcommerceStoreWithInfoPb;
  readonly draftGift: DraftGiftPb;
}

export function createGiftCreateWithPrefillState(
  partial?: Partial<GiftCreateWithPrefillState>,
): GiftCreateWithPrefillState {
  return {
    productSelection: partial?.productSelection ?? [],
    influencerSelection: partial?.influencerSelection ?? [],
    selectedEcommerceStore:
      partial?.selectedEcommerceStore ?? new EcommerceStoreWithInfoPb(),
    draftGift: partial?.draftGift ?? new DraftGiftPb(),
  };
}

export interface VariantQuantityInfo {
  variant: ProductVariantPb;
  quantity: number;
}

export function createVariantQuantityInfo(
  partial?: Partial<VariantQuantityInfo>,
): VariantQuantityInfo {
  return {
    variant: partial?.variant ?? new ProductVariantPb(),
    quantity: partial?.quantity ?? 0,
  };
}

export interface SelectedProductsWithVariants {
  readonly product: ProductWithInfoPb;
  readonly productId: bigint;
  readonly variants: VariantQuantityInfo[];
  readonly type: 'SelectedProductsWithVariants';
}

export function createSelectedProductsWithVariants(
  partial?: Partial<SelectedProductsWithVariants>,
): SelectedProductsWithVariants {
  return {
    product: partial?.product ?? new ProductWithInfoPb(),
    productId: partial?.product?.product?.id ?? partial?.productId ?? BigInt(0),
    variants: partial?.variants ?? [],
    type: 'SelectedProductsWithVariants',
  };
}

export function isSelectedProductsWithVariants(
  obj: unknown,
): obj is SelectedProductsWithVariants {
  if (typeof obj !== 'object' || isNil(obj)) {
    return false;
  }

  return 'type' in obj && obj.type === 'SelectedProductsWithVariants';
}

export interface SelectedInfluencerWithInfos {
  readonly influencer: CreatorCardSnippet;
  readonly contactInfos: GetInfluencerAddressesResponse;
  readonly trackingLink: string;
  readonly selectedAddress?: GetInfluencerAddressesResponse_InfluencerAddress;
}

export function createSelectedInfluencerWithInfos(
  partial?: Partial<SelectedInfluencerWithInfos>,
): SelectedInfluencerWithInfos {
  return {
    influencer: partial?.influencer ?? new CreatorCardSnippet(),
    contactInfos: partial?.contactInfos ?? new GetInfluencerAddressesResponse(),
    selectedAddress: partial?.selectedAddress,
    trackingLink: partial?.trackingLink ?? '',
  };
}

export function draftGiftInfluencerToSelectedInfluencerWithInfos(
  influencer: DraftGiftPb_DraftGiftInfluencer,
): SelectedInfluencerWithInfos {
  return createSelectedInfluencerWithInfos({
    influencer: new CreatorCardSnippet({
      userId: influencer.influencerId,
      userName: influencer.influencerName,
      profilePic: influencer.profilePicUrl,
    }),
    trackingLink: influencer.trackingLink,
    selectedAddress: new GetInfluencerAddressesResponse_InfluencerAddress({
      addresses: influencer.address,
    }),
  });
}

export function getSelectedProductsWithVariantsLabel(
  productWithVariant: SelectedProductsWithVariants,
  variant: VariantQuantityInfo,
): string {
  const variants = productWithVariant.product.product?.variants ?? [];
  const variantsWithAttributes = variants.filter((v) =>
    isNotEmptyArray(v.attributes),
  );
  if (isNotEmptyArray(variantsWithAttributes)) {
    return `${variant.variant?.productName ?? ''} (${getProductVariantName(variant.variant ?? new ProductVariantPb())})`;
  } else {
    return variant.variant?.productName ?? '';
  }
}
