<lefty-form-select
  [buttonClass]="buttonClass"
  [placeholder]="placeholder"
  [buttonText]="currencyCode(selectedCurrency)"
  [selection]="selectedCurrency"
  (selectionChange)="handleValueChange($event)"
  [options]="options()"
  [itemRenderer]="currencyCode"
  [disabled]="disabled"
  [popupMatchInputWidth]="true"
  [withFooter]="!showMoreCurrencies()"
  [label]="label"
>
  <div
    footer
    *ngIf="showMoreCurrencies() === false"
    (click)="showMore()"
  >
    <span
      class="body-1 link"
      i18n
    >
      More currencies
    </span>
  </div>
</lefty-form-select>
