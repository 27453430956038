import {
  ChangeDetectionStrategy,
  Component,
  Input,
  computed,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { NavigationEnd } from '@angular/router';
import { filter, map } from 'rxjs';
import { injectRouter } from '../inject.helpers';
import {
  TooltipPlacement,
  LeftyTooltipDirective,
} from '../lefty-tooltip/lefty-tooltip.directive';
import {
  NavItem,
  buildNavItem,
  LeftyRouterLinkDirective,
  LeftyRouterLinkActiveDirective,
} from '../router-utils';
import { LeftyIconComponent } from '../icon/icon.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'lefty-nav-link',
  templateUrl: './lefty-navigation-link.component.html',
  styleUrls: ['./lefty-navigation-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    LeftyRouterLinkDirective,
    LeftyRouterLinkActiveDirective,
    LeftyTooltipDirective,
    LeftyIconComponent,
  ],
})
export class LeftyNavigationLinkComponent {
  @Input()
  config: NavItem = buildNavItem('', '');

  @Input()
  tooltipPlacement: TooltipPlacement = 'is-right';

  readonly router = injectRouter();

  isGhostNavItem(nav: NavItem): boolean {
    return nav.label === '' && nav.link === '';
  }

  isActiveForRoutes(currentUrl: string, matchUrls: string[]): boolean {
    return matchUrls.some((s) => currentUrl.includes(s));
  }

  readonly currentUrl = toSignal(
    this.router.events.pipe(
      takeUntilDestroyed(),
      filter((event) => event instanceof NavigationEnd),
      map((event) => (event as NavigationEnd).url),
    ),
    {
      initialValue: this.router.url,
    },
  );

  readonly isActive = computed(
    () =>
      this.config.isActive ||
      this.isActiveForRoutes(
        this.currentUrl(),
        this.config.activeForRoutes ?? [],
      ),
  );
}
