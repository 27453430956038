import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject,
  signal,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StringValue } from '@bufbuild/protobuf';
import {
  LeftyAuthBloc,
  injectLibrarianFrontendMiscClient,
} from '@frontend2/api';
import {
  LEFTY_LOGO,
  isEmptyString,
  isNotEmptyString,
  isNotNil,
} from '@frontend2/core';
import { ResetPasswordRequest } from '@frontend2/proto/librarian/proto/frontend_misc_pb';
import {
  injectRouter,
  injectToastManager,
  showToastException,
} from '@frontend2/ui';
import { TOKEN_KEY } from '../routes.constants';

@Component({
  selector: 'alfred-reset-password-route',
  templateUrl: './reset-password.route.html',
  styleUrls: ['./reset-password.route.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlfredResetPasswordRouteComponent implements OnInit {
  private readonly route = inject(ActivatedRoute);
  private readonly frontendMiscLibrarian = injectLibrarianFrontendMiscClient();
  private readonly toastManager = injectToastManager();
  private readonly auth = inject(LeftyAuthBloc);
  private readonly router = injectRouter();

  readonly leftyLogoSrc = LEFTY_LOGO;

  readonly token = signal('');

  readonly hasInvalidToken = signal(false);

  readonly resetPasswordState = signal(ResetPasswordState.enterEmail);

  readonly loading = signal(false);

  private async _tokenIsValid(token: string): Promise<boolean> {
    return (
      await this.frontendMiscLibrarian.isResetPasswordTokenValidAPI(
        new StringValue({ value: token }),
      )
    ).value;
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(async (p) => {
      this.token.set(p[TOKEN_KEY]);
      if (isNotNil(this.token()) && isNotEmptyString(this.token())) {
        const isValid = await this._tokenIsValid(this.token());
        this.hasInvalidToken.set(!isValid);
        if (isValid) {
          this.resetPasswordState.set(ResetPasswordState.enterNewPassword);
        }
      }
    });
  }

  async emailSubmitted(email: string): Promise<void> {
    try {
      this.loading.set(true);
      await this.frontendMiscLibrarian.sendResetPasswordLinkAPI(
        new StringValue({ value: email }),
      );
      this.resetPasswordState.set(ResetPasswordState.gotoInbox);
    } catch (e) {
      showToastException(this.toastManager, e);
    } finally {
      this.loading.set(false);
    }
  }

  async newPasswordSubmitted(password: string): Promise<void> {
    if (this.hasInvalidToken() || isEmptyString(this.token())) {
      return;
    }
    try {
      this.loading.set(true);
      await this.frontendMiscLibrarian.resetPasswordAPI(
        new ResetPasswordRequest({ token: this.token(), password: password }),
      );
      this.auth.checkIsLogged();
      this.resetPasswordState.set(ResetPasswordState.passwordChanged);
    } catch (e) {
      showToastException(this.toastManager, e);
    } finally {
      this.loading.set(false);
    }
  }

  goToHome(): void {
    this.router.navigateByUrl('/');
  }
}

export enum ResetPasswordState {
  enterEmail,
  gotoInbox,
  enterNewPassword,
  passwordChanged,
}
