import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  Type,
} from '@angular/core';

import { isNotNil } from '@frontend2/core';
import { LeftyComponent, createOutput } from '../utils';
import { LeftyStatusDropdownItemComponent } from './lefty-status-dropdown-item.component';
import { LeftyButtonDirective } from '../lefty-button-directive/lefty-button.directive';
import { LeftyFormSelectComponent } from '../lefty-form-select/lefty-form-select.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'lefty-status-select',
  templateUrl: 'lefty-status-select-dropdown.component.html',
  styleUrls: ['lefty-status-select-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    LeftyFormSelectComponent,
    LeftyStatusDropdownItemComponent,
    LeftyButtonDirective,
  ],
})
export class LeftyStatusSelectComponent extends LeftyComponent {
  @Input()
  buttonClass = 'ghost';

  @Input()
  options: StatusOption[] = [];

  @Input()
  selection?: StatusOption;

  @Input()
  disabled = false;

  @Input()
  placeholder = 'No Status';

  @Output()
  readonly selectionChange$ = createOutput<StatusOption>();

  StatusItemFactory(): Type<unknown> {
    return LeftyStatusDropdownItemComponent;
  }

  itemRenderer(item: StatusOption): string {
    return item.status;
  }

  get hasValue(): boolean {
    return isNotNil(this.selection);
  }
}

export interface StatusOption {
  readonly status: string;
  readonly color: string;
}
