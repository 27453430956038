<lefty-form
  [label]="label"
  [required]="required"
  [optional]="optional"
  [helpText]="helpText"
  [externalHelpLink]="externalHelpLink"
  [helpLink]="helpLink"
  [hintText]="hintText"
  [errorMessage]="errorMessage"
  [tooltip]="tooltip"
  [large]="large"
  [topHintText]="topHintText"
>
  <div class="colors">
    <div
      class="color"
      *ngFor="let color of colors"
      [class.selected]="isSelected(color)"
      [style.background-color]="color"
      (click)="select(color)"
    ></div>
  </div>
</lefty-form>
