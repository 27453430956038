<div class="form-input">
  <button
    [leftyButton]="buttonType"
    #popupTrigger
    focusableElement
    class="selector-btn"
  >
    <div class="button-content">
      <span
        class="placeholder"
        [class.empty]="selection.size === 0"
      >
        @if (selection.size !== 0) {
          <span class="count">({{ selection.size }})</span>
        }

        {{ placeholder }}
      </span>
    </div>
    <lefty-icon icon="arrow_drop_down"></lefty-icon>
  </button>

  <lefty-popup
    [placement]="popupPlacement"
    [popupTrigger]="popupTrigger"
  >
    <div class="flex column">
      <networks-with-posts-type-list
        (selectionChange)="setSelection($event)"
        [networks]="networks"
        [selection]="selection"
        [withSelectAll]="true"
      ></networks-with-posts-type-list>
    </div>
  </lefty-popup>
</div>
