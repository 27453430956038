@if (withSelectAll) {
  <div class="header">
    <span
      (click)="selectAll()"
      i18n
      class="header-button active body-1"
    >
      Select all
    </span>
    -

    <span
      leftyButton="plain"
      (click)="clearAll()"
      class="header-button body-1"
      [class.active]="selection.size !== 0"
    >
      Clear all
    </span>
  </div>
}

<lefty-list class="networks-list">
  <!-- FIXME: avoid tracking $index on filterable list-->
  @for (item of options; track $index) {
    <div [class.active]="networkIsUnfolded(item[0])">
      <lefty-list-item [closeOnActivate]="false">
        <div class="network-item">
          <lefty-checkbox
            [checked]="isNetworkSelected(item[0])"
            (click)="toggleNetwork(item[0])"
          ></lefty-checkbox>
          <div
            (click)="toggleFold(item[0])"
            class="flex fill-space"
          >
            <div class="network fill-space">
              <network-icon [network]="item[0]"></network-icon>
              <div>{{ readableNetwork(item[0]) }}</div>
            </div>
            @if (networkIsFoldable(item[0])) {
              <lefty-icon
                class="fold-toggle"
                icon="arrow_drop_down"
              ></lefty-icon>
            }
          </div>
        </div>
      </lefty-list-item>

      @if (networkIsUnfolded(item[0])) {
        <div class="post-type">
          @for (postType of item[1]; track $index) {
            <lefty-list-item
              [closeOnActivate]="false"
              (trigger$)="togglePostType(postType)"
            >
              <div class="item flex row vCenter">
                <lefty-checkbox [checked]="isPostTypeSelected(postType)">
                </lefty-checkbox>
                @if (hasIcon(postType)) {
                  <lefty-icon [icon]="postTypeIcon(postType)"></lefty-icon>
                }

                <div class="post-type-name body-1">
                  {{ readablePostType(postType) }}
                </div>
              </div>
            </lefty-list-item>
          }
        </div>
      }
    </div>
  }
</lefty-list>
