import { Router } from '@angular/router';
import { IS_IN_IFRAME, isNil } from '@frontend2/core';
import {
  defaultSpaceIdxFromBootstrap,
  hintIsValid,
  userMustPay,
} from '@frontend2/proto-helpers/librarian/frontend_misc_pb.helpers';
import { Feature } from '@frontend2/proto/librarian/proto/common_pb';
import { LoggedBootstrapping } from '@frontend2/proto/librarian/proto/frontend_misc_pb';
import { RouteCommand } from '@frontend2/ui';
import {
  ADD_PROMO_CODE_PATH,
  AFFILIATION_PATH,
  AFFILIATION_PROMO_CODES_PATH,
  ALL_PATH,
  CAMPAIGNS_PATH,
  CANDIDATES_PATH,
  CASTINGS_PATH,
  CHAT_PATH,
  CREATE_PATH,
  CREATE_PRODUCT_GIFT_PATH,
  CREATE_REPORT_PATH,
  CREATORS_URL,
  CW_BRAND_STATS_PATH,
  CW_BRANDS_LIST_PATH,
  CW_REPORTS_PATH,
  DIRECTORY_PATH,
  DISCOVER_PATH,
  EDIT_PROMO_CODE_PATH,
  HOME_DASHBOARD_PATH,
  INFLUENCERS_RECRUITED_PATH,
  INVALID_SUBCRIPTION_PATH,
  INVENTORY_PATH,
  LANDING_PAGE_PATH,
  NOT_FOUND_PATH,
  PAYMENTS_PATH,
  POSTS,
  PRODUCT_CREATION_PATH,
  PRODUCT_EDIT_PATH,
  PRODUCT_SEEDING_GIFTS_PATH,
  PRODUCT_SEEDING_PATH,
  PRODUCT_SEEDING_REQUESTS_PATH,
  SEND_PRODUCT_REQUEST_PATH,
  SETTINGS_PATH,
  WORKSPACE_PATH,
  WORKSPACE_SELECTION_PATH,
} from './routes.constants';

export function workspaceRouteCommand(workspaceHint: number): RouteCommand[] {
  return ['/', WORKSPACE_PATH, workspaceHint];
}

export function landingPageRouteCommand(
  workspaceHint: number,
  landingId: bigint,
): RouteCommand[] {
  return [
    ...workspaceRouteCommand(workspaceHint),
    LANDING_PAGE_PATH,
    landingId,
  ];
}

export function campaignSettingsRouteCommand(
  workspaceHint: number,
  campaignId: bigint,
): RouteCommand[] {
  return [
    ...workspaceRouteCommand(workspaceHint),
    CAMPAIGNS_PATH,
    campaignId,
    SETTINGS_PATH,
  ];
}

export function campaignRouteCommand(
  workspaceHint: number,
  campaignId: bigint,
): RouteCommand[] {
  return [
    ...workspaceRouteCommand(workspaceHint),
    CAMPAIGNS_PATH,
    campaignId,
    INFLUENCERS_RECRUITED_PATH,
  ];
}

export function campaignShareRouteCommand(
  workspaceHint: number,
  campaignId: bigint,
): RouteCommand[] {
  return [
    ...workspaceRouteCommand(workspaceHint),
    CAMPAIGNS_PATH,
    campaignId,
    INFLUENCERS_RECRUITED_PATH,
  ];
}

export function campaignCreateRouteCommand(
  workspaceHint: number,
): RouteCommand[] {
  return [...workspaceRouteCommand(workspaceHint), CAMPAIGNS_PATH, CREATE_PATH];
}

export function campaignListRouteCommand(
  workspaceHint: number,
): RouteCommand[] {
  return [...workspaceRouteCommand(workspaceHint), CAMPAIGNS_PATH];
}

export function productRouteCommand(workspaceHint: number): RouteCommand[] {
  return [...workspaceRouteCommand(workspaceHint), PRODUCT_SEEDING_PATH];
}

export function productInventoryRouteCommand(
  workspaceHint: number,
): RouteCommand[] {
  return [
    ...workspaceRouteCommand(workspaceHint),
    PRODUCT_SEEDING_PATH,
    INVENTORY_PATH,
  ];
}

export function productRequestsRouteCommand(
  workspaceHint: number,
): RouteCommand[] {
  return [
    ...workspaceRouteCommand(workspaceHint),
    PRODUCT_SEEDING_PATH,
    PRODUCT_SEEDING_REQUESTS_PATH,
  ];
}

export function sendProductRequestRouteCommand(
  workspaceHint: number,
  requestId: string,
): RouteCommand[] {
  return [
    ...workspaceRouteCommand(workspaceHint),
    SEND_PRODUCT_REQUEST_PATH,
    requestId,
  ];
}

export function productCreateOrEditRouteCommand(
  workspaceHint: number,
  productId?: bigint,
): RouteCommand[] {
  return isNil(productId)
    ? [...workspaceRouteCommand(workspaceHint), PRODUCT_CREATION_PATH]
    : [...workspaceRouteCommand(workspaceHint), PRODUCT_EDIT_PATH, productId];
}

export function directoryProfileCommand(
  workspaceHint: number,
  influencerId?: string,
): RouteCommand[] {
  return isNil(influencerId)
    ? [...workspaceRouteCommand(workspaceHint), DIRECTORY_PATH, CREATORS_URL]
    : [
        ...workspaceRouteCommand(workspaceHint),
        DIRECTORY_PATH,
        CREATORS_URL,
        influencerId,
      ];
}

export function buildProfileInDirectoryUrl(
  router: Router,
  workspaceHint: number,
  influencerId: string | undefined,
): string {
  const tree = router.createUrlTree(
    directoryProfileCommand(workspaceHint, influencerId),
  );
  return router.serializeUrl(tree);
}

export function directoryPostCommand(
  workspaceHint: number,
  network: string,
): RouteCommand[] {
  return [
    ...workspaceRouteCommand(workspaceHint),
    DIRECTORY_PATH,
    POSTS,
    network,
  ];
}

export function createGiftRouteCommand(workspaceHint: number): RouteCommand[] {
  return [...workspaceRouteCommand(workspaceHint), CREATE_PRODUCT_GIFT_PATH];
}

export function giftsListRouteCommand(workspaceHint: number): RouteCommand[] {
  return [
    ...workspaceRouteCommand(workspaceHint),
    PRODUCT_SEEDING_PATH,
    PRODUCT_SEEDING_GIFTS_PATH,
  ];
}

export function createLandinPageRouteCommand(
  workspaceHint: number,
): RouteCommand[] {
  return [
    ...workspaceRouteCommand(workspaceHint),
    LANDING_PAGE_PATH,
    CREATE_PATH,
  ];
}

export function createLandingPageCandidatesRouteCommand(
  workspaceHint: number,
  landingId: bigint,
): RouteCommand[] {
  return [
    ...landingPageRouteCommand(workspaceHint, landingId),
    CANDIDATES_PATH,
    ALL_PATH,
  ];
}

export function createLandingPageSettingsRouteCommand(
  workspaceHint: number,
  landingId: bigint,
): RouteCommand[] {
  return [...landingPageRouteCommand(workspaceHint, landingId), SETTINGS_PATH];
}

export function redirectAfterLoginRouteCommand(
  bootstrap: LoggedBootstrapping,
): RouteCommand[] {
  const spaces = bootstrap.customerSpaces;
  const currentHint = bootstrap.spaceIdxHint;
  const currentHintValid = hintIsValid(bootstrap, currentHint);

  if (spaces.length > 1) {
    return [WORKSPACE_SELECTION_PATH];
  } else if (currentHintValid === false) {
    return [...workspaceRouteCommand(defaultSpaceIdxFromBootstrap(bootstrap))];
  } else if (currentHintValid && userMustPay(bootstrap)) {
    return [INVALID_SUBCRIPTION_PATH];
  } else {
    return [...workspaceRouteCommand(currentHint)];
  }
}

export function affiliationRouteCommand(workspaceHint: number): RouteCommand[] {
  return [...workspaceRouteCommand(workspaceHint), AFFILIATION_PATH];
}

export function cwRouteCommand(workspaceHint: number): RouteCommand[] {
  return [...workspaceRouteCommand(workspaceHint), CW_REPORTS_PATH];
}

export function directoryRouteCommand(workspaceHint: number): RouteCommand[] {
  return [...workspaceRouteCommand(workspaceHint), DIRECTORY_PATH];
}

export function discoverRouteCommand(workspaceHint: number): RouteCommand[] {
  return [...workspaceRouteCommand(workspaceHint), DISCOVER_PATH];
}

export function chatRouteCommand(workspaceHint: number): RouteCommand[] {
  return [...workspaceRouteCommand(workspaceHint), CHAT_PATH];
}

export function paymentsRouteCommand(workspaceHint: number): RouteCommand[] {
  return [...workspaceRouteCommand(workspaceHint), PAYMENTS_PATH];
}

export function homeDashboardRouteCommand(
  workspaceHint: number,
): RouteCommand[] {
  return [...workspaceRouteCommand(workspaceHint), HOME_DASHBOARD_PATH];
}

export function castingsListRouteCommand(
  workspaceHint: number,
): RouteCommand[] {
  return [...workspaceRouteCommand(workspaceHint), CASTINGS_PATH];
}

export function castingRouteCommand(
  workspaceHint: number,
  castingId: bigint,
): RouteCommand[] {
  return [...castingsListRouteCommand(workspaceHint), castingId];
}

export function castingSettingsRouteCommand(
  workspaceHint: number,
  castingId: bigint,
): RouteCommand[] {
  return [...castingRouteCommand(workspaceHint, castingId), SETTINGS_PATH];
}

export function landingPagesListRouteCommand(
  workspaceHint: number,
): RouteCommand[] {
  return [...workspaceRouteCommand(workspaceHint), LANDING_PAGE_PATH];
}

export function cWReportsRouteCommand(workspaceHint: number): RouteCommand[] {
  return cwRouteCommand(workspaceHint);
}

export function cwReportRouteCommand(
  workspaceHint: number,
  reportId: bigint,
): RouteCommand[] {
  return cwReportBrandsRouteCommand(workspaceHint, reportId);
}

export function cwReportBrandsRouteCommand(
  workspaceHint: number,
  reportId: bigint,
): RouteCommand[] {
  return [
    ...cWReportsRouteCommand(workspaceHint),
    reportId,
    CW_BRANDS_LIST_PATH,
  ];
}

export function cWBrandStatsRouteCommand(
  workspaceHint: number,
  reportId: bigint,
  brandId: bigint,
): RouteCommand[] {
  return [
    ...cwReportBrandsRouteCommand(workspaceHint, reportId),
    brandId,
    CW_BRAND_STATS_PATH,
  ];
}

export function cWCreateReportRouteCommand(
  workspaceHint: number,
): RouteCommand[] {
  return [...workspaceRouteCommand(workspaceHint), CREATE_REPORT_PATH];
}

export function promoCodesListRouteCommand(
  workspaceHint: number,
): RouteCommand[] {
  return [
    ...workspaceRouteCommand(workspaceHint),
    AFFILIATION_PATH,
    AFFILIATION_PROMO_CODES_PATH,
  ];
}

export function addOrEditPromoCodeRouteCommand(
  workspaceHint: number,
  promoCodeId?: bigint,
): RouteCommand[] {
  return isNil(promoCodeId)
    ? [...workspaceRouteCommand(workspaceHint), ADD_PROMO_CODE_PATH]
    : [
        ...workspaceRouteCommand(workspaceHint),
        EDIT_PROMO_CODE_PATH,
        promoCodeId,
      ];
}

export function getFeatureRouteCommand(
  workspaceHint: number,
  feature: Feature,
): RouteCommand[] {
  switch (feature) {
    case Feature.AFFILIATION_FEATURE:
      return affiliationRouteCommand(workspaceHint);
    case Feature.ANALYTICS_FEATURE:
      return cwRouteCommand(workspaceHint);
    case Feature.CAMPAIGNS_FEATURE:
      return campaignListRouteCommand(workspaceHint);
    case Feature.CASTING_FEATURE:
      return castingsListRouteCommand(workspaceHint);
    case Feature.DIRECTORY_FEATURE:
      return directoryRouteCommand(workspaceHint);
    case Feature.DISCOVER_FEATURE:
      return discoverRouteCommand(workspaceHint);
    case Feature.LANDING_PAGE_FEATURE:
      return landingPagesListRouteCommand(workspaceHint);
    case Feature.MESSAGES_FEATURE:
      return chatRouteCommand(workspaceHint);
    case Feature.PAYMENTS_FEATURE:
      return paymentsRouteCommand(workspaceHint);
    case Feature.PRODUCTS_SEEDING_FEATURE:
      return productRouteCommand(workspaceHint);
    case Feature.HOME_DASHBOARD_FEATURE:
      return homeDashboardRouteCommand(workspaceHint);
    case Feature.BRAND_SAFETY_FEATURE:
    case Feature.UNKNOWN_FEATURE:
      return [NOT_FOUND_PATH];
  }
}

// Only Match the route if the app is NOT in IFRAME
// usefull for feature not ready for production yet.
//
// The Angular won't recognize any route, then redirect the user to the
// AlfredEmptyRouteComponent and notify the parent app
//
// It guaranty it will never be visible on Dart side
export function canMatchIfNoIframe(): boolean {
  return IS_IN_IFRAME === false;
}
