import { ChangeDetectionStrategy, Component } from '@angular/core';
import { isGhostCreatorPoolResponse } from '@frontend2/proto-helpers/librarian/pool_pb.helpers';
import { Network } from '@frontend2/proto/common/proto/common_pb';
import { CreatorCardSnippet } from '@frontend2/proto/librarian/proto/creators_pb';
import { CreatorPoolResponse } from '@frontend2/proto/librarian/proto/pool_pb';
import { RendersValue } from '../../dynamic-component.component';
import { LeftyComponent } from '../../utils';
import { NetworkIconComponent } from '../../icon/network.component';
import { ProfilePictureComponent } from '../../image/profile-pic.component';

@Component({
  selector: 'directory-influencer-list-item',
  templateUrl: 'directory-influencer-list-item.component.html',
  styleUrls: ['directory-influencer-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ProfilePictureComponent, NetworkIconComponent],
})
export class DirectoryInfluencerListItemComponent
  extends LeftyComponent
  implements RendersValue<CreatorPoolResponse>
{
  setValue(newValue?: CreatorPoolResponse): void {
    this.creator = newValue ?? new CreatorPoolResponse();
    this.cardSnippet = newValue?.baseSnippet ?? new CreatorCardSnippet();
    this.networks = this.creator.networkInfo.map((n) => n.network);
  }

  creator = new CreatorPoolResponse();
  cardSnippet = new CreatorCardSnippet();
  networks: Network[] = [];

  withNetwork = false;

  isGhostCreator(creator: CreatorPoolResponse): boolean {
    return isGhostCreatorPoolResponse(creator);
  }
}

@Component({
  selector: 'directory-influencer-with-network-list-item',
  templateUrl: 'directory-influencer-list-item.component.html',
  styleUrls: ['directory-influencer-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ProfilePictureComponent, NetworkIconComponent],
})
export class DirectoryInfluencerWithNetworkListItemComponent extends DirectoryInfluencerListItemComponent {
  override withNetwork = true;
}
