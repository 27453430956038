import { inject } from '@angular/core';
import { RedirectFunction, Router, UrlTree } from '@angular/router';
import { LeftyAuthBloc, SPACE_PATH } from '@frontend2/api';
import { isNotEmptyString, LEFTY_LOGIN_PATH } from '@frontend2/core';
import { defaultSpaceIdxFromBootstrap } from '@frontend2/proto-helpers/librarian/frontend_misc_pb.helpers';

export const wildcardPathRedirect: RedirectFunction = ({ url }): UrlTree => {
  const router = inject(Router);
  const auth = inject(LeftyAuthBloc);

  // redirect to workspace path if already logged in
  if (auth.isLogged) {
    let currentPath = url.join('/');
    if (isNotEmptyString(currentPath)) {
      currentPath = '/' + currentPath;
    }
    const redirectionPath = `/${SPACE_PATH}/${defaultSpaceIdxFromBootstrap(
      auth.user,
    )}${currentPath}`;
    return router.parseUrl(redirectionPath);
  }
  return router.parseUrl(`/${LEFTY_LOGIN_PATH}`);
};
