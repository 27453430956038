import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { isNil, isNotEmptyString, isNotNil } from '@frontend2/core';
import Quill from 'quill';
import { LeftyFormValueBase, buildFormErrorMessage } from '../form';
import { LeftyFormComponent } from '../lefty-form/lefty-form.component';

@Component({
  selector: 'lefty-quill-form-editor',
  templateUrl: 'lefty-quill-editor.component.html',
  styleUrls: ['lefty-quill-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LeftyFormComponent],
})
export class LeftyQuillFormEditorComponent extends LeftyFormValueBase<string> {
  constructor(@Self() @Optional() ngControl?: NgControl) {
    super('', ngControl);
  }

  inputBoxQuill?: Quill;

  override get value(): string {
    return super.value;
  }

  override set value(value: string) {
    super.value = value;

    if (isNotNil(this.inputBoxQuill)) {
      const currentText = this.getQuillHTML(this.inputBoxQuill);
      if (currentText === value) {
        return;
      }
      this.inputBoxQuill.root.innerHTML = value;
    }
  }

  override set errorMessage(val: string) {
    super.errorMessage = val;
  }

  override get errorMessage(): string {
    if (this.ngControl && this.invalid) {
      const message =
        buildFormErrorMessage(this.ngControl) ?? super.errorMessage;
      if (isNotEmptyString(message)) {
        return message;
      }
    }

    return super.errorMessage;
  }

  @ViewChild('inputBox')
  set inputBox(box: ElementRef<HTMLElement> | undefined) {
    if (isNil(box)) {
      return;
    }

    const ref = box.nativeElement;

    const quill = (this.inputBoxQuill = this.createQuillEditor(ref));

    quill.root.innerHTML = this.value;

    quill.on('text-change', () => {
      this.handleValueChange(this.getQuillHTML(quill));
    });
  }

  getQuillHTML(quill: Quill): string {
    return quill.root.innerHTML;
  }

  createQuillEditor(containerRef: HTMLElement): Quill {
    return new Quill(containerRef, {
      modules: {
        toolbar: [
          [{ header: [3, 4, false] }],
          ['bold', 'italic', 'underline', 'link'],
          [{ list: 'ordered' }, { list: 'bullet' }],
        ],
      },
      theme: 'snow',
    });
  }

  override writeValue(obj: unknown): void {
    if (typeof obj === 'string') {
      this.value = obj;
    }
  }
}
