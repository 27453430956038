import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  NgZone,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { isEmptyArray, isNil, isNotNil } from '@frontend2/core';
import { TimeRestriction } from '@frontend2/proto/librarian/proto/common_pb';
import AirDatepicker from 'air-datepicker';
import { LeftyFormValueBase } from '../../form';
import {
  datesToTimeRestriction,
  loadAirDatepickerLocale,
  timeRestrictionToDates,
} from '../lefty-date-picker.helpers';

@Component({
  selector: 'lefty-calendar-picker',
  template: `<div #calendar></div>`,
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class LeftyCalendarPickerComponent
  extends LeftyFormValueBase<Date | TimeRestriction | undefined>
  implements AfterViewInit
{
  constructor(
    readonly zone: NgZone,
    @Self() @Optional() ngControl?: NgControl,
  ) {
    super(undefined, ngControl);
  }

  @Input()
  isRange = false;

  @Input()
  clearable = false;

  @ViewChild('calendar')
  calendarElementRef?: ElementRef;

  private datepicker?: AirDatepicker;

  private async initDatepicker(): Promise<void> {
    const startDate =
      this.value instanceof TimeRestriction
        ? this.value?.start?.toDate()
        : this.value;
    await this.zone.runOutsideAngular(async () => {
      const locale = await loadAirDatepickerLocale();

      this.datepicker = new AirDatepicker(
        this.calendarElementRef?.nativeElement,
        {
          selectedDates: isNotNil(startDate) ? [startDate] : [],
          inline: true,
          locale: locale,
          range: this.isRange,
          navTitles: {
            days: 'MMMM, yyyy',
          },
          onSelect: ({ date }): void => {
            this.zone.run(() => this.handleSelection(date));
          },
        },
      );
    });
  }

  async ngAfterViewInit(): Promise<void> {
    await this.initDatepicker();
    this._handleExternalChange(this.value);
  }

  handleSelection(date: Date | Date[] | undefined): void {
    if (this.isRange) {
      const dates = date as Date[];

      // we don't want to submit only 1 Date
      // we should always submit a range of dates (not just start date)
      if (dates.length === 2) {
        this.zone.run(() => this.handleRangeChange(dates));
      }
    } else {
      const dateVal = date as Date;
      this.handleDateChange(dateVal);
    }
  }

  handleRangeChange(val: Date[]): void {
    const newRange = datesToTimeRestriction(val);

    if (this.value instanceof TimeRestriction && newRange.equals(this.value)) {
      return;
    }
    this.handleValueChange(newRange);
  }

  handleDateChange(val: Date | undefined): void {
    if (
      isNotNil(val) &&
      isNotNil(this.value) &&
      this.value instanceof Date &&
      val.getTime() === this.value.getTime()
    ) {
      return;
    }

    if (this.clearable === false && isNil(val)) {
      this.zone.runOutsideAngular(() => {
        if (isNotNil(this.value) && this.value instanceof Date) {
          this.datepicker?.selectDate(this.value);
        }
      });
    }

    this.handleValueChange(val);
  }

  private _handleExternalChange(
    dateOrRange: TimeRestriction | Date | undefined,
  ): void {
    this.zone.runOutsideAngular(() => {
      if (!this.datepicker) {
        return;
      }
      if (isNil(dateOrRange)) {
        this.datepicker.clear();
      }
      if (this.isRange && dateOrRange instanceof TimeRestriction) {
        const currentRange = datesToTimeRestriction(
          this.datepicker.selectedDates,
        );

        if (dateOrRange.equals(currentRange) === false) {
          const newDates = timeRestrictionToDates(dateOrRange);
          if (isEmptyArray(newDates)) {
            this.datepicker.clear();
          } else {
            this.datepicker.selectDate(newDates);
          }
        }
      } else if (!this.isRange && dateOrRange instanceof Date) {
        const currentDate =
          this.datepicker.selectedDates.length === 0
            ? undefined
            : this.datepicker.selectedDates[0];

        if (currentDate?.getTime() !== dateOrRange?.getTime()) {
          this.datepicker.selectDate(dateOrRange);
        }
      }
    });
  }

  override writeValue(obj: unknown): void {
    if (
      obj instanceof TimeRestriction ||
      obj instanceof Date ||
      obj === undefined
    ) {
      this.value = obj;
    }
  }
}
