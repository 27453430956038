<a
  target="_blank"
  href="https://lefty.io"
  rel="noopener"
  class="logo-container"
>
  <img
    height="58"
    [appLogo]="leftyLogoSrc"
  />
</a>

<h4
  class="page-title"
  i18n
>
  Reset Password
</h4>
<alfred-reset-password-form-component
  [hasInvalidToken]="hasInvalidToken()"
  [state]="resetPasswordState()"
  [loading]="loading()"
  (emailIsEntered$)="emailSubmitted($event)"
  (newPassword$)="newPasswordSubmitted($event)"
  (gotoHomePage$)="goToHome()"
></alfred-reset-password-form-component>
