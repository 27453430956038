<lefty-form
  [label]="label"
  [required]="required"
  [helpText]="helpText"
  [externalHelpLink]="externalHelpLink"
  [helpLink]="helpLink"
  [hintText]="hintText"
  [errorMessage]="errorMessage"
  [tooltip]="tooltip"
  [large]="large"
  [topHintText]="topHintText"
>
  <div class="input-container">
    <lefty-date-picker
      #start
      class="fill-space"
      [placeholder]="startDatePlaceholder"
      [(value)]="startDate"
      [minDate]="minDate"
      [maxDate]="endDate"
      [dateFormat]="dateFormat"
      [clearable]="false"
      [required]="required"
    >
    </lefty-date-picker>
    <span
      class="to-label"
      i18n
    >
      to
    </span>
    <lefty-date-picker
      #end
      class="fill-space"
      [placeholder]="endDatePlaceholder"
      [(value)]="endDate"
      [minDate]="startDate"
      [maxDate]="maxDate"
      [dateFormat]="dateFormat"
      [clearable]="false"
      [required]="required"
    >
    </lefty-date-picker>
  </div>
</lefty-form>
