import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { isNotEmptyString } from '@frontend2/core';
import { HelpIconComponent } from '../icon/help.component';
import { LeftyCardComponent } from '../lefty-card/lefty-card.component';
import { LeftyTooltipDirective } from '../lefty-tooltip/lefty-tooltip.directive';

@Component({
  selector: 'lefty-key-number',
  templateUrl: './key-number.component.html',
  styleUrls: ['./key-number.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [HelpIconComponent, LeftyTooltipDirective],
})
export class LeftyKeyNumberComponent {
  @Input() label = '';
  @Input() value = '';
  @Input() isPrimary = false;
  @Input() compact = false;
  @Input() good = false;
  @Input() bad = false;
  @Input() ghost = false;

  get primary(): boolean {
    return this.isPrimary && this.value !== '-';
  }

  get isGood(): boolean {
    return this.good && this.value !== '-';
  }

  get isBad(): boolean {
    return this.bad && this.value !== '-';
  }

  @Input()
  helpMessage = '';

  get hasHelpMessage(): boolean {
    return isNotEmptyString(this.helpMessage);
  }
}

@Component({
  selector: 'lefty-key-number-card',
  template: `<lefty-card
    class="card"
    [isCompact]="true"
    [withBorder]="true"
    [withPadding]="true"
  >
    <lefty-key-number
      [label]="label"
      [value]="value"
      [isPrimary]="isPrimary"
      [good]="good"
      [bad]="bad"
      [ghost]="ghost"
      [helpMessage]="helpMessage"
    >
      <ng-content
        select="[action]"
        ngProjectAs="[action]"
      ></ng-content>
    </lefty-key-number>
  </lefty-card>`,
  styleUrls: ['./key-number.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LeftyCardComponent, LeftyKeyNumberComponent],
})
export class LeftyKeyNumberCardComponent extends LeftyKeyNumberComponent {}
