import { Timestamp } from '@bufbuild/protobuf';
import { CurrencyEnum } from '@frontend2/proto/common/proto/common_pb';
import {
  EcommerceStorePb,
  EcommerceStoreUserSettingsPb,
  EcommerceStoreWithInfoPb,
  ManualStorePb,
  ShopifyStorePbV2,
} from '@frontend2/proto/librarian/proto/ecommerce_stores_pb';
import { isNil } from '../../utils/common.helpers';
import { isNotEmptyString } from '../../utils/strings.helpers';

export function isGhostManualStore(store: ManualStorePb): boolean {
  return store.id === BigInt(0);
}

export function isGhostShopifyStore(store: ShopifyStorePbV2): boolean {
  return store.id === BigInt(0);
}

export function isGhostEcommerceStoreWithInfoPb(
  ecommerce: EcommerceStoreWithInfoPb,
): boolean {
  return (
    isNil(ecommerce.store) ||
    isNil(ecommerce.store.store.value) ||
    ecommerce.store.store.value.id === BigInt(0)
  );
}

export function isGhostEcommerceStorePb(ecommerce: EcommerceStorePb): boolean {
  return ecommerce.store.value?.id === BigInt(0) || isNil(ecommerce.store.case);
}

//we also accept undefined to make the use easier when used with EcommerceStoreWithInfoPb and store is optional
export function getStoreName(store: EcommerceStorePb | undefined): string {
  return store?.store?.value?.name ?? '';
}

export function getStoreDomain(store: EcommerceStorePb | undefined): string {
  return store?.store?.value?.domain ?? '';
}

export function getStoreWithInfoId(store: EcommerceStoreWithInfoPb): bigint {
  return getStoreId(store.store ?? new EcommerceStorePb());
}

export function getStoreId(store: EcommerceStorePb): bigint {
  return store.store.value?.id ?? BigInt(0);
}

export function getStoreCurrency(store: EcommerceStorePb): CurrencyEnum {
  return store.store?.value?.currency ?? CurrencyEnum.NA;
}

export function storeIsArchived(store: EcommerceStorePb): boolean {
  return store.store.value?.archived === true;
}
export function storeWithInfoIsArchived(
  store: EcommerceStoreWithInfoPb,
): boolean {
  return storeIsArchived(store.store ?? new EcommerceStorePb());
}

export function getStoreCreationDate(
  store: EcommerceStoreWithInfoPb,
): Timestamp | undefined {
  return store.store?.store.value?.createdAt;
}

export function isShopifyStore(store: EcommerceStorePb): boolean {
  return store.store.case === 'shopifyStore';
}

export function isManualStore(store: EcommerceStorePb): boolean {
  return store.store.case === 'manualStore';
}

export function isSalesforceStore(store: EcommerceStorePb): boolean {
  return store.store.case === 'salesforceStore';
}

export function isOnlineStore(store: EcommerceStorePb): boolean {
  return !isGhostEcommerceStorePb(store) && !isManualStore(store);
}

export function getOnlineStoreSettings(
  store: EcommerceStorePb,
): EcommerceStoreUserSettingsPb {
  if (store.store.case !== 'manualStore') {
    return store.store.value?.settings ?? new EcommerceStoreUserSettingsPb();
  }
  return new EcommerceStoreUserSettingsPb();
}

export function getOnlineStoreDiscountReason(store: EcommerceStorePb): string {
  const settings = getOnlineStoreSettings(store);
  return settings.discountCodeReason ?? '';
}

export function onlineStoreHasDiscountReason(store: EcommerceStorePb): boolean {
  return isNotEmptyString(getOnlineStoreDiscountReason(store));
}

export function getOnlineStoreSortedShippingMethods(
  store: EcommerceStoreWithInfoPb,
): string[] {
  const settings = getOnlineStoreSettings(
    store.store ?? new EcommerceStorePb(),
  );
  return settings.shippingMethods.sort((a, b) =>
    a.toLowerCase().localeCompare(b.toLowerCase()),
  );
}

export function getReadableStoreName(store: EcommerceStorePb): string {
  switch (store.store.case) {
    case 'shopifyStore':
      return 'Shopify';
    case 'salesforceStore':
      return 'Salesforce';
    case 'manualStore':
      return $localize`Manual store`;
    default:
      return '';
  }
}

export function getShopifyStorePbV2FromStore(
  store: EcommerceStorePb,
): ShopifyStorePbV2 {
  if (store.store.case === 'shopifyStore') {
    return store.store.value;
  }
  return new ShopifyStorePbV2();
}
