import { CommonFilter } from '@frontend2/proto/librarian/proto/common_pb';
import { getDynamicBooleanFilterValue } from '../../dynamic-filters.helpers';

export type BooleanOperator = boolean | 'empty';

export function getBooleanOperatorFromCommonFilter(
  filter: CommonFilter,
): BooleanOperator {
  if (filter.filter.case === 'isEmpty' && filter.filter.value === true) {
    return 'empty';
  }
  const val = getDynamicBooleanFilterValue(filter);
  return val;
}

export function formattedBoolValue(filter: CommonFilter): string {
  const op = getBooleanOperatorFromCommonFilter(filter);
  return readableBoolOperator(op);
}

export function readableBoolOperator(op: BooleanOperator): string {
  switch (op) {
    case true:
      return $localize`yes`;
    case false:
      return $localize`no`;
    case 'empty':
      return $localize`empty`;
  }
}
