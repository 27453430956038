import { InjectionToken, Provider, inject } from '@angular/core';

const NESTOR_APP_URL_TOKEN = new InjectionToken<string>('NESTOR_APP_URL');

const NESTOR_PROD_APP_URL = 'https://creators.lefty.io';
const NESTOR_STAGING_APP_URL = 'https://talent-staging.lefty.io';

export function provideNestorAppUrl(isProduction: boolean): Provider {
  return {
    provide: NESTOR_APP_URL_TOKEN,
    useValue: isProduction ? NESTOR_PROD_APP_URL : NESTOR_STAGING_APP_URL,
  };
}

export function injectNestorAppUrl(): string {
  return inject(NESTOR_APP_URL_TOKEN);
}
