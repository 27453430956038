import {
  ChangeDetectionStrategy,
  Component,
  Input,
  computed,
  signal,
} from '@angular/core';
import { isEmptyString, isNotEmptyString } from '@frontend2/core';
import { LeftyFileInputBase } from '../lefty-file-input.base';
import { LeftyProgressComponent } from '../../loading.component';
import { LeftyFeedbackComponent } from '../../lefty-feedback/lefty-feedback.component';
import { NgIf } from '@angular/common';
import { LeftyFormComponent } from '../../lefty-form/lefty-form.component';

@Component({
  selector: 'lefty-logo-input',
  templateUrl: 'lefty-logo-input.component.html',
  styleUrls: ['lefty-logo-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    LeftyFormComponent,
    NgIf,
    LeftyFeedbackComponent,
    LeftyProgressComponent,
  ],
})
export class LeftyLogoInputComponent extends LeftyFileInputBase {
  dataSrc?: string;

  readonly MAX_TINY_LOGO_SIZE = 64;

  isDataSrc(src: string): boolean {
    return src.startsWith('data:');
  }

  @Input()
  isLargeLogo = true;

  @Input()
  brandName = '';

  override accept = '.png,.jpg,.jpeg';

  imageOutOfSize = signal(false);
  readonly imageOutOfSizeError = $localize`Image is not valid. Please choose a square image less than 64x64 pixels.`;
  readonly imageOutOfSizeInfo = $localize`The image size must be square and less than 64x64 pixels.`;

  get brandInitial(): string {
    return isNotEmptyString(this.brandName) ? this.brandName.charAt(0) : '_';
  }

  feedbackType = computed(() => (this.imageOutOfSize() ? 'error' : 'info'));
  feedbackText = computed(() =>
    this.imageOutOfSize() ? this.imageOutOfSizeError : this.imageOutOfSizeInfo,
  );

  private _readFile(file: File): void {
    if (!this.isLargeLogo) {
      this.checkLogoSize(file).then((isValid) => {
        if (!isValid) {
          this.imageOutOfSize.set(true);
          this.clear();
          return;
        }
      });
    }
    this.imageOutOfSize.set(false);
    this._processFile(file);
  }

  private _processFile(file: File): void {
    const reader = new FileReader();

    this.setState(() => {
      this.progress = 0;
      this.loading = true;
    });

    reader.onprogress = (event): void => {
      this.setState(() => (this.progress = (100 * event.loaded) / event.total));
    };

    reader.onloadend = (): void => {
      const result = reader.result;

      this.setState(() => {
        this.loading = false;

        if (typeof result === 'string') {
          this.dataSrc = result;
        }
      });
    };

    reader.readAsDataURL(file);
  }

  override set file(file: File) {
    super.file = file;
    this._readFile(file);
  }

  override set path(path: string) {
    super.path = path;

    this.setState(() => {
      this.dataSrc = path;
      this.loading = false;
    });
  }

  override clear(): void {
    this.handleValueChange('');
  }

  override get isEmptyState(): boolean {
    return super.isEmptyState || isEmptyString(this.dataSrc);
  }

  async checkLogoSize(file: File): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const img = new Image();
      img.onload = (): void => {
        const isSquare = img.width === img.height;
        const isSmall =
          img.width <= this.MAX_TINY_LOGO_SIZE &&
          img.height <= this.MAX_TINY_LOGO_SIZE;
        resolve(isSquare && isSmall);
      };
      const reader = new FileReader();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      reader.onload = (e: any): void => {
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    });
  }
}
