import {
  AffiliationProgramCommissionType,
  Program,
} from '@frontend2/proto/librarian/proto/affiliation_pb';
import {
  AffiliationPromoCodeDiscountType,
  AffiliationPromoCodeMinRequirementsType,
  AffiliationPromoCodePb,
  AffiliationPromoCodeStatus,
  AffiliationPromoCodeWithInfoPb,
  InitGetPromoCodesListsResponse,
} from '@frontend2/proto/librarian/proto/affiliation_promo_codes_pb';
import { EcommerceStorePb } from '@frontend2/proto/librarian/proto/ecommerce_stores_pb';
import { LeftyFormat } from '../../format';
import { isNil, isNotNil, plural } from '../../utils/common.helpers';
import { isNotEmptyString } from '../../utils/strings.helpers';
import { getStoreCurrency } from './ecommerce_stores_pb.helpers';

export function isGhostAffiliationPromoCodeWithInfoPb(
  promoCodeWithInfo: AffiliationPromoCodeWithInfoPb,
): boolean {
  return (
    isNil(promoCodeWithInfo.promoCode) ||
    promoCodeWithInfo.promoCode.id === BigInt(0)
  );
}

export function isGhostInitGetPromoCodesListsResponse(
  val: InitGetPromoCodesListsResponse,
): boolean {
  return Object.values(val).flat().length === 0;
}

export function affiliationPromoCodeStatusToReadable(
  val: AffiliationPromoCodeStatus,
): string {
  switch (val) {
    case AffiliationPromoCodeStatus.ACTIVE:
      return $localize`Active`;
    case AffiliationPromoCodeStatus.INACTIVE:
      return $localize`Inactive`;
    case AffiliationPromoCodeStatus.DELETED:
      return $localize`Deleted`;
    case AffiliationPromoCodeStatus.SCHEDULED:
      return $localize`Scheduled`;
    default:
      return 'unknown';
  }
}

export function getPromoCodeValue(promoCode: AffiliationPromoCodePb): string {
  const currency = getStoreCurrency(
    promoCode.ecommerceStore ?? new EcommerceStorePb(),
  );

  switch (promoCode.discountType) {
    case AffiliationPromoCodeDiscountType.FIXED_AMOUNT: {
      return LeftyFormat.currency(promoCode.discountValue, {
        currency: currency,
        showZero: true,
      });
    }
    case AffiliationPromoCodeDiscountType.PERCENTAGE: {
      return LeftyFormat.percent(promoCode.discountValue / 100);
    }

    default:
      return '';
  }
}

export function getPromoCodeMinRequirement(
  promoCode: AffiliationPromoCodePb,
): string {
  const currency = getStoreCurrency(
    promoCode.ecommerceStore ?? new EcommerceStorePb(),
  );

  const requirement = promoCode.minRequirements;
  if (isNotNil(requirement)) {
    switch (requirement.type) {
      case AffiliationPromoCodeMinRequirementsType.PURCHASE_AMOUNT: {
        return LeftyFormat.currency(requirement.amount, {
          currency: currency,
          showZero: true,
        });
      }
      case AffiliationPromoCodeMinRequirementsType.QUANTITY_OF_ITEMS: {
        return $localize`${requirement.amount} ${plural(requirement.amount, { one: $localize`item`, other: $localize`items` })}`;
      }
    }
  }
  return '';
}

export function discountConditionTextFromPromoCode(
  promoCode: AffiliationPromoCodePb,
): string {
  const condition: string[] = [];

  const promoValue = getPromoCodeValue(promoCode);
  if (isNotEmptyString(promoValue)) {
    condition.push(promoValue);
  }

  const requirement = getPromoCodeMinRequirement(promoCode);
  if (isNotEmptyString(requirement)) {
    condition.push($localize`Min.purchase of ${requirement}`);
  } else {
    condition.push($localize`No min. purchase`);
  }

  if (promoCode.oneUsePerCustomer) {
    condition.push($localize`One use per customer`);
  }

  if (isNotNil(promoCode.usesLimit) && promoCode.usesLimit !== BigInt(0)) {
    condition.push($localize`${promoCode.usesLimit} uses in total`);
  }

  if (
    promoCode.oneUsePerCustomer === false &&
    (isNil(promoCode.usesLimit) || promoCode.usesLimit === BigInt(0))
  ) {
    condition.push($localize`No usage limit`);
  }
  return condition.join(' · ');
}

export function getProgramCommission(program: Program): string {
  switch (program.commissionType) {
    case AffiliationProgramCommissionType.COMMISSION_FLAT_FEE: {
      return LeftyFormat.currency(program.commissionValue ?? 0, {
        currency: program.currency,
        showZero: true,
      });
    }
    case AffiliationProgramCommissionType.COMMISSION_PERCENTAGE: {
      return LeftyFormat.percent((program.commissionValue ?? 0) / 100);
    }

    case AffiliationProgramCommissionType.COMMISSION_NONE: {
      return $localize`None`;
    }
  }
}

export function promoCodeHasAssociatedCampaign(
  promoCode: AffiliationPromoCodePb,
): boolean {
  return isNotNil(promoCode.campaign) && promoCode.campaign.id !== BigInt(0);
}
