<profile-picture [creator]="cardSnippet"> </profile-picture>

<div class="username fill-space">{{ cardSnippet.userName }}</div>
@if (withNetwork) {
  <div class="networks">
    @for (network of networks; track network) {
      <network-icon [network]="network"></network-icon>
    }
  </div>
}
