import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  inject,
} from '@angular/core';
import { LeftyAuthBloc } from '@frontend2/api';
import { escapeRegExp, orderBy } from '@frontend2/core';
import {
  CustomerSpace,
  LoggedBootstrapping,
} from '@frontend2/proto/librarian/proto/frontend_misc_pb';
import { LeftyComponent, createOutput } from '@frontend2/ui';

@Component({
  selector: 'alfred-workspaces-list',
  templateUrl: 'workspaces-list.component.html',
  styleUrls: ['workspaces-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlfredWorkspacesListComponent extends LeftyComponent {
  private readonly _auth = inject(LeftyAuthBloc);

  constructor() {
    super();
    this.watch(this._auth.state$, {
      next: (user: LoggedBootstrapping) => {
        this._spaces = orderBy<CustomerSpace>(
          [...user.customerSpaces],
          (item) => item.name,
        );
        this.filteredSpaces = this._buildSpacesList(
          this._spaces,
          this._spaceFilterName,
        );
      },
    });
  }

  notFoundText = $localize`Workspace not found.`;

  private _spaceFilterName = '';
  get spaceFilterName(): string {
    return this._spaceFilterName;
  }
  @Input()
  set spaceFilterName(spaceFilterName: string) {
    this._spaceFilterName = spaceFilterName;
    this.filteredSpaces = this._buildSpacesList(
      this._spaces,
      this._spaceFilterName,
    );
  }

  @Output()
  readonly select$ = createOutput<CustomerSpace>();

  private _spaces: CustomerSpace[] = [];
  filteredSpaces: CustomerSpace[] = [];

  private _buildSpacesList(
    spaces: CustomerSpace[],
    filterName: string,
  ): CustomerSpace[] {
    filterName = escapeRegExp(filterName.trim());

    if (filterName !== '') {
      const filter = new RegExp(filterName, 'i');
      return spaces.filter((s) => filter.test(s.name));
    }

    return spaces;
  }
}
