<lefty-form-select
  *ngIf="!disabled"
  [options]="options"
  [selection]="selection"
  [componentFactory]="StatusItemFactory"
  [itemRenderer]="itemRenderer"
  (selectionChange)="selection = $event"
  (selectionChange)="selectionChange$.next($event)"
  [buttonClass]="buttonClass"
  [placeholder]="placeholder"
>
  @if (hasValue) {
    <status-dropdown-item
      buttonContent
      [status]="selection"
    ></status-dropdown-item>
  }
</lefty-form-select>

<button
  *ngIf="disabled"
  class="read-mode"
  leftyButton="ghost"
  size="small"
>
  <status-dropdown-item [status]="selection"></status-dropdown-item>
</button>
