import { CommonFilter } from '@frontend2/proto/librarian/proto/common_pb';
import {
  BooleanOperator,
  getBooleanOperatorFromCommonFilter,
} from '../boolean.helpers';

export function readableTrackOperator(op: BooleanOperator): string {
  switch (op) {
    case true:
      return $localize`tracked`;
    case false:
      return $localize`not tracked`;
    case 'empty':
      return '';
  }
}

export function formattedTrackedStatusValue(filter: CommonFilter): string {
  const op = getBooleanOperatorFromCommonFilter(filter);
  return readableTrackOperator(op);
}
