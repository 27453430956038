import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Optional,
  Self,
  signal,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { isNil } from '@frontend2/core';
import {
  allCurrenciesWithSort,
  getCurrencyCode,
} from '@frontend2/proto-helpers/common/common_pb.helpers';
import { CurrencyEnum } from '@frontend2/proto/common/proto/common_pb';
import { LeftyFormValueBase } from '../form';
import { SearchAndSelectDropdownComponent } from '../selector/search-and-select-dropdown/search-and-select-dropdown.component';

@Component({
  selector: 'lefty-currency-search-and-select',
  template: `<search-and-select-dropdown
    [buttonClass]="buttonClass"
    [placeholder]="placeholder"
    [selection]="value"
    (selectionChange)="handleValueChange($event)"
    [options]="options"
    [itemRenderer]="currencyCode"
    [disabled]="disabled"
    [label]="label"
    [allowEmptySearch]="allowEmptySearch"
    [multiSelect]="multiSelect"
  >
  </search-and-select-dropdown> `,
  styleUrls: ['./lefty-currency-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SearchAndSelectDropdownComponent],
})
export class LeftyCurrencySearchAndSelectComponent extends LeftyFormValueBase<
  CurrencyEnum[]
> {
  constructor(@Self() @Optional() ngControl?: NgControl) {
    super([], ngControl);
  }

  @Input()
  buttonClass = '';

  @Input()
  placeholder = $localize`Select currency`;

  @Input()
  allowEmptySearch = false;

  @Input()
  multiSelect = false;

  //if not set, all the currencies
  @Input()
  options: CurrencyEnum[] = allCurrenciesWithSort();

  readonly showMoreCurrencies = signal(false);

  currencyCode(c: CurrencyEnum): string {
    return isNil(c) ? this.placeholder : getCurrencyCode(c);
  }

  override writeValue(obj: unknown): void {
    if (isNil(obj)) {
      this.value = [];
      return;
    }

    this.value = obj as CurrencyEnum[];
  }
}
