import { NgModule } from '@angular/core';
import { LeftyAddFilterButtonComponent } from './add-filter-button/add-filter-button.component';
import { LeftyAdvancedAddFilterButtonComponent } from './advanced-add-filter-button/advanced-add-filter-button.component';
import { DynamicFilterSectionListItemComponent } from './advanced-add-filter-button/filter-section-list-item/filter-section-list-item.component';
import { LeftyDynamicFiltersGroupComponent } from './dynamic-filters-group.component';
import { LeftyDynamicFiltersLogicOperatorComponent } from './dynamic-filters-logic-operator.component';
import { LeftyDynamicFiltersComponent } from './dynamic-filters.component';
import { FieldWithNetworkListItemComponent } from './field-with-network-list-item/field-with-network-list-item.component';
import { LeftyBooleanDynamicFilterComponent } from './filters/boolean/boolean.component';
import { LeftyTrackedStatusFilterComponent } from './filters/boolean/tracked-status/tracked-status.component';
import { LeftyDateDynamicFilterComponent } from './filters/date/date.component';
import { LeftyDynamicFilterComponent } from './filters/dynamic-filter.component';
import { LeftyDynamicFilterWrapperComponent } from './filters/filter-wrapper.component';
import { LeftyInstaInsightDynamicFilterComponent } from './filters/insta-insight/insta-insight.component';
import { LeftyInfluencerListDynamicFilterComponent } from './filters/list/influencer-list/influencer-list.component';
import { LeftyListDynamicFilterComponent } from './filters/list/list.component';
import { LeftyPostTypeDynamicFilterComponent } from './filters/list/post-type/post-type.component';
import { LeftyLocationDynamicFilterComponent } from './filters/location/location.component';
import { LeftyNumberWithNetworkDynamicFilterComponent } from './filters/number-with-network/number-with-network.component';
import { LeftyNumberDynamicFilterComponent } from './filters/number/number.component';
import { LeftyTextDynamicFilterComponent } from './filters/text/text.component';

export {
  createDynamicFilterConfig,
  DynamicFilterConfig,
} from './dynamic-filters-config.bloc';

export {
  ApplyCommonFilterFn,
  filterWithDynamicFilter,
} from './dynamic-filters.helpers';

@NgModule({
  imports: [
    LeftyDynamicFiltersComponent,
    LeftyDynamicFiltersGroupComponent,
    LeftyDynamicFiltersLogicOperatorComponent,
    LeftyDynamicFilterComponent,
    LeftyDynamicFilterWrapperComponent,
    LeftyTextDynamicFilterComponent,
    LeftyNumberDynamicFilterComponent,
    LeftyNumberWithNetworkDynamicFilterComponent,
    LeftyBooleanDynamicFilterComponent,
    LeftyTrackedStatusFilterComponent,
    LeftyDateDynamicFilterComponent,
    LeftyDynamicFilterComponent,
    LeftyLocationDynamicFilterComponent,
    LeftyListDynamicFilterComponent,
    LeftyInfluencerListDynamicFilterComponent,
    FieldWithNetworkListItemComponent,
    LeftyAddFilterButtonComponent,
    DynamicFilterSectionListItemComponent,
    LeftyAdvancedAddFilterButtonComponent,
    LeftyInstaInsightDynamicFilterComponent,
    LeftyPostTypeDynamicFilterComponent,
  ],
  exports: [
    LeftyDynamicFiltersComponent,
    LeftyAddFilterButtonComponent,
    LeftyAdvancedAddFilterButtonComponent,
  ],
})
export class LeftyDynamicFiltersModule {}
